import React from 'react'
import { ContainerRouterProps } from '../../interface'
import KYCTypeSelection from '../../components/kyc/kyctypeselection'
import TermsAgreementContainer from '../user/termsagreement'

const TypeSelectionContainer: React.FC<ContainerRouterProps> = () => {
  return (
    <React.Fragment>
      <TermsAgreementContainer />
      <KYCTypeSelection />
    </React.Fragment>
  )
}

export default TypeSelectionContainer
