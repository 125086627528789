import React from 'react'

import { AuthContextProvider } from '@/ApiReact'
import { BrowserRouter as Router } from 'react-router-dom'

import Routes from './routes'

const App: React.FC = () => {
  return (
    <AuthContextProvider
      API_ENDPOINT={process.env.REACT_APP_API_ENDPOINT}
      AWS_REGION={process.env.REACT_APP_AWS_REGION}
      AWS_USER_POOL_ID={process.env.REACT_APP_AWS_USER_POOL_ID}
      AWS_USER_POOLS_WEB_CLIENT_ID={process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID}
      STAGE={process.env.REACT_APP_STAGE}
    >
      <Router>
        <Routes />
      </Router>
    </AuthContextProvider>
  )
}

export default App
