import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useAuthContext, useFormState } from '@/ApiReact'
import LoginWrapper from '../../components/user/loginwrapper'
import Verify from '../../components/user/verify'
import { getEmailFromUrlParam } from '../../util/helpers'
import { ContainerRouterProps } from '../../interface'
import ErrorAlert from '../usernotifications/erroralert'
import LoadingBlock from '../usernotifications/loading'
import RouteConstants from '../../routes/routeConstants'

const VerifyContainer: React.FC<ContainerRouterProps> = (props: ContainerRouterProps) => {
  const [isLoading, setLoading] = useState(false)
  const [generalError, setGeneralError] = useState('')

  const useVerifyFormState = (): any => {
    const [resendSuccess, setResendSuccess] = useState(false)
    const [resendTimer, setResendTimer] = useState(0)
    const emailRef = getEmailFromUrlParam()
    const initialValues = { email: emailRef, code: '' }
    const auth = useAuthContext()

    const { values, setValues, errors, setErrors, handleChange, handleSubmit } = useFormState({
      initialValues,
      handleSubmit: async (event, values) => {
        event.preventDefault()
        setLoading(true)
        const { email, code } = values
        const response = await auth.confirmSignup(email, code)
        if (response.isOk()) {
          props.history.push(RouteConstants.Home)
        } else {
          setLoading(false)
          setErrors({ code: response.error.message })
        }
      },
    })

    const toggleResendSuccess = (): void => {
      let timer = 60
      const timerFun = setInterval(() => {
        setResendTimer(timer--)
        if (timer == -1) {
          clearInterval(timerFun)
          setResendSuccess(false)
        }
      }, 1000)
    }

    const onSubmitResend = async (): Promise<any> => {
      try {
        setResendSuccess(true)
        const response = await auth.resendSignUp(values.email)
        if (response.isOk()) {
          toggleResendSuccess()
        } else {
          setResendSuccess(false)
          setErrors({ email: response.error.message })
        }
      } catch (error) {
        setErrors({ email: JSON.stringify(error) })
        setResendSuccess(false)
      }
    }
    return {
      values,
      setValues,
      errors,
      setErrors,
      handleChange,
      handleSubmit,
      onSubmitResend,
      resendSuccess,
      resendTimer,
    }
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    onSubmitResend,
    resendSuccess,
    resendTimer,
  } = useVerifyFormState()

  return (
    <React.Fragment>
      {isLoading ? <LoadingBlock /> : null}
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      <LoginWrapper displayToggle={false}>
        <Verify
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          onSubmitResend={onSubmitResend}
          resendSuccess={resendSuccess}
          resendTimer={resendTimer}
        />
      </LoginWrapper>
    </React.Fragment>
  )
}

export default withRouter(VerifyContainer)
