const RouteConstants = {
  Login: '/',
  Signup: '/signup',
  ForgotPassword: '/forgot',
  ResetPassword: '/reset',
  User: '/user',
  Verify: '/verify',
  KYCType: '/kyc',
  KYCIndividual: '/kyc/individual',
  KYCInstitution: '/kyc/institutional',
  Account: '/account',
  Home: '/home',
  Bank: '/bank',
  BankAdd: '/bank/add',
  Crypto: '/crypto',
  CryptoAdd: '/crypto/add',
  Settings: '/settings',
  Admin: '/admin',
  GoldPrice: '/gold-price',
  Sandbox: '/sandbox',
}

export default RouteConstants
