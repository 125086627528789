import React from 'react'
import KYCSelection from '../../blocks/kycselection'
import { useHistory } from 'react-router-dom'
import context from '../../util/localize'
import RouteConstants from '../../routes/routeConstants'

const KYCTypeSelection = (): JSX.Element => {
  const history = useHistory()
  return (
    <div className="container">
      <KYCSelection>
        <KYCSelection.PageTitle>{context.accountType}</KYCSelection.PageTitle>
        <KYCSelection.Wrapper>
          <KYCSelection.Card>
            <KYCSelection.Image>
              <img src="/images/Kitco-Icon-02.png" />
              <KYCSelection.Title>{context.individual}</KYCSelection.Title>
            </KYCSelection.Image>
            <KYCSelection.Desc>{context.forIndividual}</KYCSelection.Desc>
            <button
              className="button-primary"
              type="submit"
              onClick={(): void => history.push(RouteConstants.KYCIndividual)}
            >
              {context.select}
            </button>
          </KYCSelection.Card>
          <KYCSelection.Card>
            <KYCSelection.Image>
              <img src="/images/Kitco-Icon-01.png" />
              <KYCSelection.Title>{context.business}</KYCSelection.Title>
            </KYCSelection.Image>
            <KYCSelection.Desc>{context.forCorporate}</KYCSelection.Desc>
            <button
              className="button-primary"
              type="submit"
              onClick={(): void => history.push(RouteConstants.KYCInstitution)}
            >
              {context.select}
            </button>
          </KYCSelection.Card>
        </KYCSelection.Wrapper>
      </KYCSelection>
    </div>
  )
}

export default KYCTypeSelection
