import React from 'react'
import context from '../util/localize'
import { FEE_URL, KITCO_ACCOUNT_SETTINGS, TRANSACTION_HISTORY_URL } from '../constants/config'
import RouteConstants from '../routes/routeConstants'

interface HomeProps {
  accountStatus?: string
  bankValues
  burnAddress: string
  ethAddress: string
  hasEthAddr: boolean
  hasBankAcc: boolean
  livePrice: string
  priceDate: string
  wireReference: string
  userEmail: string
}

const Home = ({
  accountStatus,
  bankValues,
  burnAddress,
  ethAddress,
  hasEthAddr,
  hasBankAcc,
  livePrice,
  priceDate,
  wireReference,
  userEmail,
}: HomeProps): JSX.Element => {
  return (
    <React.Fragment>
      <div className="home-container">
        <div className="kgld-gold-price-wrapper">
          <div className="kgld-gold-price-container">
            <p className="spot-text">{context.KGLDSpotPrice} </p>
            <p className="kgld-color live-price">${livePrice} </p>
            <p className="time">{priceDate}</p>
          </div>
        </div>
        <div className="home-user-msg">
          {context.welcome}, {userEmail && bankValues.accHolder ? `${bankValues.accHolder}(${userEmail})` : userEmail}
        </div>
        <div className="home-wrapper">
          {accountStatus && accountStatus === 'rejected' ? (
            <div className="home-card-title text-align-center" style={{ margin: '5rem auto', maxWidth: '80%' }}>
              Thank you for your interest in a Kitco Gold account. Unfortunately we cannot service you at this time.
            </div>
          ) : (
            <>
              <div className="home-card">
                <div>
                  <div className="home-card-title bottom-margin-1 text-uppercase">
                    {context.buy} <span className="kgld-color">{context.KGLD}</span>
                  </div>
                  {hasEthAddr ? (
                    <React.Fragment>
                      <div className="step">
                        <div className="step-circle">1</div>
                        {wireReference === '' || wireReference.toLowerCase() === 'not assigned'.toLowerCase() ? (
                          <div className="step-text">{context.preApprovalStepOne}</div>
                        ) : (
                          <div className="step-text">{context.preBuyStepTwo}</div>
                        )}
                      </div>
                      {wireReference === '' || wireReference.toLowerCase() === 'not assigned'.toLowerCase() ? null : (
                        <BankAcc />
                      )}
                      <div className="step">
                        <div className="step-circle">2</div>
                        <div className="step-text">
                          {context.buyStepTwoPart1}
                          {ethAddress}
                          {context.buyStepTwoPart2}
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="step">
                        <div className="step-circle">1</div>
                        <div className="step-text">{context.preBuyStepOne}</div>
                      </div>
                      <div className="step">
                        <div className="step-circle">2</div>
                        <div className="step-text">{context.preBuyStepTwo}</div>
                      </div>
                      <div className="step">
                        <div className="step-circle">3</div>
                        <div className="step-text">{context.preBuyStepThree}</div>
                      </div>
                    </React.Fragment>
                  )}
                </div>

                <p className="text-align-center text-instruction">
                  <span className="text-uppercase text-bold-imp">{context.important}: </span>
                  {hasEthAddr ? context.buyInstruction : context.preBuyInstruction}
                </p>
                {hasEthAddr ? (
                  <div className="center-align-vertical-container">
                    <p className="text-align-center text-instruction">{context.referenceNumberMessage}:</p>
                    <div className="display-field-box">{wireReference}</div>
                  </div>
                ) : (
                  <a className="btn" href={RouteConstants.Crypto}>
                    {context.addCryptoAccount}
                  </a>
                )}
              </div>
              <div className="home-card">
                <div>
                  <div className="home-card-title bottom-margin-1 text-uppercase">
                    {context.sell} <span className="kgld-color">{context.KGLD}</span>
                  </div>
                  {hasBankAcc ? (
                    <React.Fragment>
                      <div className="step">
                        <div className="step-circle">1</div>
                        <div className="step-text">{context.sellStepOne}</div>
                      </div>
                      <div className="step">
                        <div className="step-circle">2</div>
                        <div className="step-text">
                          {context.sellStepTwoPart1}
                          {bankValues.accNumber}
                          {context.sellStepTwoPart2}
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="step">
                        <div className="step-circle">1</div>
                        <div className="step-text">{context.preSellStepOne}</div>
                      </div>
                      <div className="step">
                        <div className="step-circle">2</div>
                        <div className="step-text">{context.preSellStepTwo}</div>
                      </div>
                      <div className="step">
                        <div className="step-circle">3</div>
                        <div className="step-text">{context.preSellStepThree}</div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <p className="text-align-center text-instruction">
                  <span className="text-uppercase text-bold-imp">{context.important}: </span>
                  {hasBankAcc ? context.sellInstruction : context.preSellInstruction}
                </p>
                {hasBankAcc ? (
                  <div className="center-align-vertical-container">
                    <p className="text-align-center text-instruction">{context.burnAddressMessage}: </p>
                    <div className="display-field-box">{burnAddress}</div>
                  </div>
                ) : (
                  <a className="btn" href={RouteConstants.Bank}>
                    {context.addBankAccount}
                  </a>
                )}
              </div>
            </>
          )}
        </div>
        <div className="account-instruction">
          <p className="text-align-center account-instruction-link">
            {context.updateAccountSettings}
            <a
              href={KITCO_ACCOUNT_SETTINGS}
              className="link underline text-uppercase"
              rel="noopener noreferrer"
              target="_blank"
            >
              {context.here}
            </a>
          </p>
          <p className="text-align-center account-instruction-link">
            {context.feeInfo}
            <a href={FEE_URL} className="link underline text-uppercase" rel="noopener noreferrer" target="_blank">
              {context.here}
            </a>
          </p>
          <p className="text-align-center account-instruction-link">
            {context.requestTransactionHistory}
            <a href={TRANSACTION_HISTORY_URL} className="link underline" rel="noopener noreferrer" target="_blank">
              {context.here}
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

const BankAcc = (): any => {
  return (
    <div className="bank-acc">
      <div className="title">Bank name</div>
      <div className="desc">Metropolitan Commercial Bank</div>
      <div className="title">Bank address</div>
      <div className="desc">99 Park Avenue, New York, NY 10016, United States</div>
      <div className="title">SWIFT/BIC</div>
      <div className="desc">MCBEUS33</div>
      <div className="title">Account/Beneficiary name</div>
      <div className="desc">First Digital Trust Limited</div>
      <div className="title">Account Number</div>
      <div className="desc">7801000422</div>
      <div className="title">Routing Number</div>
      <div className="desc">026013356</div>
      <div className="title">Beneficiary Address</div>
      <div className="desc">Suite 2303, 23rd Floor, World Wide House, 19 Des Voeux, Road Central, Hong Kong</div>
    </div>
  )
}

export default Home
