import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../blocks/footer'
import { KITCO_FAQ_URL, KITCO_HELP_URL, KITCO_PRIVACY_POLICY } from '../constants/config'
import context from '../util/localize'

const FooterContainer = (): JSX.Element => {
  return (
    <React.Fragment>
      <Footer>
        <Footer.FooterContent>
          <Footer.Group>
            {/* <Link to={''}>
              <Footer.FooterItem>{context.about}</Footer.FooterItem>
            </Link> */}
            <Link to={{ pathname: KITCO_FAQ_URL }} target="_blank">
              <Footer.FooterItem>{context.faq}</Footer.FooterItem>
            </Link>
            <Link to={{ pathname: KITCO_HELP_URL }} target="_blank">
              <Footer.FooterItem>{context.help}</Footer.FooterItem>
            </Link>
            <Link to={{ pathname: KITCO_PRIVACY_POLICY }} target="_blank">
              <Footer.FooterItem>{context.privacyPolicy}</Footer.FooterItem>
            </Link>
            {/* <Link to={''}>
              <Footer.FooterItem>{context.termsofUse}</Footer.FooterItem>
            </Link> */}
          </Footer.Group>
        </Footer.FooterContent>
        <Footer.FooterBottom>
          <Footer.Text>
            {context.copyright} &copy; {new Date().getFullYear()} {context.copyrightText}
          </Footer.Text>
        </Footer.FooterBottom>
      </Footer>
    </React.Fragment>
  )
}
export default FooterContainer
