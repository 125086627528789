import { merge } from '../../util'

/**
 * BoolRules specifies boolean value rules that may be
 * applied to a message as a field decorator.
 *
 * e.g. @validation(new BoolRules({ required: true }))
 *
 * Rules are defined by go-packages/schema/validate/bool.proto
 */
export class BoolRules {
  constructor(init: BoolRules) {
    if (init) merge(init, this)
  }
  public required = false
  public const?: boolean
}

/**
 * isBoolean will return whether astring value is an acceptable
 * value for a boolean type field.
 *
 * @param value
 * returns boolean
 */
export function isBoolean(value: string): boolean {
  if (value === 'true' || value === 'false') return true
  if (value === '1' || value === '0') return true
  return false
}

/**
 * parseBoolean will return the boolean value of a
 * boolean field that allows for numeric and string
 * representations of boolean values
 *
 * @param value
 * returns boolean
 */
export function parseBoolean(value: string): boolean | undefined {
  if (value === 'true' || value === '1') return true
  if (value === 'false' || value === '0') return false
  return undefined
}

/**
 * validateBoolValue will validate whether a field is a valid string
 * representation of a boolean value, and that the boolean value complies with
 * the field rules (if any). Returns an error message (string) or blank string.
 * @param value
 * @param rules
 * returns string
 */
export function validateBoolValue(value: string, rules?: BoolRules): string {
  if (!value && (!rules || !rules?.required)) return ''
  if (rules) {
    if (!value && rules.required) return 'A value is required'
  }
  if (!isBoolean(value)) return 'A boolean value is required'

  if (!rules) return ''
  if (rules.const === true && parseBoolean(value) !== true) {
    return `A true value is required`
  }
  if (rules.const === false && parseBoolean(value) !== false) {
    return `A false value is required`
  }
  return ''
}

/**
 * validateBoolInput will return whether the present string input can lead to a
 * valid boolean value that conforms to the given rules
 *
 * @param input
 * @param rules
 * returns boolean
 */
export function validateBoolInput(input: string, rules?: BoolRules): boolean {
  if (input === '') return true
  if (input === '1' || input === 't' || input === 'tr' || input === 'tru' || input === 'true')
    return !rules || !rules.const === false
  if (input === '0' || input === 'f' || input === 'fa' || input === 'fal' || input === 'fals' || input === 'false')
    return !rules || !rules.const === true
  return false
}
