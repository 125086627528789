import React, { useEffect } from 'react'
import RouteConstants from '../../routes/routeConstants'
import context from '../../util/localize'

type SuccessPageProps = {
  clearSuccessMethod: (val: boolean) => void
  successMsg: string
  showRedirectToHome?: boolean
}

export const SUCCESS_DATA_TESTID = 'success-data-test'

const SuccessBlock = ({ clearSuccessMethod, successMsg, showRedirectToHome }: SuccessPageProps): any => {
  useEffect(() => {
    const elem = document.getElementById('success')
    if (elem) {
      setTimeout(() => {
        if (clearSuccessMethod) {
          clearSuccessMethod(false)
        }
      }, 30000)
    }
  })

  return (
    <div className="success-container" id="success" data-testid={SUCCESS_DATA_TESTID}>
      <img src="/images/success.png" alt="success.png" />
      <p>{successMsg ? successMsg : context.success}</p>
      {showRedirectToHome ? (
        <a href={RouteConstants.Account} className="btn">
          Go To Home
        </a>
      ) : null}
    </div>
  )
}

export default SuccessBlock
