import React, { useEffect } from 'react'
// import context from '../util/localize'

export type LoadingProps = {
  clearLoadingMethod?: (val: boolean) => void
  setTimeoutErrorMethod?: (val: string) => void
}

const LoadingBlock = ({ clearLoadingMethod }: LoadingProps): JSX.Element => {
  useEffect(() => {
    const elem = document.getElementById('loading')
    if (elem) {
      setTimeout(() => {
        const elem1 = document.getElementById('loading')
        if (elem1) {
          elem1.style.display = 'none'
          if (clearLoadingMethod) clearLoadingMethod(false)

          // if (setTimeoutErrorMethod) setTimeoutErrorMethod('Request timed out')
          // should we throw error if it is still loading?
          // decide and set timeout value to throw an error if it takes longer than usual
        }
      }, 120000)
    }
  })

  return (
    <div className="coverup-container">
      <div className="circle" id="loading">
        <div className="circle1 child"></div>
        <div className="circle2 child"></div>
        <div className="circle3 child"></div>
        <div className="circle4 child"></div>
        <div className="circle5 child"></div>
        <div className="circle6 child"></div>
        <div className="circle7 child"></div>
        <div className="circle8 child"></div>
        <div className="circle9 child"></div>
        <div className="circle10 child"></div>
        <div className="circle11 child"></div>
        <div className="circle12 child"></div>
      </div>
    </div>
  )
}

export default LoadingBlock
