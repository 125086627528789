import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { ContainerRouterProps } from '../../interface'
import ErrorAlert from '../usernotifications/erroralert'
import { api, TextInput, useAuthContext } from '@/ApiReact'
import LoadingBlock from '../usernotifications/loading'
import AdminComponent from '../../blocks/admin'
import { checkAdminEmail, convertTimestampToLocalString } from '../../util/helpers'
import RouteConstants from '../../routes/routeConstants'

interface PriceCalculatorProps extends ContainerRouterProps {
  setErrors: React.Dispatch<any>
  getEmail: () => void
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  isLoading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
  success: boolean
  showContent: boolean
}

const PriceCalculatorContainer: React.FC<PriceCalculatorProps> = (props: PriceCalculatorProps) => {
  const auth = useAuthContext()
  const [data, setData] = useState({ tokens: '', usd: '' })
  const [kgldTousd, convertKgldTousd] = useState(false)
  const [generalError, setGeneralError] = useState('')
  const [livePrice, setLivePrice] = useState({ askPrice: '0.0', bidPrice: '0.0' })
  const [priceDate, setPriceDate] = useState('')
  const [feeAmount, setFeeAmount] = useState(0) // in KGLD
  const [finalAmount, setFinalAmount] = useState('') // amount after subtracting fee
  const [isLoading, setLoading] = useState(true)
  const [userGuid, setUserGuid] = useState('')

  const getFeeValue = (tokenAmount: number): number => {
    let fee: number // in percentage
    if (tokenAmount < 25) {
      fee = 0.0125
    } else if (tokenAmount >= 25 && tokenAmount < 75) {
      fee = 0.01
    } else {
      // > 75
      fee = 0.0075
    }
    return tokenAmount * fee
  }

  const calculateValue = (tokenTousd: boolean): void => {
    if (tokenTousd) {
      // kgld to usd
      const initialTokenValue = parseFloat(data.tokens)
      const feeValue = getFeeValue(initialTokenValue)
      setFeeAmount(feeValue)
      const initialUSDVal = initialTokenValue * parseFloat(livePrice.bidPrice)
      const finalUSDVal = (initialTokenValue - feeValue) * parseFloat(livePrice.bidPrice)
      setData({ tokens: data.tokens, usd: initialUSDVal.toString() })
      setFinalAmount(finalUSDVal.toString())
    } else {
      //usd to kgld
      const tokens = parseFloat(data.usd) / parseFloat(livePrice.askPrice)
      const feeValue = getFeeValue(tokens)
      setFeeAmount(feeValue)
      // subtract fee from token amount
      const tokenValue = tokens - feeValue
      setFinalAmount(tokenValue.toString())
      setData({ tokens: tokens.toString(), usd: data.usd })
    }
  }

  const clear = (): void => {
    setUserGuid('')
    setData({ usd: '', tokens: '' })
    setFinalAmount('')
    setFeeAmount(0)
  }

  const getEmail = async (): Promise<void> => {
    try {
      const response = await auth.getCurrentAuthenticatedUser()

      if (response.isOk()) {
        const str = response.value.attributes.email
        const isValid = checkAdminEmail(str)
        if (!isValid) {
          props.history.push(RouteConstants.Home)
        }
      }
      setLoading(false)
    } catch (err) {
      setGeneralError(JSON.stringify(err))
    }
  }

  const getLiveGoldPrice = async (): Promise<void> => {
    try {
      const response = await api.firstdigital.getGoldPrice()
      if (response.success && response.askPrice && response.bidPrice) {
        setLivePrice({
          askPrice: parseFloat(response.askPrice).toFixed(2).toString(),
          bidPrice: parseFloat(response.bidPrice).toFixed(2).toString(),
        })

        const formatDate = convertTimestampToLocalString(response.timestamp)
        setPriceDate(formatDate)
      } else {
        setGeneralError(`Couldn't fetch spot price`)
      }
    } catch (err) {
      setGeneralError(`Couldn't fetch spot price ` + JSON.stringify(err))
    }
  }

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const val = e.currentTarget.value
    setUserGuid(val)
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>, tokenTousd: boolean): void => {
    e.preventDefault()
    const val = e.currentTarget.value
    e.currentTarget.value
    convertKgldTousd(tokenTousd)
    if (tokenTousd) {
      setData({ tokens: val, usd: '' })
      setFinalAmount('')
      setFeeAmount(0)
    } else {
      setData({ usd: val, tokens: '' })
      setFinalAmount('')
      setFeeAmount(0)
    }
  }

  const setAllLoading = (): void => {
    setLoading(false)
  }

  useEffect(() => {
    getEmail()
    getLiveGoldPrice()
    // eslint-disable-next-line
  }, [])

  return (
    <AdminComponent className="container">
      {isLoading ? <LoadingBlock clearLoadingMethod={setAllLoading} setTimeoutErrorMethod={setGeneralError} /> : null}
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      <div className="card-small">
        <div className="kgld-gold-price-wrapper">
          <a href={RouteConstants.Admin} className="link underline">
            Go Back
          </a>
        </div>
        <table className="border user-table bottom-margin">
          <tbody>
            <tr>
              <th>Asset</th>
              <th>Ask Price</th>
              <th>Bid price</th>
              <th>Time</th>
              <td rowSpan={2}>
                <button onClick={getLiveGoldPrice}>Refresh</button>
              </td>
            </tr>

            <tr>
              <th>Gold</th>
              <th>${livePrice.askPrice}</th>
              <th>${livePrice.bidPrice}</th>
              <th>{priceDate}</th>
            </tr>
          </tbody>
        </table>
        <div className="flex-row">
          <div className="col-6">
            <TextInput
              name="username"
              type="text"
              label="USER GUID"
              required={true}
              onChange={handleInputChange}
              value={userGuid}
              error={generalError}
              autoCompleteOff={true}
            />
          </div>
          <div className="col-6 align-vertical">
            <button onClick={clear}>Clear</button>
          </div>
        </div>
        <table className="border calc-table">
          <tbody>
            <tr>
              <th colSpan={6}>
                Calculator
                {/* Calculator with fee &nbsp;
                <input
                  type="number"
                  value={fee}
                  onChange={(e): void => {
                    const val = e.currentTarget.value
                    setFeeValue(val)
                  }}
                  min="0"
                  max="100"
                  maxLength={3}
                />
                % */}
              </th>
            </tr>
            <tr>
              <th colSpan={3}>Calculate token value for USD</th>
              <th colSpan={3}>Calculate USD value for tokens</th>
            </tr>
            <tr>
              {/* COL 1 */}
              <th colSpan={1}>Amount(USD)</th>
              <th colSpan={2}>
                <div className="col-6">
                  <TextInput
                    name="usd"
                    type="number"
                    label=""
                    required={true}
                    onChange={(e): void => handleChange(e, false)}
                    value={kgldTousd ? '' : data.usd}
                    error={generalError}
                  />
                </div>
              </th>
              {/* COL 2 */}
              <th colSpan={1}>Amount(KGLD)</th>
              <th colSpan={2}>
                <div className="col-6">
                  <TextInput
                    name="tokens"
                    type="number"
                    label=""
                    required={true}
                    onChange={(e): void => handleChange(e, true)}
                    value={kgldTousd ? data.tokens : ''}
                    error={generalError}
                  />
                </div>
              </th>
            </tr>
            <tr>
              {/* COL 1 */}
              <th colSpan={1}>KGLD value</th>
              <th colSpan={2}>
                <div className="col-6">
                  <TextInput
                    name="usdtotoken"
                    type="number"
                    label=""
                    required={true}
                    value={kgldTousd ? '' : data.tokens}
                    disabled={true}
                    error=""
                  />
                </div>
              </th>
              {/* COL 2 */}
              <th colSpan={1}>USD value</th>
              <th colSpan={2}>
                <div className="col-6">
                  <TextInput
                    name="tokenstousdamount"
                    type="number"
                    label=""
                    required={true}
                    value={kgldTousd ? data.usd : ''}
                    disabled={true}
                    error=""
                  />
                </div>
              </th>
            </tr>
            <tr>
              {/* COL 1 */}
              <th colSpan={1}>Fee (KGLD)</th>
              <th colSpan={2}>
                <div className="col-6">
                  <TextInput
                    name="usdtotokenfee"
                    type="number"
                    label=""
                    required={true}
                    value={kgldTousd ? '' : feeAmount}
                    disabled={true}
                    error=""
                  />
                </div>
              </th>
              {/* COL 2 */}
              <th colSpan={1}>Fee (KGLD)</th>
              <th colSpan={2}>
                <div className="col-6">
                  <TextInput
                    name="usdtotokenfee"
                    type="number"
                    label=""
                    required={true}
                    value={kgldTousd ? feeAmount : ''}
                    disabled={true}
                    error=""
                  />
                </div>
              </th>
            </tr>
            <tr>
              {/* COL 1 */}
              <th colSpan={1}>Final Amount(KGLD)</th>
              <th colSpan={2}>
                <div className="col-6">
                  <TextInput
                    name="usdtotokenamount"
                    type="number"
                    label=""
                    required={true}
                    value={kgldTousd ? '' : finalAmount}
                    disabled={true}
                    error=""
                  />
                </div>
              </th>
              {/* COL 2 */}
              <th colSpan={1}>Final Amount(USD)</th>
              <th colSpan={2}>
                <div className="col-6">
                  <TextInput
                    name="usdtotokenamount"
                    type="number"
                    label=""
                    required={true}
                    value={kgldTousd ? finalAmount : ''}
                    disabled={true}
                    error=""
                  />
                </div>
              </th>
            </tr>

            <tr>
              {/* COL 1 */}
              <td colSpan={3}>
                <button onClick={(): void => calculateValue(false)}>Calculate</button>
              </td>
              {/* COL 2 */}
              <td colSpan={3}>
                <button onClick={(): void => calculateValue(true)}>Calculate</button>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="table-wrapper">
          <table className="border user-table">
            <tbody></tbody>
          </table>
        </div>
      </div>
    </AdminComponent>
  )
}

export default withRouter(PriceCalculatorContainer)
