import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useAuthContext, useFormState } from '@/ApiReact'
import Forgot from '../../components/user/forgot'
import { KeyValues, ContainerRouterProps } from '../../interface'
import ErrorAlert from '../usernotifications/erroralert'
import LoadingBlock from '../usernotifications/loading'
import RouteConstants from '../../routes/routeConstants'

type ForgotPasswordStateType = {
  values: KeyValues
  setValues: React.Dispatch<React.SetStateAction<KeyValues>>
  errors: KeyValues
  setErrors: React.Dispatch<React.SetStateAction<KeyValues>>
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const ForgotContainer: React.FC<ContainerRouterProps> = (props: ContainerRouterProps) => {
  const [isLoading, setLoading] = useState(false)
  const [generalError, setGeneralError] = useState('')

  const useForgotPasswordFormState = (): ForgotPasswordStateType => {
    const initialValues: KeyValues = { email: '' }
    const auth = useAuthContext()

    const { values, setValues, errors, setErrors, handleChange, handleSubmit } = useFormState({
      initialValues,
      handleSubmit: async (event: React.FormEvent<HTMLFormElement>, values: KeyValues): Promise<void> => {
        event.preventDefault()
        setLoading(true)
        try {
          const response = await auth.forgotPassword(values.email)
          if (response.isOk()) {
            const encodedEmail = encodeURIComponent(values.email)
            const routeWithEmail = `${RouteConstants.ResetPassword}?email=${encodedEmail}`
            props.history.push(routeWithEmail)
          } else {
            setLoading(false)
            setErrors({ email: response.error.message })
          }
        } catch (e) {
          setLoading(false)
          setGeneralError(e.error.message)
        }
      },
    })
    return { values, setValues, errors, setErrors, handleChange, handleSubmit }
  }
  const { values, errors, handleChange, handleSubmit } = useForgotPasswordFormState()

  return (
    <React.Fragment>
      {isLoading ? <LoadingBlock /> : null}
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      <Forgot values={values} errors={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </React.Fragment>
  )
}

export default withRouter(ForgotContainer)
