import React, { useEffect } from 'react'

export type ErrorAlertProps = {
  errorMsg: string
  clearErrorMethod: React.Dispatch<React.SetStateAction<string>>
}

const ErrorAlert = ({ errorMsg, clearErrorMethod }: ErrorAlertProps): JSX.Element => {
  useEffect(() => {
    const elem = document.getElementById('alert')
    if (elem) {
      setTimeout(() => {
        elem.style.display = 'none'

        if (clearErrorMethod) {
          clearErrorMethod('')
        }
      }, 120000)
    }
  })

  const handleClose = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    const elem = document.getElementById('alert')
    if (elem) {
      elem.style.display = 'none'
    }
    if (clearErrorMethod) {
      clearErrorMethod('')
    }
  }

  return (
    <div className="coverup-container">
      <div className="alert" id="alert">
        <div className="alert-text">{errorMsg}</div>

        <span className="closebtn" onClick={handleClose}>
          [&times;]
        </span>
      </div>
    </div>
  )
}

export default ErrorAlert
