import React, { useState, FormEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ContainerRouterProps } from '../interface'
import { useAuthContext, useFormStateAction } from '@/ApiReact'
import { ActionEthCryptoAccount, validateMessage, EthCryptoAccount, EthCryptoAccountErrors } from '@/Api'
import ErrorAlert from './usernotifications/erroralert'
import LoadingBlock from './usernotifications/loading'
import AddEthCryptoAccount from '../components/EthereumAccount/EthereumAccount'
import context from '../util/localize'
import RouteConstants from '../routes/routeConstants'

type ETHFormStates = {
  values: EthCryptoAccount
  setValues: React.Dispatch<EthCryptoAccount>
  errors: EthCryptoAccountErrors
  setErrors: React.Dispatch<EthCryptoAccountErrors>
  getInitialValues: () => void
  handleChange: (e: FormEvent<HTMLInputElement>) => void
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  handleNumberInputChange?: (e: FormEvent<HTMLInputElement>) => void
  success: boolean
  setSuccess: (val: boolean) => void
}

const EthereumAccountContainer: React.FC<ContainerRouterProps> = (props: ContainerRouterProps) => {
  const auth = useAuthContext()
  const [generalError, setGeneralError] = useState('')
  const [displayTextEdit, setDisplayTextEdit] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const appliedForVerification = useSelector((state) => state.kgldReducer.appliedForVerification)

  const useEthAccountFormState = (): ETHFormStates => {
    const [success, setSuccess] = useState(false)
    const actionEthAccount = new ActionEthCryptoAccount()
    const { values, setValues, errors, setErrors, handleChange } = useFormStateAction(actionEthAccount)

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault()
      setLoading(true)
      const [valid, newErrors] = validateMessage(values, actionEthAccount.messageType, actionEthAccount.errorsType)
      if (!valid) {
        setLoading(false)
        setErrors(newErrors)
        return
      }

      const response = await auth.api().set(actionEthAccount.setUrl, values)
      if (response.isOk()) {
        if (typeof response.value.errors === 'object' && Object.keys(response.value.errors).length > 0) {
          setErrors(response.value.errors)
        } else {
          setValues(new actionEthAccount.messageType(response.value))
          if (response.value.success) {
            setSuccess(true)
            setTimeout(() => {
              props.history.push(RouteConstants.Home)
            }, 2000)
          } else if (response.value.error) {
            setGeneralError(JSON.stringify(response.value.error))
          } else if (response.isErr()) {
            setGeneralError(JSON.stringify(response))
          }
        }
        setLoading(false)
      } else {
        setLoading(false)
        setGeneralError(JSON.stringify(response.error))
        return
      }
    }

    const getInitialValues = async (): Promise<void> => {
      try {
        setLoading(true)
        //redirect if not applied for verification
        if (!appliedForVerification) {
          props.history.push(RouteConstants.KYCType)
        }
        const values = await auth.api().get(actionEthAccount.getUrl)
        if (
          values === undefined ||
          values === null ||
          (typeof values === 'object' && Object.keys(values).length === 0)
        ) {
          setDisplayTextEdit(false)
          setValues(new actionEthAccount.messageType(values))
        } else if (values && values.address !== '') {
          setDisplayTextEdit(true)
        } else if (values && values.address === '') {
          setDisplayTextEdit(false)
        } else if (typeof values.errors === 'object' && Object.keys(values.errors).length > 0) {
          setGeneralError(JSON.stringify(values.errors))
        }
        setLoading(false)
      } catch (err) {
        setLoading(false)
        setGeneralError(JSON.stringify(err).length > 2 ? JSON.stringify(err) : 'Unexpected error occured')
      }
    }

    return {
      values: values,
      setValues: setValues,
      errors: errors,
      setErrors: setErrors,
      getInitialValues: getInitialValues,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      success: success,
      setSuccess: setSuccess,
    }
  }
  const { values, errors, getInitialValues, handleChange, handleSubmit, success, setSuccess } = useEthAccountFormState()

  useEffect(() => {
    getInitialValues()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="container">
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      {isLoading ? <LoadingBlock clearLoadingMethod={setLoading} setTimeoutErrorMethod={setGeneralError} /> : null}
      <div className="card-small">
        <p className="text-align-center uppercase text-bold">
          {displayTextEdit ? context.updateEthAddr : context.addEthAddr}
        </p>

        <AddEthCryptoAccount
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          values={values}
          errors={errors}
          success={success}
          setSuccess={setSuccess}
        />
      </div>
    </div>
  )
}

export default withRouter(EthereumAccountContainer)
