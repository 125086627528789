import React from 'react'
import context from '../../util/localize'
import { TextInput } from '@/ApiReact'
import RouteConstants from '../../routes/routeConstants'

const UserSignUp = ({ SignupFormState, showPassword }: any): any => {
  return (
    <React.Fragment>
      <p className="text-align-center text-bold">{context.signupMessage}</p>
      <form onSubmit={SignupFormState.handleSubmit}>
        <TextInput
          name="email"
          onChange={SignupFormState.handleChange}
          placeholder={context.email}
          type="email"
          value={SignupFormState.values.email}
          error={SignupFormState.errors.email}
          required={true}
          label={context.email}
        />

        <TextInput
          type={showPassword ? 'input' : 'password'}
          name="password"
          placeholder={context.password}
          label={context.password}
          value={SignupFormState.values.password}
          onChange={SignupFormState.handlePasswordChange}
          error={SignupFormState.errors.password}
          required={true}
          // showPassword={showPassword}
          // toggleShowPassword={toggleShowPassword}
        />

        <TextInput
          type={showPassword ? 'input' : 'password'}
          name="confirmPassword"
          placeholder={context.confirmPassword}
          value={SignupFormState.values.confirmPassword}
          onChange={SignupFormState.handleChange}
          error={SignupFormState.errors.confirmPassword}
          required={true}
          label={context.confirmPassword}
          // showPassword={showPassword}
          // toggleShowPassword={toggleShowPassword}
        />

        <div className="space-between-container">
          <a className="link" href={RouteConstants.Home}>
            {context.loginInstead}
          </a>

          <a className="link" href={RouteConstants.ForgotPassword}>
            {context.resetPassword}
          </a>
        </div>
        <div className="center-align-vertical-container">
          <button className="button-primary" type="submit">
            {context.createAccount}
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default UserSignUp
