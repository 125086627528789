import React from 'react'
import context from '../../util/localize'
import { KeyValues, TextInput } from '@/ApiReact'
import SuccessBlock from '../../container/usernotifications/success'

export type ChangePasswordProps = {
  values: KeyValues
  errors: KeyValues
  setErrors: React.Dispatch<React.SetStateAction<KeyValues>>
  setValues: React.Dispatch<React.SetStateAction<KeyValues>>
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  success: boolean
  setSuccess: (val: boolean) => void
}

export const CHANGE_PASSWORD_DATA_TESTID = 'changePassword'

const ChangePassword = (Password: ChangePasswordProps): JSX.Element => {
  return (
    <React.Fragment>
      {Password.success ? (
        <SuccessBlock clearSuccessMethod={Password.setSuccess} successMsg={context.changePassword} />
      ) : (
        <form onSubmit={Password.handleSubmit} data-testid={CHANGE_PASSWORD_DATA_TESTID}>
          <div className="account-content">
            <div>
              <div className="title-med text-align-center text-uppercase">{context.changePassword}</div>
            </div>
            <div>
              <TextInput
                name="oldPassword"
                onChange={Password.handleChange}
                placeholder={context.oldPassword}
                type="password"
                value={Password.values.oldPassword || ''}
                error={Password.errors.oldPassword}
                required={true}
                label={context.oldPassword}
              />

              <TextInput
                name="newPassword"
                onChange={Password.handleChange}
                placeholder={context.newPassword}
                type="password"
                value={Password.values.newPassword || ''}
                error={Password.errors.newPassword}
                required={true}
                label={context.newPassword}
              />

              <TextInput
                name="confirmNewPassword"
                onChange={Password.handleChange}
                placeholder={context.confirmNewPassword}
                type="password"
                value={Password.values.confirmNewPassword || ''}
                error={Password.errors.confirmNewPassword}
                required={true}
                label={context.confirmNewPassword}
              />
            </div>
            <button className="button-primary" type="submit">
              {context.submit}
            </button>
          </div>
        </form>
      )}
    </React.Fragment>
  )
}

export default ChangePassword
