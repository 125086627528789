import React from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from '@/ApiReact'

const Sandbox: React.FC = () => {
  const auth = useAuthContext()

  const loginout = (): React.ReactFragment => {
    return auth.loggedIn ? (
      <button className="link" onClick={(): boolean => auth.api().auth.logout()}>
        logout
      </button>
    ) : (
      <Link to="/login">login</Link>
    )
  }

  return (
    <div>
      <h1>Sandbox</h1>
      <table className="border">
        <tbody>
          <tr>
            <td>Logged in</td>
            <td>{`${auth.loggedIn}`}</td>
            <td>{loginout()}</td>
          </tr>
          <tr>
            <td>Cognito Pool:</td>
            <td>{auth.api().config.AWS_USER_POOL_ID}</td>
          </tr>
          <tr>
            <td>API client version:</td>
            <td>{auth.api().version()}</td>
          </tr>
          <tr>
            <td>API endpoint: </td>
            <td>
              <a href={`${auth.api().config.API_ENDPOINT}/healthcheck`} target="_blank" rel="noopener noreferrer">
                {auth.api().getEndpoint()}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Sandbox
