import { merge } from '../../util'
import { StringRules, validation } from '../validation'

const NamePattern = new RegExp('^[A-Z][a-zA-Z][^#&<>\\"~;$^%{}()?\\d]{1,20}$')

//const MiddleNamePattern = new RegExp('^[A-Z]([a-z]+)|([A-Z]\\.?)$')

export class Name {
  constructor(init?: Name) {
    if (init) merge(init, this)
  }

  @validation(new StringRules({ required: true, pattern: NamePattern }))
  public last? = ''
  @validation(new StringRules({ required: true, pattern: NamePattern }))
  public first? = ''
  public preferred? = ''
  public matronymic? = ''
  public maiden? = ''
  public latinFirst? = ''
  public latinLast? = ''
  public middle? = ''
}
