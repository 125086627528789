import { merge } from '../../util'
import { isUInt } from './uint'
/**
 * TimeStampRules specifies timestamp value rules that may be
 * applied to a message as a field decorator.
 *
 * e.g. @validation(new TimeStampRules({ required: true }))
 *
 * Rules are defined by go-packages/schema/validate/timestamp.proto
 */
export class TimeStampRules {
  constructor(init: TimeStampRules) {
    if (init) merge(init, this)
  }
  public required = false
  public min?: number
  public max?: number
}

/**
 * validateTimeStampValue will validate whether a field is a valid string
 * representation of a timestamp value, and that the value complies with
 * the field rules (if any). Returns an error message (string) or blank string.
 * @param value
 * @param rules
 * returns string
 */
export function validateTimeStampValue(value: string, rules?: TimeStampRules): string {
  if (!value && (!rules || !rules?.required)) return ''
  if (rules) {
    if (!value && rules.required) return 'A value is required'
  }
  if (!isUInt(value)) return 'A positive integer value is required'

  if (!rules) return ''
  return ''
}

/**
 * validateTimeStampInput is not implemented. Timestamps are not
 * typically input by users, but rather generated.
 *
 * If input by users, they will be a date/time selector translated
 * into a timestamp.
 *
 * @param input
 * @param rules
 * returns boolean
 */
export function validateTimeStampInput(input: string, rules?: TimeStampRules): boolean {
  // not implemented
  if (!input || !rules) return true
  return true
}
