import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import NavBar from '../navbar/public'
import Forgot from '../container/user/forgot'
import Login from '../container/user/login'
import Reset from '../container/user/reset'
import Verify from '../container/user/verify'
import Footer from '../container/footer'
import RouteConstants from './routeConstants'

const Routes: React.FC = () => {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path={RouteConstants.Login} component={Login} />
        <Route
          path={RouteConstants.User}
          render={({ match: { path } }): JSX.Element => (
            <>
              <Route exact path={path} component={Login} />
              <Route path={`${path}${RouteConstants.Verify}`} component={Verify} />
              <Route path={`${path}${RouteConstants.Signup}`} component={Login} />
              <Route path={`${path}${RouteConstants.ForgotPassword}`} component={Forgot} />
              <Route path={`${path}${RouteConstants.ResetPassword}`} component={Reset} />
            </>
          )}
        ></Route>
        <Route path={RouteConstants.Signup} component={Login} />
        <Route path={RouteConstants.ForgotPassword} component={Forgot} />
        <Route path={RouteConstants.ResetPassword} component={Reset} />
        <Route path={RouteConstants.Verify} component={Verify} />
        <Redirect to={RouteConstants.Login} />
      </Switch>
      <Footer />
    </Router>
  )
}

export default Routes
