import React from 'react'
import TermsOfServiceWrapper from '../../blocks/termsofservice'
import context from '../../util/localize'
import { DOCUMENT_UPDATE_DATE, KITCO_PRIVACY_POLICY, KITCO_TERMS_AND_CONDITIONS } from '../../constants/config'

export type TermsAgreementProps = {
  accepted: () => void
  declined: () => void
  hasAcceptedPreviously: string
  termsAgreementPossibleStates: string[]
}

const SignUpTerms = ({
  accepted,
  declined,
  hasAcceptedPreviously,
  termsAgreementPossibleStates,
}: TermsAgreementProps): JSX.Element => {
  return (
    <React.Fragment>
      <TermsOfServiceWrapper>
        <div className="coverup-container">
          {hasAcceptedPreviously === termsAgreementPossibleStates[0] && (
            <div className="terms-of-service-card">
              <h1 className="home-card-title">{context.updatedTerms}</h1>
              <p>{context.updatedTermsTitle}</p>
              <p>
                {context.updatedTOS1}
                <a className="link" href={KITCO_TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer">
                  {context.termsOfService}
                </a>
                {context.and}
                <a className="link" href={KITCO_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                  {context.privacyPolicy}
                </a>
                {context.updatedTOS2}
                {DOCUMENT_UPDATE_DATE}
                {context.updatedTOS3}
              </p>
              <div className="center-align-vertical-container">
                <button className="button-primary" onClick={accepted}>
                  {context.accept}
                </button>
              </div>
            </div>
          )}
          {/* not required to show anything if up-to-date {hasAcceptedPreviously === termsAgreementPossibleStates[1] && ()} */}
          {hasAcceptedPreviously === termsAgreementPossibleStates[2] && (
            <div className="terms-of-service-card">
              <h1 className="home-card-title">{context.termsOfService}</h1>
              <p>
                {context.initialTOS1}
                <a className="link" href={KITCO_TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer">
                  {context.termsOfService}
                </a>
                {context.and}
                <a className="link" href={KITCO_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                  {context.privacyPolicy}
                </a>
                {context.initialTOS2}
              </p>
              <div className="space-between-container">
                <button id="accept" className="button-primary" onClick={accepted}>
                  {context.accept}
                </button>

                <button id="decline" className="button-primary" onClick={declined}>
                  {context.decline}
                </button>
              </div>
            </div>
          )}
        </div>
      </TermsOfServiceWrapper>
    </React.Fragment>
  )
}

export default SignUpTerms
