import { merge } from '../../util'

export class UserAccount {
  constructor(init?: any) {
    if (init) merge(init, this)
  }
  public userGuid = ''
  public email = ''
  public accountEnabled = false
  public accountStatus = ''
  public wireReferenceAssigned = false
  public wireReference = ''
  public redemptionAddressAssigned = false
  public redemptionAddress = ''
}

export class UserAccountsList {
  constructor(init?: UserAccountsList) {
    if (init) merge(init, this)
  }

  public accounts: UserAccount[] = []
  public success? = false
  public error? = ''
}

export class UserAccountSet {
  constructor(init?: UserAccountSet) {
    if (init) merge(init, this)
  }

  public userGuid = ''
  public email = ''
  public enableAccount = false
  public rejectAccount? = false
}

export class UserAccountGet {
  constructor(init?: UserAccountGet) {
    if (init) merge(init, this)
  }

  public userGuid = ''
  public errors? = ''
}
export class UserAccountGetReply {
  constructor(init?: UserAccountGetReply) {
    if (init) merge(init, this)
  }

  public account? = {} as UserAccount
  public success? = false
  public error? = ''
}
