import { StringRules, validation } from '../validation'
import { merge } from '../../util'

export class AddRedemptionAddress {
  constructor(init?: AddRedemptionAddress) {
    if (init) merge(init, this)
  }
  @validation(new StringRules({ required: true, ethAddress: true }))
  public address? = ''
}

export class AddRedemptionAddressErrors {
  constructor(init?: AddRedemptionAddressErrors) {
    if (init) merge(init, this)
  }
  public address? = ''
  public Address? = ''
}

export class AddRedemptionAddresses {
  constructor(init?: AddRedemptionAddresses) {
    if (init) merge(init, this)
  }
  public addresses? = ['']
  @validation(new StringRules({ required: false }))
  public addressList = ''
  public success = false
  public count = 0
}

export class AddRedemptionAddressesErrors {
  constructor(init?: AddRedemptionAddressesErrors) {
    if (init) merge(init, this)
  }
  public addresses? = ['']
  public Addresses? = ['']
  public addressList = ''
  public AddressList = ''
}
