import styled from 'styled-components'
import is from 'styled-is'
import { colors } from '../util/styles'

const Navbar = styled.div`
  width: 100%;
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
  background: ${colors.backgroundCol};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  box-shadow: 0px 0px 5px 3px ${colors.navbarShadow} !important;

  .active {
    text-decoration: underline;
  }

  .invisible {
    display: none;
  }

  @media (max-width: 991px) {
    margin-bottom: 1rem;
    padding: 1rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 77.45px;
    overflow: hidden;
    ${is('isopen')`
      box-shadow: none;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        border-bottom: 1px solid rgb(239, 240, 244);
        top: 77.45px;
        left: 0;
      }
    `}
  }
`

const NavbarItem = styled.div`
  padding: 1rem 1.5rem;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  color: ${colors.black};

  &:hover {
    // transform: translateY(-5px);
    color: ${colors.black};
    a {
      color: ${colors.black};
    }
  }
  text-align: left;
  cursor: pointer;

  @media (max-width: 991px) {
    border-bottom: 1px solid rgb(239, 240, 244);
    width: 100%;
    justify-content: flex-start;
    transform: translate(0) !important;
  }

  @media screen and (min-width: 992px) {
    ${is('forMobile')`
      display: none;
		`}
    ${is('forDesktop')`
			padding-left: 1rem;
		`}
  }
`

const Logo = styled.div`
  position: relative;
  margin: 0;
  display: flex;
  flex: 1 1 25%;
  align-items: center;
  & > img {
    max-width: 155px;
  }
`

const Group = styled.div`
	a {
		text-decoration :none;
	}

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;

    ${is('withLogo')`
      flex: 1 1 60%;
      flex-direction: row;
      & > span { padding-left: 1rem; }
    `}

    ${is('isLangSelector')`
      margin-right: 1rem;
    `}

    ${is('hasNavItems')`
      display: none;
    `}
    ${is('hasNavItems', 'isOpen')`
      display: flex;
      height: calc(100vh - 60px);
      top: 77px;
      width: calc(100vw - 50px);
      left: 0;
      z-index: 99;
      position: fixed;
      background: white;
      box-shadow: rgba(14,30,37,0.03) -1px 2px 13px 15px;
      border: 1px solid rgb(239, 240, 244);
			max-width: 300px;
			a {
				color: ${colors.black}
			}
    `}

    ${is('forDesktop')`
      display: none;
    `}
  }

  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
		align-items: center;

		${is('isAuthed')`
			a {
				text-decoration: none;
			}
		`}
    ${is('forDesktop', 'isMenu')`
			justify-content: flex-start;
			height: 100vh !important;
      flex-direction: column;
      padding: 0;
      box-shadow: rgba(14,30,37,0.03) -1px 2px 13px 15px;
      width: 250px;
      height: 120px;
      position: absolute;
      left: -2rem;
      top: 55px;
      background-color: white;
      display: none;
			border: 1px solid rgb(239, 240, 244);
			text-align: left;
    `}

    ${is('forDesktop', 'isMenu', 'isOpen')`
      display: flex;
      background: white;
      z-index: 99;
      & > a {
        width: 100%;
        z-index: 99;
        border-bottom: 1px solid rgb(239, 240, 244);
      }
    `}

    ${is('forDesktop', 'isMenu', 'isOpen')`
      top: 44px;
    `}

    ${is('hasNavItems')`
      min-width: 507px;
      max-width: 90%;
      flex: 1 1 60%;
    `}

    ${is('withLogo')`
      flex: 1 1 20%;
      max-width: 20%;
      min-width: 210px;
    `}

    ${is('isLangSelector')`
      padding-left: 1.5rem;
    `}
  }

  ${is('withLogo')`
    justify-content: flex-start !important;
  `}

  ${is('isLangSelector')`
    justify-content: flex-end !important;
  `}
`

const MenuBg = styled.div`
  position: absolute;
  width: 100vw;
  left: 0;
  top: 100%;
  height: 100vh;
  background-color: #00000040;
  z-index: 99;
  cursor: pointer;
  display: none;

  @media (max-width: 991px) {
    top: 0;
    left: 100%;
    ${is('forMobile', 'isOpen')`
      display: block;
    `}

    ${is('forDesktop')`
      display: none;
      visibility: hidden;
    `}
  }

  @media screen and (min-width: 992px) {
    left: -2rem;
    top: 44px;
    z-index: 98;
    ${is('forDesktop', 'isOpen')`
      display: block;
    `}

    ${is('forMobile')`
      display: none;
      visibility: hidden;
    `}
  }
`

const Dropdown = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding-right: 2rem;
  i {
    transform: scale(1.5);
  }

  @media (min-width: 992px) {
    display: none;
  }
`

const ReplacementBlock = styled.div`
  width: 100%;
  padding-top: 92px;
`

Navbar.Item = NavbarItem
Navbar.Logo = Logo
Navbar.Group = Group
Navbar.Dropdown = Dropdown
Navbar.ReplacementBlock = ReplacementBlock
Navbar.MenuBg = MenuBg

export default Navbar
