import moment from 'moment-timezone'
import { VALID_ADMIN_EMAILS } from '../constants/config'

export const getEmailFromUrlParam = (): string => {
  const url = new URL(window.location.href)
  const urlParam = decodeURIComponent(url.searchParams.get('email') ?? '')
  const urlEmail = urlParam !== null && urlParam !== 'null' ? urlParam : ''
  return urlEmail
}

export const getParamFromUrlParam = (param: string): string => {
  const url = new URL(window.location.href)
  const urlParam = decodeURIComponent(url.searchParams.get(param) ?? '')
  const reqParam = urlParam !== null && urlParam !== 'null' ? urlParam : ''
  return reqParam
}

/**
 * returns the time after adding the time difference
 * @param timestamp the time that needs to be checked
 * @returns returns updated timestamp
 */
export const convertTimestampToLocalString = (timestamp: string): string => {
  const time = moment.tz(timestamp, 'America/New_York').add(4, 'hours')
  const localtz = moment.tz.guess()
  const date = time.clone().tz(localtz)
  const formatDate = moment(date).format('MMMM Do YYYY, h:mm:ss A z')
  return formatDate
}

/**
 * check if the email is an admin email or not for all stages
 * @param email the email that needs to be checked
 * @returns returns true if the email is admin email
 */
export const checkAdminEmail = (email: string | undefined): boolean => {
  if (email === undefined) return false
  if (process.env.REACT_APP_STAGE === 'prod') {
    const isValidEmail = VALID_ADMIN_EMAILS.indexOf(email) !== -1
    if (isValidEmail) return isValidEmail

    const validDomain = /(@1stdigital.com|@1stDigital.com)$/
    const isValidDomainAdmin = validDomain.test(email)

    return isValidDomainAdmin
  } else {
    const isValidEmail = VALID_ADMIN_EMAILS.indexOf(email) !== -1
    if (isValidEmail) return isValidEmail

    const isValidDomainAdmin = /(@1stdigital.com|@1stDigital.com|@stably.io)$/.test(email)
    return isValidDomainAdmin
  }
}
