import { ActionType } from '../action'
import { EthCryptoAccount, EthCryptoAccountErrors } from '../../messages/fiat'

export class ActionEthCryptoAccount implements ActionType<EthCryptoAccount, EthCryptoAccountErrors> {
  readonly authRequired = true
  readonly getUrl = '/crypto/get'
  readonly setUrl = '/crypto/set/eth'
  public message = new EthCryptoAccount()
  public errors = new EthCryptoAccountErrors()
  public messageType = EthCryptoAccount
  public errorsType = EthCryptoAccountErrors
}
