import { TimeStampRules, validation, StringRules } from '../validation'
import { merge } from '../../util'

export class EthCryptoAccount {
  constructor(init?: EthCryptoAccount) {
    if (init) merge(init, this)
  }
  @validation(new TimeStampRules({ required: true }))
  public createdAt? = Date.now()
  public nickName? = ''
  @validation(new StringRules({ required: true, ethAddress: true }))
  public address? = ''
  public type? = ''
}

export class EthCryptoAccountErrors {
  constructor(init?: EthCryptoAccountErrors) {
    if (init) merge(init, this)
  }
  public createdAt?: number
  public nickName? = ''
  public address? = ''
  public type? = ''
}
