import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './appl'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
import store from './redux/store'
import { Provider } from 'react-redux'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || '',
  environment: process.env.REACT_APP_STAGE,
  release: process.env.REACT_APP_PACKAGE_VERSION,
  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        lang: localStorage.getItem('locale') || 'en', //set language for sentry user feedback form
      })
    }
    return event
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
