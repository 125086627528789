import React from 'react'
import context from '../../util/localize'
import { KeyValues, TextInput } from '@/ApiReact'

export type ForgotPasswordProps = {
  values: KeyValues
  errors: KeyValues
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const UserForgot = ({ values, errors, handleChange, handleSubmit }: ForgotPasswordProps): JSX.Element => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="card-small">
          <p className="text-align-center uppercase text-bold">{context.resetPassword}</p>
          <p className="text-align-center bottom-margin-2">{context.forgotMessage}</p>

          <div className="sub-card">
            <form onSubmit={handleSubmit}>
              <TextInput
                name="email"
                onChange={handleChange}
                label={context.email}
                placeholder={context.email}
                type="email"
                required={true}
                value={values.email}
                error={errors.email}
              />
              <div className="center-align-container">
                <button className="button-primary" type="submit">
                  {context.submit}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserForgot
