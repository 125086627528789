import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Api, AuthState } from '@/Api'
import { api } from './api'
import { Result } from 'neverthrow'

type AuthContextProviderProps = {
  children: React.ReactNode
  myprop?: string
  API_ENDPOINT?: string
  AWS_REGION?: string
  AWS_USER_POOL_ID?: string
  AWS_USER_POOLS_WEB_CLIENT_ID?: string
  STAGE?: string
}

interface AuthContextState {
  loggedIn: boolean
  login: (username: string, password: string) => void
  login2FA: (username: string, password: string) => Promise<any>
  login2FAConfirm: (loguser: any, code: string, mfaType: string) => void
  signup: (email: string, password: string, confirmPassword: string) => any
  logout: () => void
  confirmSignup: (userEmail: string, code: string) => Promise<Result<any, Error>>
  getCurrentAuthenticatedUser: () => Promise<any>
  getPreferredMFAType: (user: CognitoUser) => Promise<Result<any, Error>>
  getTOTPCode: (user: CognitoUser) => Promise<Result<any, Error>>
  forgotPassword: (userEmail: string) => Promise<Result<any, Error>>
  forgotPasswordSubmit: (
    userEmail: string,
    code: string,
    newPassword: string,
    confirmNewPassword
  ) => Promise<Result<any, Error>>
  changePassword: (oldPassword: string, newPassword: string, confirmNewPassword: string) => Promise<Result<any, Error>>
  resendSignUp: (username: string) => Promise<Result<any, Error>>
  setPreferredMFAType: (user: CognitoUser, mfaType: string) => Promise<Result<any, Error>>
  verifyTotpToken: (user: CognitoUser, challenge: string) => Promise<Result<any, Error>>
  api: () => Api
}

const uninitialized = (): void => console.log('use AuthContextProvider hoc instead of AuthContext.Provider')
const uninitializedPromise = (): Promise<Result<any, Error>> =>
  new Promise((resolve, _) => {
    console.log('use AuthContextProvider hoc instead of AuthContext.Provider')
    resolve()
  })

const userinitializedPromise = (): Promise<any> =>
  new Promise((resolve, _) => {
    console.log('use AuthContextProvider hoc instead of AuthContext.Provider')
    resolve()
  })

const initialState: AuthContextState = {
  loggedIn: api.auth.state.loggedIn,
  login: uninitialized,
  login2FA: userinitializedPromise,
  login2FAConfirm: uninitialized,
  signup: uninitialized,
  logout: uninitialized,
  confirmSignup: uninitializedPromise,
  getCurrentAuthenticatedUser: uninitializedPromise,
  getPreferredMFAType: uninitializedPromise,
  getTOTPCode: uninitializedPromise,
  forgotPassword: uninitializedPromise,
  forgotPasswordSubmit: uninitializedPromise,
  changePassword: uninitializedPromise,
  resendSignUp: uninitializedPromise,
  setPreferredMFAType: uninitializedPromise,
  verifyTotpToken: uninitializedPromise,
  api: (): Api => api,
}

export const AuthContext = React.createContext<AuthContextState>(initialState)

export const useAuthContext = (): AuthContextState => useContext(AuthContext)

export const AuthContextProvider: React.FC<AuthContextProviderProps> = (props) => {
  // console.log(`API_ENDPOINT?: ${props.API_ENDPOINT}`)
  // console.log(`AWS_REGION?: ${props.AWS_REGION}`)
  // console.log(`AWS_USER_POOL_ID?: ${props.AWS_USER_POOL_ID}`)
  // console.log(`AWS_USER_POOLS_WEB_CLIENT_ID?: ${props.AWS_USER_POOLS_WEB_CLIENT_ID}`)
  // console.log(`STAGE?: ${props.STAGE}`)
  const [state, setState] = useState({
    ...initialState,
    loggedIn: api.auth.state.loggedIn,
    login: async (username: string, password: string): Promise<void> => {
      try {
        const success = await api.auth.login(username, password)
        if (success) {
          setState({ ...state, loggedIn: true })
        }
      } catch (e) {
        throw e
      }
    },
    login2FA: async (username: string, password: string): Promise<any> => {
      try {
        const user = await api.auth.login2FA(username, password)
        if (user && user.challengeName !== undefined) {
          setState({ ...state, loggedIn: false })
        } else {
          setState({ ...state, loggedIn: true })
        }
        return user
      } catch (e) {
        throw e
      }
    },
    login2FAConfirm: async (loguser: any, code: string, mfaType: string): Promise<void> => {
      try {
        const success = await api.auth.login2FAConfirm(loguser, code, mfaType)
        if (success) {
          setState({ ...state, loggedIn: true })
        }
      } catch (e) {
        throw e
      }
    },
    logout: (): void => {
      setState({ ...state, loggedIn: false })
      api.auth.logout()
    },
    signup: async (email: string, password: string, confirmPassword: string): Promise<any> => {
      try {
        const passwordErrors: string[] = []
        if (password.length < 8) {
          passwordErrors.push('must be at least 8 characters')
        }
        if (password.length > 90) {
          passwordErrors.push('must be less than 90 characters')
        }
        // if (password.search(/[a-z]/i) < 0) {
        //   passwordErrors.push('must contain at least one letter')
        // }
        // if (password.search(/[0-9]/) < 0) {
        //   passwordErrors.push('must contain at least one digit')
        // }
        // if (password.search(/[=+^$*.[\]{}()?"!@#%&/\\,><':;|_~` -]/) < 0) {
        //   passwordErrors.push('must contain at least one of =+^$*.[]{}()?"!@#%&/\\,><\':;|_~` -')
        // }

        if (passwordErrors.length > 0) {
          // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html

          const err = {
            name: 'InvalidPasswordException',
            message: 'Your password ' + (passwordErrors.length > 1 ? passwordErrors.join(', ') : passwordErrors[0]),
          }
          throw err
        }

        if (password !== confirmPassword) {
          const err = {
            name: 'InvalidConfirmPasswordException',
            message: 'Confirm password does not match to your password',
          }
          throw err
        }
        // tdb: support signup unconfirmed state
        const success = await api.auth.signup(email, password)

        return success
      } catch (e) {
        throw e
      }
    },
    confirmSignup: async (userEmail: string, code: string): Promise<Result<any, Error>> => {
      return await api.auth.confirmSignup(userEmail, code)
    },
    getCurrentAuthenticatedUser: async (): Promise<any> => {
      return await api.auth.getCurrentAuthenticatedUser()
    },
    getPreferredMFAType: async (user: CognitoUser): Promise<Result<any, Error>> => {
      return await api.auth.getPreferredMFAType(user)
    },
    getTOTPCode: async (user: CognitoUser): Promise<Result<any, Error>> => {
      return await api.auth.getTOTPCode(user)
    },
    forgotPassword: async (userEmail: string): Promise<Result<any, Error>> => {
      return await api.auth.forgotPassword(userEmail)
    },
    forgotPasswordSubmit: async (
      userEmail: string,
      code: string,
      newPassword: string,
      confirmNewPassword: string
    ): Promise<Result<any, Error>> => {
      try {
        if (newPassword !== confirmNewPassword) {
          const err = {
            name: 'InvalidConfirmPasswordException',
            message: 'Confirm password does not match to your password',
          }
          throw err
        }
        return await api.auth.forgotPasswordSubmit(userEmail, code, newPassword)
      } catch (e) {
        throw e
      }
    },
    changePassword: async (
      oldPassword: string,
      newPassword: string,
      confirmNewPassword: string
    ): Promise<Result<any, Error>> => {
      try {
        const passwordErrors: string[] = []
        if (newPassword.length < 8) {
          passwordErrors.push('must be at least 8 characters')
        }
        if (newPassword.length > 90) {
          passwordErrors.push('must be less than 90 characters')
        }
        // if (newPassword.search(/[a-z]/i) < 0) {
        //   passwordErrors.push('must contain at least one letter')
        // }
        // if (newPassword.search(/[0-9]/) < 0) {
        //   passwordErrors.push('must contain at least one digit')
        // }
        // if (newPassword.search(/[=+^$*.[\]{}()?"!@#%&/\\,><':;|_~` -]/) < 0) {
        //   passwordErrors.push('must contain at least one of =+^$*.[]{}()?"!@#%&/\\,><\':;|_~` -')
        // }

        if (passwordErrors.length > 0) {
          // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html

          const err = {
            name: 'InvalidPasswordException',
            message: 'Your password ' + (passwordErrors.length > 1 ? passwordErrors.join(', ') : passwordErrors[0]),
          }
          throw err
        }

        if (newPassword !== confirmNewPassword) {
          const err = {
            name: 'InvalidConfirmPasswordException',
            message: 'Confirm password does not match to your password',
          }
          throw err
        }
        return await api.auth.changePassword(oldPassword, newPassword)
      } catch (e) {
        throw e
      }
    },
    resendSignUp: async (username: string): Promise<Result<any, Error>> => {
      return await api.auth.resendSignUp(username)
    },
    setPreferredMFAType: async (user: CognitoUser, mfaType: string): Promise<Result<any, Error>> => {
      return await api.auth.setPreferredMFAType(user, mfaType)
    },
    verifyTotpToken: async (user: CognitoUser, challenge: string): Promise<Result<any, Error>> => {
      return await api.auth.verifyTotpToken(user, challenge)
    },
  })

  /**
   * Handle asyncronous authentication state changes
   */
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const handler = (authState: AuthState): void => {
      if (state.loggedIn !== authState.loggedIn) {
        setState({ ...state, loggedIn: authState.loggedIn })
      }
    }

    api.auth.subscribe(handler)
    return (): void => api.auth.unsubscribe(handler)
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */
  return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>
}

AuthContextProvider.propTypes = { children: PropTypes.node.isRequired }

export default AuthContextProvider
