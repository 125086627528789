import React, { FormEvent } from 'react'
import { TextInput, FileInput } from '@/ApiReact'
import context from '../../util/localize'
import { IndividualAccount, IndividualAccountErrors } from '@/Api'
import IndividualAccountComponent from '../../blocks/individualaccount'
import { SelectInput } from '@/ApiReact'
import { SelectCountries, SelectState } from '../../components/locale/countries'
import SuccessBlock from '../../container/usernotifications/success'

type IndividualAccountProps = {
  enabled: boolean
  handleChange: (e: FormEvent<HTMLInputElement>) => void
  handleSelectChange: (e: FormEvent<HTMLSelectElement>) => void
  handleCustomSelectChange: (field: string, val: any) => void
  handleNumberInputChange?: (e: React.FormEvent<HTMLInputElement>) => void
  handleCheckboxChange?: (e: React.FormEvent<HTMLInputElement>) => void
  handleSubmit: ((event: FormEvent<HTMLFormElement>) => void) | undefined
  handleFileChange: (event: FormEvent<HTMLInputElement>, label: string) => void | undefined
  values: IndividualAccount
  errors: IndividualAccountErrors
  success: boolean
  setSuccess: (boolean) => void
  noLitigations: boolean
  noRegulatoryActions: boolean
  // getRestrictedCountries: () => string
  state: any
  setState: (state: any) => void
}

const SubmitIndividualAccount = ({
  // getRestrictedCountries,
  handleSubmit,
  handleSelectChange,
  handleCustomSelectChange,
  handleChange,
  handleFileChange,
  handleNumberInputChange,
  handleCheckboxChange,
  enabled,
  values,
  errors,
  success,
  setSuccess,
  noRegulatoryActions,
  state,
  setState,
}: IndividualAccountProps): JSX.Element => {
  return (
    <IndividualAccountComponent>
      <React.Fragment>
        <div className="container">
          <div className="card-small">
            {success ? (
              <SuccessBlock
                clearSuccessMethod={setSuccess}
                successMsg={context.successKYCSubmitted}
                showRedirectToHome={true}
              />
            ) : (
              <form onSubmit={handleSubmit}>
                <p className="text-align-center uppercase text-bold">{context.openPersonalAccount}</p>
                <p className="text-align-center">{context.openAccInstruction}</p>
                <div className="flex-row">
                  <div className="col-3">
                    <SelectInput
                      className="input-group"
                      name="nameTitle"
                      label={context.nameTitle}
                      onChange={handleSelectChange}
                      value={values.nameTitle}
                      error={errors.nameTitle}
                    >
                      <option value="">Choose here</option>
                      <option>Mr</option>
                      <option>Ms</option>
                      <option>Mrs</option>
                    </SelectInput>
                  </div>
                  <div className="col-3">
                    <TextInput
                      type="text"
                      label={context.firstName}
                      name="name.first"
                      value={values.name.first}
                      error={errors.name.first || errors['name.first']}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                  <div className="col-3">
                    <TextInput
                      type="text"
                      label={context.lastName}
                      name="name.last"
                      value={values.name.last}
                      error={errors.name.last || errors['name.last']}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <label className="label top-margin-label">{context.primaryAddress}</label>
                </div>
                {/* <div className="text-bold-small">
                  {context.formatString(context.restrictedCountriesNotice, getRestrictedCountries())}
                </div> */}
                <div className="flex-row">
                  <div className="col-5">
                    <SelectCountries
                      name="address.country"
                      label={context.country}
                      handleCountryChange={(val): any => handleCustomSelectChange('address.country', val)}
                      value={values.address.country}
                      error={errors.address.country || errors['address.country']}
                      showAsterisk={true}
                    />
                  </div>
                  <div className="col-5">
                    <SelectState
                      name="address.region"
                      country={values.address.country}
                      label={context.region}
                      handleStateChange={(val): any => handleCustomSelectChange('address.region', val)}
                      handleChange={handleChange}
                      value={values.address.region}
                      error={errors.address.region || errors['address.region']}
                      showAsterisk={true}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-10">
                    <TextInput
                      type="text"
                      label={context.address}
                      name="address.street_1"
                      value={values.address.street_1}
                      error={errors.address.street_1 || errors['address.street_1']}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-5">
                    <TextInput
                      type="text"
                      label={context.city}
                      name="address.city"
                      value={values.address.city}
                      error={errors.address.city || errors['address.city']}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                  <div className="col-5">
                    <TextInput
                      type="text"
                      label={context.postal}
                      name="address.postal"
                      value={values.address.postal}
                      error={errors.address.postal || errors['address.postal']}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <label className="label top-margin-label">{context.correspondenceAddress}</label>
                </div>
                <div className="flex-row">
                  <div className="col-5">
                    <SelectCountries
                      name="correspondenceAddress.country"
                      label={context.country}
                      handleCountryChange={(val): any => handleCustomSelectChange('correspondenceAddress.country', val)}
                      value={values.correspondenceAddress.country}
                      error={errors.correspondenceAddress.country || errors['correspondenceAddress.country']}
                    />
                  </div>
                  <div className="col-5">
                    <SelectState
                      name="correspondenceAddress.region"
                      country={values.correspondenceAddress.country}
                      label={context.region}
                      handleStateChange={(val): any => handleCustomSelectChange('correspondenceAddress.region', val)}
                      handleChange={handleChange}
                      value={values.correspondenceAddress.region}
                      error={errors.correspondenceAddress.region || errors['correspondenceAddress.region']}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-10">
                    <TextInput
                      type="text"
                      label={context.address}
                      name="correspondenceAddress.street_1"
                      value={values.correspondenceAddress.street_1}
                      error={errors.correspondenceAddress.street_1 || errors['correspondenceAddress.street_1']}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-5">
                    <TextInput
                      type="text"
                      label={context.city}
                      name="correspondenceAddress.city"
                      value={values.correspondenceAddress.city}
                      error={errors.correspondenceAddress.city || errors['correspondenceAddress.city']}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-5">
                    <TextInput
                      type="text"
                      label={context.postal}
                      name="correspondenceAddress.postal"
                      value={values.correspondenceAddress.postal}
                      error={errors.correspondenceAddress.postal || errors['correspondenceAddress.postal']}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <hr />
                </div>
                <div className="flex-row">
                  <div className="col-5">
                    <TextInput
                      type="text"
                      label={context.emailAddress}
                      name="email"
                      value={values.email}
                      error={errors.email}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                  <div className="col-5">
                    <TextInput
                      type="text"
                      label={context.mobilePhone}
                      name="phone"
                      value={values.phone}
                      error={errors.phone}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-5">
                    <div className="form-item">
                      <SelectInput
                        className="input-group"
                        name="gender"
                        label={context.gender}
                        onChange={handleSelectChange}
                        value={values.gender}
                        error={errors.gender}
                        showAsterisk={true}
                      >
                        <option value="">Choose here</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </SelectInput>
                    </div>
                  </div>
                  <div className="col-5">
                    <TextInput
                      type="date"
                      label={context.dateofBirth}
                      name="birth"
                      value={values.birth}
                      error={errors.birth}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-5">
                    <SelectCountries
                      name="placeOfBirth"
                      label={context.placeofBirth}
                      handleCountryChange={(val): any => handleCustomSelectChange('placeOfBirth', val)}
                      value={values.placeOfBirth}
                      error={errors.placeOfBirth}
                      showAsterisk={true}
                    />
                  </div>
                  <div className="col-5">
                    <SelectCountries
                      name="nationality"
                      label={context.nationality}
                      handleCountryChange={(val): any => handleCustomSelectChange('nationality', val)}
                      value={values.nationality}
                      error={errors.nationality}
                      showAsterisk={true}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-5">
                    <TextInput
                      type="text"
                      label={context.governmentIdNumber}
                      name="governmentIdNumber"
                      value={values.governmentIdNumber}
                      error={errors.governmentIdNumber}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                  <div className="col-5">
                    <SelectCountries
                      name="taxCountry"
                      label={context.taxCountry}
                      handleCountryChange={(val): any => handleCustomSelectChange('taxCountry', val)}
                      value={values.taxCountry}
                      error={errors.taxCountry}
                      showAsterisk={true}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-5">
                    <label className="label">
                      <input
                        type="checkbox"
                        name="dualNationality"
                        checked={values.dualNationality}
                        onChange={handleCheckboxChange}
                      />
                      {context.dualNationality}
                    </label>
                  </div>
                  <div className="col-5">
                    <TextInput
                      type="text"
                      label={context.taxId}
                      name="taxId"
                      value={values.taxId}
                      error={errors.taxId}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-3">
                    <SelectInput
                      className="input-group"
                      name="employmentStatus"
                      label={context.employmentStatus}
                      onChange={handleSelectChange}
                      value={values.employmentStatus}
                      error={errors.employmentStatus}
                      showAsterisk={true}
                    >
                      <option value="">Choose here</option>
                      <option>Employed</option>
                      <option>Self Employed</option>
                      <option>Retired</option>
                      <option>Housewife</option>
                      <option>Other</option>
                    </SelectInput>
                  </div>
                  <div className="col-3">
                    <TextInput
                      type="text"
                      label={context.occupation}
                      name="occupation"
                      value={values.occupation}
                      error={errors.occupation}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                  <div className="col-3">
                    <TextInput
                      type="text"
                      label={context.jobTitle}
                      name="jobTitle"
                      value={values.jobTitle}
                      error={errors.jobTitle}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-6">
                    <TextInput
                      type="text"
                      label={context.nameofEmployer}
                      name="nameOfEmployer"
                      value={values.nameOfEmployer}
                      error={errors.nameOfEmployer}
                      onChange={handleChange}
                      showAsterisk={true}
                    />
                  </div>
                  <div className="col-3">
                    <TextInput
                      type="number"
                      label={context.yearsofService}
                      name="yearsOfService"
                      value={values.yearsOfService || 0}
                      error={errors.yearsOfService}
                      onChange={handleNumberInputChange}
                      showAsterisk={true}
                    />
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-5">
                    <SelectInput
                      className="input-group"
                      name="sourceOfWealth"
                      label={context.sourceofWealth}
                      onChange={handleSelectChange}
                      value={values.sourceOfWealth}
                      error={errors.sourceOfWealth}
                      showAsterisk={true}
                    >
                      <option value="">Choose here</option>
                      <option>Salary</option>
                      <option>Income Accumulation</option>
                      <option>Commission</option>
                      <option>Business Income</option>
                      <option>Pension</option>
                      <option>Reserve</option>
                      <option>Return on investment</option>
                      <option>Other</option>
                    </SelectInput>
                  </div>
                  <div className="col-5">
                    <SelectInput
                      className="input-group"
                      name="sourceOfFunds"
                      label={context.sourceofFunds}
                      onChange={handleSelectChange}
                      value={values.sourceOfFunds}
                      error={errors.sourceOfFunds}
                      showAsterisk={true}
                    >
                      <option value="">Choose here</option>
                      <option>Salary</option>
                      <option>Income Accumulation</option>
                      <option>Commission</option>
                      <option>Business Income</option>
                      <option>Pension</option>
                      <option>Reserve</option>
                      <option>Return on investment</option>
                      <option>Other</option>
                    </SelectInput>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-10">
                    <div className="ckb-lbl">
                      <label className="label">
                        <input
                          type="checkbox"
                          name="noLitigations"
                          onChange={(e): void => {
                            setState({
                              ...state,
                              noLitigations: e.currentTarget.checked,
                            })
                          }}
                        />
                        {context.anyLitigationsIndividual}
                      </label>
                    </div>
                  </div>
                </div>
                {!state.noLitigations && (
                  <div className="flex-row">
                    <div className="col-10">
                      <TextInput
                        type="text"
                        label={context.anyLitigationsDetail}
                        name="noLitigations"
                        value={values.anyLitigations}
                        error={errors.anyLitigations}
                        onChange={handleChange}
                        required={true}
                        showAsterisk={true}
                      />
                    </div>
                  </div>
                )}
                <div className="flex-row">
                  <div className="col-10">
                    <div className="ckb-lbl">
                      <label className="label">
                        <input
                          type="checkbox"
                          name="noRegulatoryActions"
                          onChange={(e): void => {
                            setState({
                              ...state,
                              noRegulatoryActions: e.currentTarget.checked,
                            })
                          }}
                        />
                        {context.anyRegulatoryActionsIndividual}
                      </label>
                    </div>
                  </div>
                </div>
                {!noRegulatoryActions && (
                  <div className="col-10">
                    <TextInput
                      type="text"
                      label={context.anyRegulatoryActionsDetail}
                      name="noRegulatoryActions"
                      value={values.anyRegulatoryActions}
                      error={errors.anyRegulatoryActions}
                      onChange={handleChange}
                      required={true}
                      showAsterisk={true}
                    />
                  </div>
                )}
                <div className="flex-row">
                  <div className="col-5">
                    <div className="input-file">
                      <FileInput
                        name="idDocument"
                        label={context.idDocument}
                        onChange={(e): void => handleFileChange(e, 'id-document')}
                        error={errors['idDocument'] || errors['id-document']}
                        disabled={!enabled}
                        required={true}
                        showAsterisk={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-5">
                    <div className="input-file">
                      <FileInput
                        name="proof-of-address"
                        label={context.proofofAddress}
                        onChange={(e): void => handleFileChange(e, 'proof-of-address')}
                        error={errors['proofofAddress'] || errors['proof-of-address']}
                        showAsterisk={true}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-8">
                    <div className="ckb-lbl">
                      <label className="label">
                        <input
                          type="checkbox"
                          name="haveNeverBeenUsCitizen"
                          checked={values.haveNeverBeenUsCitizen}
                          onChange={handleCheckboxChange}
                        />
                        {context.haveNeverBeenUsCitizen}
                      </label>
                      {/* <span className="required-asterisk"> *</span> */}
                    </div>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="col-6">
                    <div className="ckb-lbl">
                      <label className="label">
                        <input
                          type="checkbox"
                          name="isUltimateBeneficialOwner"
                          checked={values.isUltimateBeneficialOwner}
                          onChange={handleCheckboxChange}
                        />
                        {context.iAmUltimateBeneficialOwner}
                      </label>
                    </div>
                  </div>
                </div>
                {!values.isUltimateBeneficialOwner && (
                  <div>
                    <div className="flex-row">
                      <div className="col-10">
                        <TextInput
                          type="text"
                          label={context.nameofUltimateBeneficialOwner}
                          name="ultimateBeneficialOwner.ownerName"
                          value={values.ultimateBeneficialOwner.ownerName}
                          error={
                            errors.ultimateBeneficialOwner.ownerName || errors['ultimateBeneficialOwner.ownerName']
                          }
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="flex-row">
                      <div className="col-10">
                        <TextInput
                          type="text"
                          label={context.governmentIdNumberofUltimateBeneficialOwner}
                          name="ultimateBeneficialOwner.ownerGovernmentIdNumber"
                          value={values.ultimateBeneficialOwner.ownerGovernmentIdNumber}
                          error={
                            errors.ultimateBeneficialOwner.ownerGovernmentIdNumber ||
                            errors['ultimateBeneficialOwner.ownerGovernmentIdNumber']
                          }
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <label className="label">{context.addressofUltimateBeneficialOwner}</label> <br />
                    <div className="flex-row">
                      <div className="col-5">
                        <SelectCountries
                          name="ultimateBeneficialOwner.ownerAddress.country"
                          label={context.country}
                          handleCountryChange={(val): any =>
                            handleCustomSelectChange('ultimateBeneficialOwner.ownerAddress.country', val)
                          }
                          value={values.ultimateBeneficialOwner.ownerAddress.country}
                          error={
                            errors.ultimateBeneficialOwner.ownerAddress.country ||
                            errors['ultimateBeneficialOwner.ownerAddress.country']
                          }
                        />
                      </div>
                      <div className="col-5">
                        <SelectState
                          name="ultimateBeneficialOwner.ownerAddress.region"
                          country={values.ultimateBeneficialOwner.ownerAddress.country}
                          label={context.region}
                          handleStateChange={(val): any =>
                            handleCustomSelectChange('ultimateBeneficialOwner.ownerAddress.region', val)
                          }
                          handleChange={handleChange}
                          value={values.ultimateBeneficialOwner.ownerAddress.region}
                          error={
                            errors.ultimateBeneficialOwner.ownerAddress.region ||
                            errors['ultimateBeneficialOwner.ownerAddress.region']
                          }
                        />
                      </div>
                    </div>
                    <div className="flex-row">
                      <div className="col-10">
                        <TextInput
                          type="text"
                          label={context.address}
                          name="ultimateBeneficialOwner.ownerAddress.street_1"
                          value={values.ultimateBeneficialOwner.ownerAddress.street_1}
                          error={
                            errors.ultimateBeneficialOwner.ownerAddress.street_1 ||
                            errors['ultimateBeneficialOwner.ownerAddress.street_1']
                          }
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="flex-row">
                      <div className="col-5">
                        <TextInput
                          type="text"
                          label={context.city}
                          name="ultimateBeneficialOwner.ownerAddress.city"
                          value={values.ultimateBeneficialOwner.ownerAddress.city}
                          error={
                            errors.ultimateBeneficialOwner.ownerAddress.city ||
                            errors['ultimateBeneficialOwner.ownerAddress.city']
                          }
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-5">
                        <TextInput
                          type="text"
                          label={context.postal}
                          name="ultimateBeneficialOwner.ownerAddress.postal"
                          value={values.ultimateBeneficialOwner.ownerAddress.postal}
                          error={
                            errors.ultimateBeneficialOwner.ownerAddress.postal ||
                            errors['ultimateBeneficialOwner.ownerAddress.postal']
                          }
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="flex-row">
                      <div className="col-10">
                        <TextInput
                          type="text"
                          label={context.mobilePhoneofUltimateBeneficialOwner}
                          name="ultimateBeneficialOwner.ownerPhone"
                          value={values.ultimateBeneficialOwner.ownerPhone}
                          error={
                            errors.ultimateBeneficialOwner.ownerPhone || errors['ultimateBeneficialOwner.ownerPhone']
                          }
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="flex-row">
                      <div className="col-5">
                        <div className="input-file">
                          <FileInput
                            name="ultimate-beneficial-owner-id-document"
                            label={context.ultimateBeneficialOwnerIdDocument}
                            onChange={(e): void => handleFileChange(e, 'ultimate-beneficial-owner-id-document')}
                            error={
                              errors['ultimateBeneficialOwnerIdDocument'] ||
                              errors['ultimate-beneficial-owner-id-document']
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex-row">
                      <div className="col-5">
                        <div className="input-file">
                          <FileInput
                            name="ultimate-beneficial-owner-proof-of-address"
                            label={context.ultimateBeneficialOwnerProofofAddress}
                            onChange={(e): void => handleFileChange(e, 'ultimate-beneficial-owner-proof-of-address')}
                            error={
                              errors['ultimateBeneficialOwnerProofofAddress'] ||
                              errors['ultimate-beneficial-owner-proof-of-address']
                            }
                            disabled={!enabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="center-align-container">
                  <button type="submit" className="button-primary" disabled={!enabled}>
                    {context.submit}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </React.Fragment>
    </IndividualAccountComponent>
  )
}

export default SubmitIndividualAccount
