import { merge } from '../../util'
import moment from 'moment'

export class DateRules {
  constructor(init: DateRules) {
    if (init) merge(init, this)
  }
  public required = false

  public past?: boolean
  public future?: boolean

  public format? = 'MM-DD-YYYY'
  public minYear?: number
  public maxYear?: number
  public minAge?: number
  public maxAge?: number

  public birth?: true
  public issuance?: true
  public expiration?: true
}

export function validateDateInput(input: string, rules?: DateRules): boolean {
  if (input === '') return true
  if (!rules) return true
  return true
}

const minBirthYear = 1900

export function validateDateValue(value: string, rules?: DateRules): string {
  if (!rules) return ''
  if ((!rules || !rules?.required) && !value) return ''
  if (rules.required && !value) return 'A value is required'

  const thatDate = moment(value, rules.format, true)
  if (!thatDate.isValid()) {
    return `Value is an invalid date with format (${rules.format})`
  }

  const today = moment()
  const thatDateStr = thatDate.format(rules.format)

  if (rules.future && thatDate < today) return 'Value must be a day in the future, got ' + thatDateStr
  if (rules.past && thatDate > today) return 'Value must be a day in the past, got ' + thatDateStr

  if (rules.minYear && thatDate.year() < rules.minYear)
    return 'Value must be a day after ' + rules.minYear + ', got ' + thatDateStr
  if (rules.maxYear && thatDate.year() > rules.maxYear)
    return 'Value must be a day before ' + rules.maxYear + ', got ' + thatDateStr

  const age = moment().diff(thatDate, 'years', false)
  if (rules.minAge && (age < 0 || age < rules.minAge)) {
    return 'The age must be greater than min age, got ' + age
  }
  if (rules.maxAge && (age < 0 || age > rules.maxAge)) {
    return 'The age must be less than max age, got ' + age
  }

  if (rules.birth && (thatDate > today || thatDate.year() < minBirthYear)) {
    return 'A date of birth must be in the past and after ' + minBirthYear
  }
  if (rules.issuance && thatDate > today) {
    return 'A date of issuance must be a day in the past, got ' + thatDateStr
  }
  if (rules.expiration && thatDate < today) {
    return 'A date of expiration must be a day in the future, got ' + thatDateStr
  }

  return ''
}
