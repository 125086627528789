import React from 'react'
import { useLoginFormState } from '../hooks/forms/login'
import { TextInput } from '../elements'

export const LoginForm: React.FC = () => {
  const { values, errors, handleChange, handleSubmit } = useLoginFormState()
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextInput
          name="username"
          type="email"
          label="Email"
          required={true}
          onChange={handleChange}
          value={values.username}
          error={errors.username}
        />
        <TextInput
          name="password"
          type="password"
          label="Password"
          required={true}
          onChange={handleChange}
          value={values.password}
          error={errors.password}
        />
        <button type="submit">Login</button>
      </form>
    </div>
  )
}
