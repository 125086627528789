import React from 'react'
import context from '../../util/localize'
import { TextInput } from '@/ApiReact'
import RouteConstants from '../../routes/routeConstants'

const UserLogin = ({ has2FAEnabled, LoginFormState, TwoFAFormState }: any): JSX.Element => {
  return (
    <React.Fragment>
      <p className="text-align-center text-bold">{context.loginMessage}</p>
      {/* flag is opposite because it will show the code after checking*/}
      {!has2FAEnabled ? (
        <form onSubmit={LoginFormState.handleSubmit}>
          <TextInput
            name="username"
            onChange={LoginFormState.handleChange}
            label={context.email}
            placeholder={context.email}
            type="email"
            required={true}
            value={LoginFormState.values.username}
            error={LoginFormState.errors.username}
          />
          <TextInput
            name="password"
            type="password"
            label={context.password}
            placeholder={context.password}
            value={LoginFormState.values.password}
            onChange={LoginFormState.handleChange}
            required={true}
            error={LoginFormState.errors.password}
          />
          <div className="space-between-container">
            <a className="link" href={RouteConstants.Signup}>
              {context.createAnAccount}
            </a>

            <a className="link" href={RouteConstants.ForgotPassword}>
              {context.resetPassword}
            </a>
          </div>
          <div className="center-align-container">
            <button className="button-primary" type="submit">
              {context.login}
            </button>
          </div>
        </form>
      ) : (
        <form onSubmit={TwoFAFormState.handleSubmit}>
          <TextInput
            name="code"
            onChange={TwoFAFormState.handleChange}
            label={context.code}
            placeholder={context.code}
            type="text"
            required={true}
            value={TwoFAFormState.values.code}
            error={TwoFAFormState.errors.code}
          />
          <div className="center-align-container">
            <button className="button-primary" type="submit">
              {context.submit}
            </button>
          </div>
        </form>
      )}
    </React.Fragment>
  )
}

export default UserLogin
