import styled from 'styled-components'
// import is from 'styled-is'
import { colors } from '../util/styles'

const Footer = styled.div`
  width: 100%;
  z-index: 10;
`

const FooterBottom = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.backgroundCol};
`

const FooterContent = styled.div`
  width: 100%;
  padding: 0.5rem 2rem;
  color: ${colors.white};
  background: ${colors.black};
  a {
    color: ${colors.white};
    text-decoration: none !important;
  }

  @media (max-width: 991px) {
    padding: 0.5rem;
  }
`

const FooterItem = styled.div`
  padding: 1rem 1rem;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  text-transform: uppercase;

  &:hover {
    transform: translateY(-5px);
    color: ${colors.white};
  }
  @media (max-width: 991px) {
  }

  @media screen and (min-width: 992px) {
  }
`

const Group = styled.div`
  @media screen and (max-width: 991px) {
    display: flex;
  }

  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

const Text = styled.p`
  font-size: 10px;
  color: ${colors.black};
  margin: 0.5rem 2rem;
  text-align: center;
  max-width: 40rem;
`

Footer.FooterBottom = FooterBottom
Footer.FooterContent = FooterContent
Footer.FooterItem = FooterItem
Footer.Group = Group
Footer.Text = Text

export default Footer
