import React, { useEffect, useState, FormEvent } from 'react'
import { useDispatch } from 'react-redux'
import { ContainerRouterProps } from '../../interface'
import { useFormStateAction, useAuthContext, api, ACCEPTED_FILE_FORMATS } from '@/ApiReact'
import { ActionInstitutionalAccount, validateInput, InstitutionalAccount, deepCopyAndSet, validateMessage } from '@/Api'
import SubmitInstitutionalAccount from '../../components/kyc/InstitutionalAccount'
import LoadingBlock from '../usernotifications/loading'
import ErrorAlert from '../usernotifications/erroralert'
import account from '../../redux/actions/accountActions'
// import { BLOCKED_COUNTRIES } from '../../constants/config'
import RouteConstants from '../../routes/routeConstants'
import * as countries from '../../constants/countries.json'

type AccountFile = {
  [label: string]: File
}

const InstitutionalAccountContainer: React.FC<ContainerRouterProps> = (props: any) => {
  const auth = useAuthContext()
  const dispatch = useDispatch()
  const institutionalAccountAction = new ActionInstitutionalAccount()
  const { values, errors, enabled, setEnabled, handleChange, setValues, setErrors, handleSubmit } = useFormStateAction(
    institutionalAccountAction
  )
  const initialFiles: AccountFile = {}
  const [files, setFiles] = useState(initialFiles)
  const [isLoading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [generalError, setGeneralError] = useState('')
  const [state, setState] = useState({ noLitigations: false, noRegulatoryActions: false })

  // const getRestrictedCountries = (): string => {
  //   const len = BLOCKED_COUNTRIES.length
  //   const restrictedCountries: string[] = []
  //   for (let i = 0; i < len; i++) {
  //     const found = countries.data.find((country) => country.iso2 === BLOCKED_COUNTRIES[i])
  //     restrictedCountries.push(found?.name || '')
  //   }
  //   return restrictedCountries.join(', ')
  // }

  const handleCustomSelectChange = (field: string, value: any): void => {
    if (!validateInput(values, InstitutionalAccount, field, value.value)) return

    const newValues = { ...values }

    if (field === 'registeredOfficeAddress.country') {
      newValues['registeredOfficeAddress']['region'] = ''
      setValues(deepCopyAndSet(field, newValues, value.value))
    } else if (field === 'correspondenceAddress.country') {
      newValues['correspondenceAddress']['region'] = ''
      setValues(deepCopyAndSet(field, values, value.value))
    } else {
      setValues(deepCopyAndSet(field, values, value.value))
    }

    if ((errors as any)[field] !== '') {
      setErrors(deepCopyAndSet(field, errors, ''))
    }
  }

  const handleSelectChange = (e: FormEvent<HTMLSelectElement>): void => {
    if (!validateInput(values, InstitutionalAccount, e.currentTarget.name, e.currentTarget.value)) return
    setValues(deepCopyAndSet(e.currentTarget.name, values, e.currentTarget.value))
    if ((errors as any)[e.currentTarget.name] !== '') {
      setErrors(deepCopyAndSet(e.currentTarget.name, errors, ''))
    }
  }

  const handleFileChange = (event: FormEvent<HTMLInputElement>, label: string): void => {
    const target = event.target
    if (target['files'] && target['files'][0]) {
      const selectedFile = target['files'][0]
      if (ACCEPTED_FILE_FORMATS.indexOf(selectedFile.type) === -1) {
        files[label] = selectedFile
        setFiles(files)
        setErrors(deepCopyAndSet(label, errors, ''))
      } else {
        setErrors(deepCopyAndSet(label, errors, `Only ${ACCEPTED_FILE_FORMATS} accepted`))
      }
    }
  }

  const getUserCountry = async (): Promise<any> => {
    try {
      const newValues = { ...values }
      const reply = await api.firstdigital.userCountry()
      if (reply && reply.country && !countries.restricted[reply.country]) {
        newValues['registeredOfficeAddress']['country'] = reply.country
        // newValues['correspondenceAddress']['country'] = reply.country
      } else {
        newValues['registeredOfficeAddress']['country'] = ''
        // newValues['correspondenceAddress']['country'] = reply.country
      }
      setValues(newValues)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setGeneralError(JSON.stringify(err))
    }
  }

  // const removeEmpty = (obj): string => {
  //   return JSON.stringify(
  //     Object.entries(obj)
  //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //       .filter(([_, v]) => v !== '')
  //       .reduce((acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmpty(v) : v }), {})
  //   )
  // }

  const preSubmit = async (e): Promise<void> => {
    e.preventDefault()
    setLoading(true)
    const [valid, newErrors] = validateMessage(
      values,
      institutionalAccountAction.messageType,
      institutionalAccountAction.errorsType
    )
    if (!valid) {
      setLoading(false)
      setErrors(newErrors)
      return
    }

    //upload image
    const keys = Object.keys(files)
    // Check count to make sure all images were uploaded before submit
    let count = 0
    keys.forEach((key) => {
      auth
        .api()
        .upload('/document', files[key], key)
        .then(async () => {
          count += 1
          if (count === keys.length) {
            const isCreated = await handleSubmit(e)
            if (isCreated) {
              dispatch(account.APPLIED_FOR_VERIFICATION(true))
              setLoading(false)
              setSuccess(true)
              setTimeout(() => {
                props.history.push(RouteConstants.Home)
              }, 30000)
              return
            } else {
              if (errors['errorMessage']) {
                setGeneralError(errors['errorMessage'])
              } else {
                // const newErrors = removeEmpty(errors)
                setGeneralError(JSON.stringify(errors) || 'Something went wrong')
              }
            }
            setLoading(false)
            setEnabled(true)
          }
        })
        .catch((err) => {
          setGeneralError('Error uploading details' + JSON.stringify(err))
        })
    })
  }

  useEffect(() => {
    getUserCountry()
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      {isLoading ? <LoadingBlock clearLoadingMethod={setLoading} setTimeoutErrorMethod={setGeneralError} /> : null}
      <SubmitInstitutionalAccount
        values={values}
        setValues={setValues}
        errors={errors}
        // getRestrictedCountries={getRestrictedCountries}
        handleChange={handleChange}
        handleCustomSelectChange={handleCustomSelectChange}
        handleSelectChange={handleSelectChange}
        handleSubmit={preSubmit}
        handleFileChange={handleFileChange}
        enabled={enabled}
        success={success}
        setSuccess={setSuccess}
        state={state}
        setState={setState}
      />
    </div>
  )
}

export default InstitutionalAccountContainer
