import styled from 'styled-components'

const AdminComponent = styled.div`
  .toggle-wrapper {
    max-width: 65rem;
  }

  .card-small {
    max-width: 65rem;
  }
  .align-vertical {
    margin: auto;
  }
  .align-vertical button {
    margin: 0 1rem;
  }

  button.enabled {
    border-radius: 0.25rem;
  }

  button.disabled {
    border-radius: 0.25rem;
    background-color: var(--light-gray);
    color: var(--white);
  }

  .calc-table {
    table-layout: fixed;
    width: 100%;
    text-align: center;
  }
  .calc-table th,
  .calc-table td {
    border: 1px solid black;
  }
  table.calc-table tr:nth-child(2) {
    background-color: lightgray;
  }
`

export default AdminComponent
