import { StringRules, TimeStampRules, validation } from '../validation'
import { merge } from '../../util'

export class IbanBankAccount {
  constructor(init?: IbanBankAccount) {
    if (init) merge(init, this)
  }
  @validation(new TimeStampRules({ required: true }))
  public createdAt? = Date.now()
  @validation(new StringRules({ required: true, len: 2 }))
  public country? = ''
  @validation(new StringRules({ required: true, maxLen: 80 }))
  public accountName? = ''
  @validation(new StringRules({ required: true, maxLen: 40 }))
  public ibanNumber? = ''
  @validation(new StringRules({ required: true, maxLen: 15 }))
  public swiftRoute? = ''
  @validation(new StringRules({ required: true, maxLen: 80 }))
  public bankName? = ''
  public nickName? = ''
}

export class IbanBankAccountErrors {
  constructor(init?: IbanBankAccountErrors) {
    if (init) merge(init, this)
  }
  public createdAt? = ''
  public country? = ''
  public accountName? = ''
  public ibanNumber? = ''
  public swiftRoute? = ''
  public bankName? = ''
  public nickName? = ''
}
