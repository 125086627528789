import React, { FormEvent } from 'react'
import { TextInput, TextAreaInput } from '@/ApiReact'
import { SelectCountries } from '../../components/locale/countries'
import context from '../../util/localize'
import { FDUSBankAccount, FDUSBankAccountErrors } from '@/Api'
import SuccessBlock from '../../container/usernotifications/success'
import { SUPPORT_EMAIL } from '../../constants/config'

export type AddUSBankAccComponentProps = {
  handleChange: (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleCountryChange: (value: any) => void
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  values: FDUSBankAccount
  errors: FDUSBankAccountErrors
  country: string
  success: boolean
  setSuccess: (boolean) => void
  setValues?: React.Dispatch<any>
}

export const US_BANK_ACC_DATA_TESTID = 'us-bank-acc-testid'

const AddUSBankAccount = ({
  handleChange,
  handleCountryChange,
  handleSubmit,
  values,
  errors,
  country,
  success,
  setSuccess,
}: AddUSBankAccComponentProps): JSX.Element => {
  return (
    <React.Fragment>
      {success ? (
        <SuccessBlock clearSuccessMethod={setSuccess} successMsg={context.successBankAcc} />
      ) : (
        <form onSubmit={handleSubmit} data-testid={US_BANK_ACC_DATA_TESTID}>
          <div className="flex-row">
            <div className="col-3">
              <SelectCountries
                name="country"
                label="Country"
                handleCountryChange={handleCountryChange}
                value={country}
                error={errors.country || errors.Country}
              />
              {/* <CountrySelection
                handleCountryChange={handleCountryChange}
                country={country}
                error={errors.country || errors.Country}
              /> */}
            </div>
            <div className="col-6">
              <TextInput
                type="text"
                label={context.bankName}
                name="bankName"
                value={values.bankName}
                onChange={handleChange}
                error={errors.bankName || errors.BankName}
              />
            </div>
          </div>
          <div className="flex-row">
            <div className="col-6">
              <TextInput
                type="text"
                name="nameOnAccount"
                label={context.accountHoldersName}
                value={values.nameOnAccount}
                onChange={handleChange}
                error={errors.nameOnAccount || errors.NameOnAccount}
              />
            </div>
            <div className="col-3">
              <TextInput
                type="text"
                name="accountNumber"
                label={context.accountNumber}
                // placeholder="ABCDFGH1234512789"
                value={values.accountNumber}
                onChange={handleChange}
                error={errors.accountNumber || errors.AccountNumber}
              />
            </div>
          </div>

          <div className="flex-row">
            <div className="col-5">
              <TextInput
                type="text"
                name="abaRoutingNumber"
                label={context.abaRoutingNumber}
                // placeholder="ABCDFGH1234512789"
                value={values.abaRoutingNumber}
                onChange={handleChange}
                disabled={country == 'US' ? false : true}
                error={errors.abaRoutingNumber || errors.AbaRoutingNumber}
              />
            </div>
            <div className="col-5">
              <TextInput
                type="text"
                name="bankAddress"
                label={context.bankAddress}
                // placeholder="ABCDFGH1234512789"
                value={values.bankAddress}
                onChange={handleChange}
                error={errors.bankAddress || errors.BankAddress}
              />
            </div>
          </div>
          <div className="flex-row">
            <div className="col-10">
              <TextAreaInput
                name="additionalInfo"
                label="Additional Info"
                value={values.additionalInfo}
                rows={5}
                onChange={handleChange}
                error={''}
              />
            </div>
          </div>
          <p className="text-bold-imp bank-info-text">
            {context.forAssistanceEmailUs}
            <a href={`mailto:${SUPPORT_EMAIL}`} className="link underline">
              {SUPPORT_EMAIL}
            </a>
          </p>
          <div className="center-align-container">
            <button type="submit" className="button-primary">
              {context.submit}
            </button>
          </div>
        </form>
      )}
    </React.Fragment>
  )
}

export default AddUSBankAccount
