import { merge } from '../../util'

export class Email {
  constructor(init?: Email) {
    if (init) merge(init, this)
  }

  public address? = ''
  public domain? = ''
}
