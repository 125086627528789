import { merge } from '../../util'

export class Telephone {
  constructor(init?: Telephone) {
    if (init) merge(init, this)
  }

  public country? = ''
  public countryCode? = 0
  public number? = ''
  public extension? = ''
}
