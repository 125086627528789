import { StringRules, TimeStampRules, UIntRules, validation } from '../validation'
import { merge } from '../../util'

export class UsBankAccount {
  constructor(init?: UsBankAccount) {
    if (init) merge(init, this)
  }
  @validation(new TimeStampRules({ required: true }))
  public createdAt? = Date.now()
  @validation(new StringRules({ required: true, const: 'US' }))
  public country? = 'US'
  @validation(new StringRules({ required: true, maxLen: 80 }))
  public accountName? = ''
  @validation(new UIntRules({ required: true, maxLen: 17 }))
  public accountNumber? = ''
  @validation(new UIntRules({ required: true, len: 9 }))
  public wireRoute? = ''
  @validation(new UIntRules({ required: true, len: 9 }))
  public achRoute? = ''
  @validation(new StringRules({ required: true, maxLen: 80 }))
  public bankName? = ''
  public nickName? = ''
}

export class UsBankAccountErrors {
  constructor(init?: UsBankAccountErrors) {
    if (init) merge(init, this)
  }
  public createdAt? = ''
  public country? = ''
  public accountName? = ''
  public accountNumber? = ''
  public wireRoute? = ''
  public achRoute? = ''
  public bankName? = ''
  public nickName? = ''
}
