import { StringRules, validation, AddressRules, BoolRules } from '../validation'
import { Address, Name } from '../common'
import { merge } from '../../util'

export class InstitutionalAccount {
  constructor(init?: InstitutionalAccount) {
    if (init) merge(init, this)
  }
  @validation(new StringRules({ required: true }))
  public companyName? = ''
  @validation(new StringRules({ required: true }))
  public natureOfBusiness? = ''
  public businessRegistrationNumber? = ''
  @validation(new StringRules({ required: true }))
  public dateOfIncorporation? = ''
  @validation(new AddressRules({ required: true }))
  public registeredOfficeAddress = new Address()
  public correspondenceAddress = new Address()
  @validation(new StringRules({ required: true, maxLen: 50 }))
  public email? = ''
  @validation(new StringRules({ required: true, maxLen: 20 }))
  public businessPhoneNumber? = ''
  public primaryContactPersonName = new Name()
  @validation(new StringRules({ required: true }))
  public primaryContactEmail? = ''
  @validation(new StringRules({ required: true }))
  public taxId? = ''
  @validation(new StringRules({ required: true }))
  public taxCountry? = ''
  @validation(new StringRules({ required: true }))
  public listOfDirectorsNames? = ''
  @validation(new StringRules({ required: true }))
  public keyShareholdersNames? = ''
  @validation(new StringRules({ required: true }))
  public sourceOfWealth? = ''
  @validation(new StringRules({ required: true }))
  public sourceOfFunds? = ''
  public anyLitigations? = ''
  public anyRegulatoryActions? = ''
  @validation(new BoolRules({ required: true }))
  public areRegulatedEntity? = false
  @validation(new BoolRules({ required: true }))
  public haveNeverBeenUsCitizen? = false
  public summaryOfRegulatoryStatus? = ''
}

export { InstitutionalAccount as InstitutionalAccountErrors }
