import 'reflect-metadata'
import { BoolRules, validateBoolValue, validateBoolInput } from './bool'
import { DateRules, validateDateValue, validateDateInput } from './date'
import { DateTimeRules, validateDateTimeValue, validateDateTimeInput } from './datetime'
import { UUIDRules, validateUUIDValue, validateUUIDInput } from './uuid'
import { FloatRules, validateFloatValue, validateFloatInput } from './float'
import { IntRules, validateIntValue, validateIntInput } from './int'
import { StringRules, validateStringValue, validateStringInput } from './string'
import { AddressRules, validateAddressValue } from './address'
import { TimeStampRules, validateTimeStampValue, validateTimeStampInput } from './timestamp'
import { UIntRules, validateUIntValue, validateUIntInput } from './uint'

export const validationMetadataKey = Symbol('validation')

export type ValidationRules =
  | BoolRules
  | DateRules
  | DateTimeRules
  | FloatRules
  | IntRules
  | StringRules
  | UIntRules
  //  | AddressRules
  | UUIDRules

export function getValidationRules(target: any, propertyKey: string): ValidationRules | undefined {
  return Reflect.getMetadata(validationMetadataKey, target, propertyKey)
}

export function validation(
  rules: ValidationRules
): {
  (target: Function): void
  (target: Record<string, any>, propertyKey: string | symbol): void
} {
  return Reflect.metadata(validationMetadataKey, rules)
}

export function validateValueRules(value: string, rules?: ValidationRules, key?: string): string {
  if (!rules) return ''
  if (rules instanceof BoolRules) {
    return validateBoolValue(value, rules)
  } else if (rules instanceof DateRules) {
    return validateDateValue(value, rules)
  } else if (rules instanceof DateTimeRules) {
    return validateDateTimeValue(value, rules)
  } else if (rules instanceof FloatRules) {
    return validateFloatValue(value, rules)
  } else if (rules instanceof IntRules) {
    return validateIntValue(value, rules)
  } else if (rules instanceof StringRules) {
    return validateStringValue(value, rules, key)
  } else if (rules instanceof TimeStampRules) {
    return validateTimeStampValue(value, rules)
  } else if (rules instanceof UIntRules) {
    return validateUIntValue(value, rules)
  } else if (rules instanceof UUIDRules) {
    return validateUUIDValue(value, rules)
  }
  return ''
}

export function validateMessageRules<T>(message: T, rules: ValidationRules): object | undefined {
  if (!rules) return
  if (rules instanceof AddressRules) {
    return validateAddressValue(message, rules)
  }
  return
}

export function validateValue<T>(message: T, key: string, value: any): string {
  if (value == null || value === undefined || typeof value.toString !== 'function') {
    return validateValueRules(value, getValidationRules(message, key))
  }
  return validateValueRules(value.toString(), getValidationRules(message, key), key)
}

export function validateMessageFields<T>(message: T, errors: T): boolean {
  let fail = false
  for (const key in message) {
    if (typeof message[key] === 'object') {
      const validationRules = getValidationRules(message, key)
      if (!validationRules) {
        const pass = validateMessageFields(message[key], errors[key])
        if (!pass && !fail) fail = true
        continue
      }

      const err = validateMessageRules(message[key], validationRules)
      if (err) {
        if (!fail) fail = true
        ;(errors as any)[key] = err
      }
    } else {
      const err = validateValue(message, key, message[key])
      if (err !== '') {
        if (!fail) fail = true
        ;(errors as any)[key] = err
      }
    }
  }
  return !fail
}

export function validateMessage<T>(
  message: T,
  messageType: new (init?: T) => T,
  errorsType: new (init?: T) => T
): [boolean, T] {
  const errors = new errorsType()
  if (!(message instanceof messageType)) {
    message = new messageType(message)
  }
  const pass = validateMessageFields(message, errors)
  return [pass, errors]
}

export function validateInputRules(value: string, rules?: ValidationRules): boolean {
  if (rules instanceof BoolRules) {
    return validateBoolInput(value, rules)
  } else if (rules instanceof DateRules) {
    return validateDateInput(value, rules)
  } else if (rules instanceof DateTimeRules) {
    return validateDateTimeInput(value, rules)
  } else if (rules instanceof FloatRules) {
    return validateFloatInput(value, rules)
  } else if (rules instanceof IntRules) {
    return validateIntInput(value, rules)
  } else if (rules instanceof StringRules) {
    return validateStringInput(value, rules)
  } else if (rules instanceof TimeStampRules) {
    return validateTimeStampInput(value, rules)
  } else if (rules instanceof UIntRules) {
    return validateUIntInput(value, rules)
  } else if (rules instanceof UUIDRules) {
    return validateUUIDInput(value, rules)
  }
  return true
}

export function validateInput<T>(message: T, messageType: new (init?: T) => T, key: string, value: string): boolean {
  if (!(message instanceof messageType)) {
    message = new messageType(message)
  }
  while (key.includes('.')) {
    message = (message as any)[key.split('.')[0]]
    key = key.split('.')[1]
  }
  return validateInputRules(value, getValidationRules(message, key))
}
