import React, { useState } from 'react'
import Language from '../components/locale/language'

const LanguageContainer = (): JSX.Element => {
  const [selectedLanguage, setCountry] = useState(localStorage.getItem('locale') || 'en')

  const handleLanguageChange = (event: any): void => {
    const selectedLanguage = event.target.value
    setCountry(selectedLanguage)
    localStorage.setItem('locale', selectedLanguage)
    window.location.reload()
  }

  return (
    <React.Fragment>
      <Language handleLanguageChange={handleLanguageChange} selectedLanguage={selectedLanguage} />
    </React.Fragment>
  )
}

export default LanguageContainer
