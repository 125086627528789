import React, { FormEvent, SetStateAction, Dispatch } from 'react'
import { TextInput, SelectInput, FileInput } from '@/ApiReact'
import context from '../../util/localize'
import InstitutionalAccountComponent from '../../blocks/institutionalaccount'
import { InstitutionalAccount, InstitutionalAccountErrors } from '@/Api'
import SuccessBlock from '../../container/usernotifications/success'
import { SelectCountries, SelectState } from '../../components/locale/countries'

type InstitutionalAccountProps = {
  enabled: boolean
  // getRestrictedCountries: () => string
  handleChange: (e: FormEvent<HTMLInputElement>) => void
  handleSelectChange: (e: FormEvent<HTMLSelectElement>) => void
  handleCustomSelectChange: (field: string, val: any) => void
  handleSubmit: ((event: FormEvent<HTMLFormElement>) => void) | undefined
  handleFileChange: (event: FormEvent<HTMLInputElement>, label: string) => void | undefined
  values: InstitutionalAccount
  setValues: Dispatch<SetStateAction<any>>
  errors: InstitutionalAccountErrors
  success: boolean
  setSuccess: (boolean) => void
  state: any
  setState: (state: any) => void
}

const SubmitInstitutionalAccount = ({
  // getRestrictedCountries,
  handleSubmit,
  handleSelectChange,
  handleCustomSelectChange,
  handleChange,
  handleFileChange,
  enabled,
  values,
  setValues,
  errors,
  success,
  setSuccess,
  state,
  setState,
}: InstitutionalAccountProps): JSX.Element => {
  return (
    <InstitutionalAccountComponent>
      <div className="container">
        <div className="card-small">
          {success ? (
            <SuccessBlock
              clearSuccessMethod={setSuccess}
              successMsg={context.successKYCSubmitted}
              showRedirectToHome={true}
            />
          ) : (
            <form onSubmit={handleSubmit}>
              <p className="text-align-center uppercase text-bold">{context.openBusinessAccount}</p>
              <p className="text-align-center text-bold-imp">{context.openAccInstruction}</p>
              <div className="flex-row">
                <div className="col-5">
                  <TextInput
                    type="text"
                    label={context.companyName}
                    name="companyName"
                    value={values.companyName}
                    error={errors.companyName}
                    onChange={handleChange}
                    required={true}
                    showAsterisk={true}
                  />
                </div>
                <div className="col-5">
                  <TextInput
                    type="text"
                    label={context.natureofBusiness}
                    name="natureOfBusiness"
                    value={values.natureOfBusiness}
                    error={errors.natureOfBusiness}
                    onChange={handleChange}
                    showAsterisk={true}
                  />
                </div>
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <TextInput
                    type="text"
                    label={context.businessRegistrationNumber}
                    name="businessRegistrationNumber"
                    value={values.businessRegistrationNumber}
                    error={errors.businessRegistrationNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-5">
                  <TextInput
                    type="date"
                    label={context.dateofIncorporation}
                    name="dateOfIncorporation"
                    value={values.dateOfIncorporation}
                    error={errors.dateOfIncorporation}
                    onChange={handleChange}
                    showAsterisk={true}
                  />
                </div>
              </div>
              <div className="flex-row">
                <label className="label top-margin-label">{context.registeredOfficeAddress}</label>
              </div>
              {/* <div className="text-bold-small">
                {context.formatString(context.restrictedCountriesNotice, getRestrictedCountries())}
              </div> */}
              <div className="flex-row">
                <div className="col-5">
                  <SelectCountries
                    name="registeredOfficeAddress.country"
                    label={context.country}
                    handleCountryChange={(val): any => handleCustomSelectChange('registeredOfficeAddress.country', val)}
                    value={values.registeredOfficeAddress.country}
                    error={errors.registeredOfficeAddress.country || errors['registeredOfficeAddress.country']}
                    showAsterisk={true}
                  />
                </div>
                <div className="col-5">
                  <SelectState
                    name="registeredOfficeAddress.region"
                    country={values.registeredOfficeAddress.country}
                    label={context.region}
                    handleStateChange={(val): any => handleCustomSelectChange('registeredOfficeAddress.region', val)}
                    handleChange={handleChange}
                    value={values.registeredOfficeAddress.region}
                    error={errors.registeredOfficeAddress.region || errors['registeredOfficeAddress.region']}
                    showAsterisk={true}
                  />
                </div>
              </div>
              <div className="flex-row">
                <div className="col-10">
                  <TextInput
                    type="text"
                    label={context.address}
                    name="registeredOfficeAddress.street_1"
                    value={values.registeredOfficeAddress.street_1}
                    error={errors.registeredOfficeAddress.street_1 || errors['registeredOfficeAddress.street_1']}
                    onChange={handleChange}
                    showAsterisk={true}
                  />
                </div>
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <TextInput
                    type="text"
                    label={context.city}
                    name="registeredOfficeAddress.city"
                    value={values.registeredOfficeAddress.city}
                    error={errors.registeredOfficeAddress.city || errors['registeredOfficeAddress.city']}
                    onChange={handleChange}
                    showAsterisk={true}
                  />
                </div>
                <div className="col-5">
                  <TextInput
                    type="text"
                    label={context.postal}
                    name="registeredOfficeAddress.postal"
                    value={values.registeredOfficeAddress.postal}
                    error={errors.registeredOfficeAddress.postal || errors['registeredOfficeAddress.postal']}
                    onChange={handleChange}
                    showAsterisk={true}
                  />
                </div>
              </div>
              <div className="flex-row">
                <label className="label top-margin-label">{context.correspondenceAddress}</label>
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <SelectCountries
                    name="correspondenceAddress.country"
                    label={context.country}
                    handleCountryChange={(val): any => handleCustomSelectChange('correspondenceAddress.country', val)}
                    value={values.correspondenceAddress.country}
                    error={errors.correspondenceAddress.country || errors['correspondenceAddress.country']}
                  />
                </div>
                <div className="col-5">
                  <SelectState
                    name="correspondenceAddress.region"
                    country={values.correspondenceAddress.country}
                    label={context.region}
                    handleStateChange={(val): any => handleCustomSelectChange('correspondenceAddress.region', val)}
                    handleChange={handleChange}
                    value={values.correspondenceAddress.region}
                    error={errors.correspondenceAddress.region || errors['correspondenceAddress.region']}
                  />
                </div>
              </div>
              <div className="flex-row">
                <div className="col-10">
                  <TextInput
                    type="text"
                    label={context.address}
                    name="correspondenceAddress.street_1"
                    value={values.correspondenceAddress.street_1}
                    error={errors.correspondenceAddress.street_1 || errors['correspondenceAddress.street_1']}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <TextInput
                    type="text"
                    label={context.city}
                    name="correspondenceAddress.city"
                    value={values.correspondenceAddress.city}
                    error={errors.correspondenceAddress.city || errors['correspondenceAddress.city']}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-5">
                  <TextInput
                    type="text"
                    label={context.postal}
                    name="correspondenceAddress.postal"
                    value={values.correspondenceAddress.postal}
                    error={errors.correspondenceAddress.postal || errors['correspondenceAddress.postal']}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex-row">
                <hr />
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <TextInput
                    type="text"
                    label={context.emailAddress}
                    name="email"
                    value={values.email}
                    error={errors.email}
                    onChange={handleChange}
                    showAsterisk={true}
                  />
                </div>
                <div className="col-5">
                  <TextInput
                    type="text"
                    label={context.businessPhoneNumber}
                    name="businessPhoneNumber"
                    value={values.businessPhoneNumber}
                    error={errors.businessPhoneNumber}
                    onChange={handleChange}
                    showAsterisk={true}
                  />
                </div>
              </div>

              <div className="flex-row">
                <div className="col-5">
                  <TextInput
                    type="text"
                    label={context.primaryContactPersonFirstName}
                    name="primaryContactPersonName.first"
                    value={values.primaryContactPersonName.first}
                    error={errors.primaryContactPersonName.first || errors['primaryContactPersonName.first']}
                    onChange={handleChange}
                    showAsterisk={true}
                  />
                </div>
                <div className="col-5">
                  <TextInput
                    type="text"
                    label={context.primaryContactPersonLastName}
                    name="primaryContactPersonName.last"
                    value={values.primaryContactPersonName.last}
                    error={errors.primaryContactPersonName.last || errors['primaryContactPersonName.last']}
                    onChange={handleChange}
                    showAsterisk={true}
                  />
                </div>
              </div>
              <div className="flex-row">
                <div className="col-6">
                  <TextInput
                    type="text"
                    label={context.primaryContactEmail}
                    name="primaryContactEmail"
                    value={values.primaryContactEmail}
                    error={errors.primaryContactEmail}
                    onChange={handleChange}
                    showAsterisk={true}
                  />
                </div>
                <div className="col-3">
                  <SelectCountries
                    name="taxCountry"
                    label={context.taxCountry}
                    handleCountryChange={(val): any => handleCustomSelectChange('taxCountry', val)}
                    value={values.taxCountry}
                    error={errors.taxCountry}
                    showAsterisk={true}
                  />
                </div>
              </div>
              <div className="flex-row">
                <div className="col-3">
                  <TextInput
                    type="text"
                    label={context.taxId}
                    name="taxId"
                    value={values.taxId}
                    error={errors.taxId}
                    onChange={handleChange}
                    showAsterisk={true}
                  />
                </div>
                <div className="col-6">
                  <TextInput
                    type="text"
                    label={context.listofDirectorsNames}
                    name="listOfDirectorsNames"
                    value={values.listOfDirectorsNames}
                    error={errors.listOfDirectorsNames}
                    onChange={handleChange}
                    showAsterisk={true}
                  />
                </div>
              </div>
              <TextInput
                type="text"
                label={context.keyShareHoldersNames}
                name="keyShareholdersNames"
                value={values.keyShareholdersNames}
                error={errors.keyShareholdersNames}
                onChange={handleChange}
                showAsterisk={true}
              />
              <div className="flex-row">
                <div className="col-5">
                  <SelectInput
                    className="input-group"
                    name="sourceOfWealth"
                    label={context.sourceofWealth}
                    onChange={handleSelectChange}
                    value={values.sourceOfWealth}
                    error={errors.sourceOfWealth}
                    showAsterisk={true}
                  >
                    <option value="">Choose here</option>
                    <option>Salary</option>
                    <option>Income Accumulation</option>
                    <option>Commission</option>
                    <option>Business Income</option>
                    <option>Pension</option>
                    <option>Reserve</option>
                    <option>Return on investment</option>
                    <option>Other</option>
                  </SelectInput>
                </div>
                <div className="col-5">
                  <SelectInput
                    className="input-group"
                    name="sourceOfFunds"
                    label={context.sourceofFunds}
                    onChange={handleSelectChange}
                    value={values.sourceOfFunds}
                    error={errors.sourceOfFunds}
                    showAsterisk={true}
                  >
                    <option value="">Choose here</option>
                    <option>Salary</option>
                    <option>Income Accumulation</option>
                    <option>Commission</option>
                    <option>Business Income</option>
                    <option>Pension</option>
                    <option>Reserve</option>
                    <option>Return on investment</option>
                    <option>Other</option>
                  </SelectInput>
                </div>
              </div>
              <div className="flex-row">
                <div className="col-8">
                  <div className="ckb-lbl">
                    <label className="label">
                      <input
                        type="checkbox"
                        name="haveNeverBeenUsCitizen"
                        onChange={(e): void => {
                          setState({
                            ...state,
                            haveNeverBeenUsCitizen: e.currentTarget.checked,
                          })
                          if (!e.currentTarget.checked) {
                            const newValues = { ...values }
                            newValues.haveNeverBeenUsCitizen = e.currentTarget.checked
                            setValues(newValues)
                          }
                        }}
                      />
                      {context.weHaveNeverBeenUsCitizen}
                    </label>
                    {/* <span className="required-asterisk"> *</span> */}
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="col-10">
                  <div className="ckb-lbl">
                    <label className="label">
                      <input
                        type="checkbox"
                        name="noLitigations"
                        onChange={(e): void => {
                          setState({
                            ...state,
                            noLitigations: e.currentTarget.checked,
                          })
                          if (!e.currentTarget.checked) {
                            const newValues = { ...values }
                            newValues[e.currentTarget.name] = ''
                            setValues(newValues)
                          }
                        }}
                      />
                      {context.anyLitigationsInstitution}
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex-row">
                {!state.noLitigations && (
                  <div className="col-10">
                    <TextInput
                      type="text"
                      label={context.anyLitigationsDetail}
                      name="anyLitigations"
                      value={values.anyLitigations}
                      error={errors.anyLitigations}
                      onChange={handleChange}
                      required={true}
                      showAsterisk={true}
                    />
                  </div>
                )}
              </div>
              <div className="flex-row">
                <div className="col-10">
                  <div className="ckb-lbl">
                    <label className="label">
                      <input
                        type="checkbox"
                        name="noRegulatoryActions"
                        onChange={(e): void => {
                          setState({
                            ...state,
                            noRegulatoryActions: e.currentTarget.checked,
                          })
                          if (!e.currentTarget.checked) {
                            const newValues = { ...values }
                            newValues[e.currentTarget.name] = ''
                            setValues(newValues)
                          }
                        }}
                      />
                      {context.anyRegulatoryActionsInstitution}
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex-row">
                {!state.noRegulatoryActions && (
                  <div className="col-10">
                    <TextInput
                      type="text"
                      label={context.anyRegulatoryActionsDetail}
                      name="anyRegulatoryActions"
                      value={values.anyRegulatoryActions}
                      error={errors.anyRegulatoryActions}
                      onChange={handleChange}
                      required={true}
                      showAsterisk={true}
                    />
                  </div>
                )}
              </div>
              <div className="flex-row">
                <div className="col-10">
                  <div className="ckb-lbl">
                    <label className="label">
                      <input
                        type="checkbox"
                        name="areRegulatedEntity"
                        checked={values.areRegulatedEntity}
                        onChange={(e): void => {
                          setValues({
                            ...values,
                            areRegulatedEntity: e.currentTarget.checked,
                          })
                          if (e.currentTarget.checked) {
                            const newValues = { ...values }
                            newValues.summaryOfRegulatoryStatus = ''
                            newValues.areRegulatedEntity = true
                            setValues(newValues)
                          }
                        }}
                      />
                      {context.weAreRegulatedEntity}
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex-row">
                {values.areRegulatedEntity && (
                  <div className="col-10">
                    <TextInput
                      type="text"
                      label={context.summaryOfRegulatoryStatus}
                      name="summaryOfRegulatoryStatus"
                      value={values.summaryOfRegulatoryStatus}
                      error={errors.summaryOfRegulatoryStatus}
                      onChange={handleChange}
                      required={true}
                      showAsterisk={true}
                    />
                  </div>
                )}
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <div className="input-file">
                    <FileInput
                      name="certificate-of-incorporation"
                      label={context.certificateofIncorporation}
                      onChange={(e): void => handleFileChange(e, 'certificate-of-incorporation')}
                      error={errors['certificateOfIncorporation'] || errors['certificate-of-incorporation']}
                      disabled={!enabled}
                      showAsterisk={true}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <div className="input-file">
                    <FileInput
                      name="business-registration-certificate"
                      label={context.businessRegistrationCertificate}
                      onChange={(e): void => handleFileChange(e, 'business-registration-certificate')}
                      error={errors['businessRegistrationCertificate'] || errors['business-registration-certificate']}
                      disabled={!enabled}
                      showAsterisk={false}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <div className="input-file">
                    <FileInput
                      name="articles-of-association"
                      label={context.articlesofAssociation}
                      onChange={(e): void => handleFileChange(e, 'articles-of-association')}
                      error={errors['articlesofAssociation'] || errors['articles-of-association']}
                      disabled={!enabled}
                      showAsterisk={true}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <div className="input-file">
                    <FileInput
                      name="list-of-directors"
                      label={context.listofDirectors}
                      onChange={(e): void => handleFileChange(e, 'list-of-directors')}
                      error={errors['listOfDirectors'] || errors['list-of-directors']}
                      disabled={!enabled}
                      showAsterisk={true}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <div className="input-file">
                    <FileInput
                      name="list-of-shareholders"
                      label={context.listofShareholders}
                      onChange={(e): void => handleFileChange(e, 'list-of-shareholders')}
                      error={errors['listofShareholders'] || errors['list-of-shareholders']}
                      showAsterisk={true}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <div className="input-file">
                    <FileInput
                      name="id-copies-of-directors-and-key-shareholders"
                      label={context.idCopiesofDirectorsAndKeyShareholders}
                      onChange={(e): void => handleFileChange(e, 'id-copies-of-directors-and-key-shareholders')}
                      error={
                        errors['idCopiesofDirectorsAndKeyShareholders'] ||
                        errors['id-copies-of-directors-and-key-shareholders']
                      }
                      disabled={!enabled}
                      showAsterisk={true}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <div className="input-file">
                    <FileInput
                      name="company-structure"
                      label={context.companyStructure}
                      onChange={(e): void => handleFileChange(e, 'company-structure')}
                      error={errors['companyStructure'] || errors['company-structure']}
                      disabled={!enabled}
                      showAsterisk={true}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="col-5">
                  <div className="input-file">
                    <FileInput
                      name="evidence-of-listed-or-regulatory-status"
                      label={context.evidenceofListedOrRegulatoryStatus}
                      onChange={(e): void => handleFileChange(e, 'evidence-of-listed-or-regulatory-status')}
                      error={
                        errors['evidenceofListedOrRegulatoryStatus'] ||
                        errors['evidence-of-listed-or-regulatory-status']
                      }
                      disabled={!enabled}
                    />
                  </div>
                </div>
              </div>
              <div className="center-align-container">
                <button type="submit" className="button-primary" disabled={!enabled}>
                  {context.submit}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </InstitutionalAccountComponent>
  )
}

export default SubmitInstitutionalAccount
