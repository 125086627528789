import { ActionType } from '../action'
import { InstitutionalAccount, InstitutionalAccountErrors } from '../../messages/firstdigital'

export class ActionInstitutionalAccount implements ActionType<InstitutionalAccount, InstitutionalAccountErrors> {
  readonly authRequired = true
  readonly setUrl = '/account/institutional/open'
  public message = new InstitutionalAccount()
  public errors = new InstitutionalAccountErrors()
  public messageType = InstitutionalAccount
  public errorsType = InstitutionalAccountErrors
}
