import React from 'react'
import context from '../util/localize'
import SuccessBlock from '../container/usernotifications/success'
import { AddRedemptionAddresses, AddRedemptionAddressesErrors } from 'Api/src/messages'
import { TextAreaInput } from '@/ApiReact'

export type AdminProps = {
  errors: AddRedemptionAddressesErrors
  handleChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
  showContent: boolean
  setSuccess: (val: boolean) => void
  success: boolean
  values: AddRedemptionAddresses
}

const Admin = ({
  errors,
  handleChange,
  handleSubmit,
  setSuccess,
  showContent,
  success,
  values,
}: AdminProps): JSX.Element => {
  return (
    <React.Fragment>
      {showContent ? (
        <div>
          <p className="text-align-center text-bold">
            {context.redemptionAddrAvailable}:{' '}
            {values.success ? (values.count ? values.count : 0) : 'Value unavailable'}
          </p>
          {success ? (
            <SuccessBlock clearSuccessMethod={setSuccess} successMsg={context.successAddRedemptionAdd} />
          ) : (
            <form onSubmit={handleSubmit}>
              <p className="text-align-center">{context.addEthAddrListIns}</p>
              <div className="sub-card">
                <TextAreaInput
                  name="addressList"
                  label={context.ethAddrList}
                  value={values.addressList}
                  rows={10}
                  onChange={handleChange}
                  error={''}
                />
                <div className="error">
                  <div className="input-feedback">
                    {errors.addressList || errors.AddressList || errors.addresses || errors.Addresses}
                  </div>
                </div>
              </div>
              <div className="center-align-container">
                <button type="submit" className="button-primary">
                  {context.submit}
                </button>
              </div>
            </form>
          )}
        </div>
      ) : (
        <p className="text-align-center">Sorry you are not authorized to view this</p>
      )}
    </React.Fragment>
  )
}

export default Admin
