import React from 'react'
import language from '../../constants/language.json'
import { DISABLE_LOCALIZATION } from '../../constants/config'

export type LanguageSelectionProps = {
  selectedLanguage: string
  handleLanguageChange: React.ChangeEventHandler<HTMLSelectElement>
  renderLanguage: () => (JSX.Element | undefined)[]
}

export type LanguageProps = {
  handleLanguageChange: React.ChangeEventHandler<HTMLSelectElement>
  selectedLanguage: string
}

const renderLanguage = (): (JSX.Element | undefined)[] => {
  return Object.keys(language).map((item) => {
    if (language[item].enabledLocalization) {
      return (
        <option key={item} value={item}>
          {language[item].name}
        </option>
      )
    } else {
      return
    }
  })
}

const LanguageSelection = ({ selectedLanguage, handleLanguageChange, renderLanguage }: LanguageSelectionProps): any => (
  <div className="select-input">
    <select className="lang-selector" name="language" onChange={handleLanguageChange} value={selectedLanguage}>
      {renderLanguage()}
    </select>
  </div>
)

const Language = ({ handleLanguageChange, selectedLanguage }: LanguageProps): JSX.Element | null => {
  // Hidden using env
  const shouldShow = !DISABLE_LOCALIZATION
  return shouldShow ? (
    <>
      <LanguageSelection
        handleLanguageChange={handleLanguageChange}
        selectedLanguage={selectedLanguage}
        renderLanguage={renderLanguage}
      />
    </>
  ) : null
}

export default Language
