import React from 'react'
import { useAuthContext } from '@/ApiReact'

import Authed from './authed'
import Public from './public'

const Routes: React.FC = () => {
  const auth = useAuthContext()
  return auth.loggedIn ? <Authed /> : <Public />
}

export default Routes
