import { ActionType } from '../action'
import { FDUSBankAccount, FDUSBankAccountErrors, FDIntBankAccount, FDIntBankAccountErrors } from '../../messages/fiat'

export class ActionFDUSBankAccount implements ActionType<FDUSBankAccount, FDUSBankAccountErrors> {
  readonly authRequired = true
  readonly getUrl = '/bank/get'
  readonly setUrl = '/bank/add/us'
  public message = new FDUSBankAccount()
  public errors = new FDUSBankAccountErrors()
  public messageType = FDUSBankAccount
  public errorsType = FDUSBankAccountErrors
}

export class ActionFDIntBankAccount implements ActionType<FDIntBankAccount, FDIntBankAccountErrors> {
  readonly authRequired = true
  readonly getUrl = '/bank/get'
  readonly setUrl = '/bank/add/int'
  public message = new FDIntBankAccount()
  public errors = new FDIntBankAccountErrors()
  public messageType = FDIntBankAccount
  public errorsType = FDIntBankAccountErrors
}
