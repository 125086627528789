import React from 'react'
import classnames from 'classnames'
import { KeyValues } from '@/Api'

// file formats accepted in kyc submissions
export const ACCEPTED_FILE_FORMATS = '.png, .pdf, .jpg, .jpeg'
export interface LabelProps {
  htmlFor?: string
  children: (JSX.Element | string)[] | JSX.Element | string
}

export const Label: React.FC<LabelProps> = (props: LabelProps) => {
  const { htmlFor, children, ...anyprops } = props
  return (
    <label className="label" htmlFor={htmlFor} {...anyprops}>
      {children}
    </label>
  )
}

export interface InputFeedbackProps {
  name: string
  error: string
}

export const InputFeedback: React.FC<InputFeedbackProps> = (props: InputFeedbackProps) => {
  const { error, name } = props
  return error ? (
    <div className="input-feedback" data-testid={name}>
      {error}
    </div>
  ) : null
}

export interface TextInputProps {
  type: string
  name: string
  label: string
  error: string | KeyValues | any
  value: string | KeyValues | any
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  minLength?: number
  className?: string
  disabled?: boolean
  showAsterisk?: boolean
  placeholder?: string
  autoCompleteOff?: boolean
}

export interface SelectInputProps {
  name: string
  label: string
  error: string | KeyValues | any
  value: string | KeyValues | any
  defaultValue?: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  required?: boolean
  className?: string
  disabled?: boolean
  showAsterisk?: boolean
  placeholder?: string
}

export const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  const {
    type,
    name,
    label,
    error,
    value,
    onChange,
    onBlur,
    className,
    required,
    minLength,
    disabled,
    placeholder,
    showAsterisk,
    autoCompleteOff,
    ...anyprops
  } = props
  const classes = classnames(
    'input-group',
    {
      'animated shake error': !!error,
    },
    disabled ? 'disabled' : '',
    className
  )
  return (
    <div className={classes}>
      <Label htmlFor={name}>
        {label}
        {showAsterisk ? <span className="required-asterisk">*</span> : ''}
        <input
          id={name}
          name={name}
          className="text-input"
          type={type}
          data-testid={name}
          value={value}
          required={required ? true : false}
          minLength={minLength}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoCompleteOff ? 'off' : 'on'}
          {...anyprops}
        />
        <InputFeedback name={`${name}-error`} error={error} />
      </Label>
    </div>
  )
}

export interface TextAreaProps {
  name: string
  label: string
  error: string | KeyValues | any
  value: string | KeyValues | any
  onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  required?: boolean
  minLength?: number
  className?: string
  disabled?: boolean
  showAsterisk?: boolean
  placeholder?: string
  autoCompleteOff?: boolean
  rows?: number
  cols?: number
}

export const TextAreaInput: React.FC<TextAreaProps> = (props: TextAreaProps) => {
  const {
    name,
    label,
    error,
    value,
    onChange,
    onBlur,
    className,
    required,
    minLength,
    disabled,
    placeholder,
    showAsterisk,
    autoCompleteOff,
    rows,
    cols,
    ...anyprops
  } = props
  const classes = classnames(
    'input-group',
    {
      'animated shake error': !!error,
    },
    disabled ? 'disabled' : '',
    className
  )
  return (
    <div className={classes}>
      <Label htmlFor={name}>
        {label}
        {showAsterisk ? <span className="required-asterisk">*</span> : ''}
        <textarea
          id={name}
          name={name}
          className="input-text-area-10"
          data-testid={name}
          value={value}
          required={required ? true : false}
          minLength={minLength}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoCompleteOff ? 'off' : 'on'}
          rows={rows}
          cols={cols}
          {...anyprops}
        />
        <InputFeedback name={`${name}-error`} error={error} />
      </Label>
    </div>
  )
}

export const SelectInput: React.FC<SelectInputProps> = (props: SelectInputProps) => {
  const {
    name,
    label,
    error,
    value,
    onChange,
    className,
    showAsterisk,
    required,
    disabled,
    placeholder,
    defaultValue,
    ...anyprops
  } = props
  const classes = classnames(
    'input-group',
    {
      'animated shake error': !!error,
    },
    disabled ? 'disabled' : '',
    className
  )
  return (
    <div className={classes}>
      <Label htmlFor={name}>
        {label}
        {showAsterisk ? <span className="required-asterisk">*</span> : ''}
        <div className="select-input">
          <select
            id={name}
            name={name}
            value={value}
            required={required ? true : false}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            defaultValue={defaultValue}
            {...anyprops}
          />
        </div>
        <InputFeedback name={`${name}-error`} error={error} />
      </Label>
    </div>
  )
}

export const CustomTextInput: React.FC<TextInputProps> = (props: TextInputProps & any) => {
  const { name, label, error, className, disabled, showAsterisk, children } = props
  const classes = classnames(
    'input-group',
    {
      'animated shake error': !!error,
    },
    disabled ? 'disabled' : '',
    className
  )
  return (
    <div className={classes}>
      <Label htmlFor={name}>
        {label}
        {showAsterisk ? <span className="required-asterisk">*</span> : ''}
        {children}
        <InputFeedback name={`${name}-error`} error={error} />
      </Label>
    </div>
  )
}

export interface FileInputProps {
  type?: string
  name: string
  label: string
  error: string | KeyValues | any
  value?: string | KeyValues | any
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  className?: string
  disabled?: boolean
  showAsterisk?: boolean
}

export const FileInput: React.FC<FileInputProps> = (props: FileInputProps) => {
  const {
    type,
    name,
    label,
    error,
    value,
    onChange,
    onBlur,
    className,
    required,
    disabled,
    showAsterisk,
    ...anyprops
  } = props
  const classes = classnames(
    {
      'animated shake error': !!error,
    },
    disabled ? 'disabled' : '',
    className
  )
  return (
    <div className={classes}>
      <label className="label">{label}</label>
      {showAsterisk ? <span className="required-asterisk">*</span> : ''}
      <input
        id={name}
        name={name}
        className="custom-file-input"
        type={type || 'file'}
        accept={ACCEPTED_FILE_FORMATS}
        data-testid={name}
        value={value}
        required={required ? true : false}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        {...anyprops}
      />
      <InputFeedback name={`${name}-error`} error={error} />
    </div>
  )
}
