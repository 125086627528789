import Auth from '@aws-amplify/auth'
import { CognitoUser, CognitoUserSession, CognitoUserAttribute, UserData } from 'amazon-cognito-identity-js'
import { ApiConfig } from '../config'
import { ok, err, Result } from 'neverthrow'

export type AuthChallengeName =
  | 'CUSTOM_CHALLENGE'
  | 'NEW_PASSWORD_REQUIRED'
  | 'SMS_MFA'
  | 'SOFTWARE_TOKEN_MFA'
  | 'MFA_SETUP'
  | undefined

export type AuthUser = CognitoUser & {
  challengeName: AuthChallengeName
}
export class CognitoApi {
  constructor(public config: ApiConfig) {
    if (config.AWS_REGION && config.AWS_USER_POOL_ID) {
      Auth.configure({
        /*eslint-disable @typescript-eslint/camelcase */
        aws_cognito_region: config.AWS_REGION,
        aws_user_pools_id: config.AWS_USER_POOL_ID,
        aws_user_pools_web_client_id: config.AWS_USER_POOLS_WEB_CLIENT_ID,
        /*eslint-enable @typescript-eslint/camelcase */
      })
    }
    this.auth = Auth
  }

  public auth: any

  public async login(username: string, password: string): Promise<[CognitoUser | null, CognitoUserSession | null]> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    const user: CognitoUser = await this.auth.signIn(username, password)
    return [user, user.getSignInUserSession()]
  }

  public async login2FA(username: string, password: string): Promise<[AuthUser | any, CognitoUserSession | null]> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    const user = await this.auth.signIn(username, password)
    if (user.challengeName !== undefined) {
      return [user, null]
    } else {
      return [user, user.getSignInUserSession()]
    }
  }

  public async login2FAConfirm(
    loguser: any,
    code: string,
    mfaType: string
  ): Promise<[AuthUser | null, CognitoUserSession | null]> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    const user: AuthUser = await this.auth.confirmSignIn(loguser, code, mfaType)
    return [user, user.getSignInUserSession()]
  }

  public async signup(username: string, password: string): Promise<CognitoUser | null> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    const { user } = await this.auth.signUp(username, password)
    return user
  }

  public async loginOrSignup(
    username: string,
    password: string
  ): Promise<[CognitoUser | null, CognitoUserSession | null]> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    try {
      const user: CognitoUser = await this.auth.signIn(username, password)
      return [user, user.getSignInUserSession()]
    } catch (e) {
      const err = e as Error
      if (err.name !== 'UserNotFoundException') throw e
      const { user } = await this.auth.signUp(username, password)
      if (user) {
        return [user, null]
      }
      return [user, null]
    }
  }

  // this can only be used on test pools with usernames that are autoconfirmed
  public async signupAndLogin(
    username: string,
    password: string
  ): Promise<[CognitoUser | null, CognitoUserSession | null]> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    const { user } = await this.auth.signUp(username, password)
    if (user) {
      return this.login(username, password)
    }
    return [null, null]
  }

  // this can only be used on test pools with usernames that are autoconfirmed
  public async loginOrSignupAndLogin(
    username: string,
    password: string
  ): Promise<[CognitoUser | null, CognitoUserSession | null]> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    try {
      const user: CognitoUser = await this.auth.signIn(username, password)
      return [user, user.getSignInUserSession()]
    } catch (e) {
      const err = e as Error
      if (err.name !== 'UserNotFoundException') throw e
      return this.signupAndLogin(username, password)
    }
  }

  public async getUserAttributes(user: CognitoUser): Promise<CognitoUserAttribute[] | undefined> {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((err, result) => {
        if (err) return reject(err)
        resolve(result)
      })
    })
  }

  public async getUserData(user: CognitoUser): Promise<UserData | undefined> {
    return new Promise((resolve, reject) => {
      user.getUserData((err, result) => {
        if (err) return reject(err)
        resolve(result)
      })
    })
  }

  public async getEmail(user: CognitoUser): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((err, result) => {
        if (err) return reject(err)
        if (result) {
          result.some((attrib) => {
            if (attrib.getName() === 'email') {
              resolve(attrib.getValue())
              return true
            }
            return false
          })
        }
        resolve(undefined)
      })
    })
  }

  public async getEmailVerified(user: CognitoUser): Promise<boolean> {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((err, result) => {
        if (err) return reject(err)
        if (result) {
          result.some((attrib) => {
            if (attrib.getName() === 'email_verified') {
              resolve(attrib.getValue() === 'true')
              return true
            }
            return false
          })
        }
        resolve(false)
      })
    })
  }

  public async getEmailWithVerified(user: CognitoUser): Promise<[string | undefined, boolean]> {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((err, result) => {
        if (err) return reject(err)
        let email: string | undefined = undefined
        let verified: boolean | undefined = undefined
        if (result) {
          result.some((attrib) => {
            switch (attrib.getName()) {
              case 'email':
                email = attrib.getValue()
                break
              case 'email_verified':
                verified = attrib.getValue() === 'true'
                break
            }
            return email !== undefined && verified !== undefined
          })
        }
        resolve([email, verified === true])
      })
    })
  }
  public async confirmSignup(userEmail: string, code: string): Promise<Result<any, Error>> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    try {
      const response: string = await this.auth.confirmSignUp(userEmail, code, {
        // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
        // For more context see here: https://github.com/aws-amplify/amplify-js/issues/1067
        forceAliasCreation: false,
      })
      return ok(response)
    } catch (e) {
      return err(e)
    }
  }
  // any is returned https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#forgotpassword
  public async forgotPassword(userEmail: string): Promise<Result<any, Error>> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    try {
      const response = await this.auth.forgotPassword(userEmail)
      return ok(response)
    } catch (e) {
      return err(e)
    }
  }
  // void is returned https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#forgotpassword
  public async forgotPasswordSubmit(userEmail: string, code: string, newPassword: string): Promise<Result<any, Error>> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    try {
      const response = await this.auth.forgotPasswordSubmit(userEmail, code, newPassword)
      return ok(response)
    } catch (e) {
      return err(e)
    }
  }

  // "SUCCESS" is returned https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#changepassword
  public async changePassword(oldPassword: string, newPassword: string): Promise<Result<any, Error>> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    try {
      const currentAuthenticatedUser = await this.auth.currentAuthenticatedUser()
      const response = await this.auth.changePassword(currentAuthenticatedUser, oldPassword, newPassword)
      return ok(response)
    } catch (e) {
      return err(e)
    }
  }

  //returns Promise<string> https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#resendsignup
  public async resendSignUp(username: string): Promise<Result<any, Error>> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    try {
      const response = await this.auth.resendSignUp(username)
      return ok(response)
    } catch (e) {
      return err(e)
    }
  }

  public async getCurrentAuthenticatedUser(): Promise<CognitoUser | any> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    try {
      const currentAuthenticatedUser = await this.auth.currentAuthenticatedUser({ bypassCache: true })
      return ok(currentAuthenticatedUser)
    } catch (e) {
      return err(e)
    }
  }

  public async getPreferredMFAType(user: CognitoUser): Promise<Result<any, Error>> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    try {
      const code = await this.auth.getPreferredMFA(user, { bypassCache: true })
      return ok(code)
    } catch (e) {
      return err(e)
    }
  }

  public async getTOTPCode(user: CognitoUser): Promise<Result<any, Error>> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    try {
      const code = await this.auth.setupTOTP(user)
      return ok(code)
    } catch (e) {
      return err(e)
    }
  }

  public async setPreferredMFA(user: CognitoUser, mfaType: string): Promise<Result<any, Error>> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    try {
      const response = await this.auth.setPreferredMFA(user, mfaType)
      return ok(response)
    } catch (e) {
      return err(e)
    }
  }

  public async verifyTotpToken(user: CognitoUser, challenge: string): Promise<Result<any, Error>> {
    if (!this.config.AWS_USER_POOL_ID) throw new Error('Cognito is not configured')
    try {
      const response = await this.auth.verifyTotpToken(user, challenge)
      return ok(response)
    } catch (e) {
      return err(e)
    }
  }
}

export { CognitoUser, CognitoUserSession }
