import { merge } from '../../util'

/**
 * UIntRules specifies unsigned integer value rules that may be
 * applied to a message as a field decorator.
 *
 * e.g. @validation(new UIntRules({ required: true, len: 9 }))
 *
 * Rules are defined by go-packages/schema/validate/uint.proto
 */
export class UIntRules {
  constructor(init: UIntRules) {
    if (init) merge(init, this)
  }
  public required = false
  public const?: number
  public min?: number
  public max?: number
  public minLen?: number
  public maxLen?: number
  public len?: number
  public bits?: number
  public in?: Array<number>
  public notIn?: Array<number>
  public pattern?: RegExp
}

const regUint = new RegExp('^[0-9]+$')

/**
 * isUInt will return whether a string value is an acceptable
 * value for an unsigned integer type field.
 *
 * @param value
 * returns boolean
 */
export function isUInt(value: string): boolean {
  return regUint.test(value)
}

/**
 * validateUIntValue will validate whether a field is a valid string
 * representation of an unsigned integer value, and that the value complies with
 * the field rules (if any). Returns an error message (string) or blank string.
 * @param value
 * @param rules
 * returns string
 */
export function validateUIntValue(value: string, rules?: UIntRules): string {
  if (!value && (!rules || !rules?.required)) return ''
  if (!rules) return ''

  if (!value && rules.required) return 'A value is required'
  if (rules.len && value.length !== rules.len) return `Required length is ${rules.len}`
  if (!isUInt(value)) return 'A positive integer value is required'
  if (rules.minLen && value.length < rules.minLen) return 'Value is too short'
  if (rules.maxLen && value.length > rules.maxLen) return 'Value is too long'
  if (rules.pattern && !rules.pattern.test(value)) return 'Value must match to the pattern'

  const uintVal = parseInt(value)
  if (rules.const && uintVal !== rules.const) return 'Value must be equal to constant ' + rules.const
  if (rules.in && rules.in.indexOf(uintVal) === -1) return 'Value does not exist in the required list'
  if (rules.notIn && rules.notIn.indexOf(uintVal) !== -1) return 'Value exist in the excluded list'
  if (rules.min && uintVal < rules.min) return 'Value must be greater than ' + rules.min
  if (rules.max && uintVal > rules.max) return 'Value must be less than ' + rules.max

  const bin = parseInt(uintVal.toString(2))
  if (rules.bits && bin !== rules.bits) return 'Value bit must be equal to ' + rules.bits
  return ''
}

/**
 * validateUIntInput will return whether the present string input can lead to a
 * valid unsigned integer value that conforms to the given rules
 *
 * @param input
 * @param rules
 * returns boolean
 */
export function validateUIntInput(input: string, rules?: UIntRules): boolean {
  if (input === '') return true
  if (!isUInt(input)) return false
  if (!rules) return true
  // rules not implemented
  return true
}
