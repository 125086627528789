import React, { useRef } from 'react'
import QRCode from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { TextInput } from '@/ApiReact'
import context from '../../util/localize'
import SuccessBlock from '../../container/usernotifications/success'

export type TwoFAProps = {
  challenge: string
  challengeError: string
  disableTwoFA: () => Promise<void>
  fetchCode: () => Promise<void>
  getMFASettings: () => Promise<void>
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  hasMFAEnabled: boolean
  setChallenge: (val: string) => void
  setShowMFASetup: (val: boolean) => void
  setTwoFASuccess: (val: boolean) => void
  setUserTwoFA: () => Promise<void>
  showMFASetup: boolean
  startMFAsetup: () => void
  twoFACode: string
  twoFASuccess: boolean
  username: string
}

export const TFA_TEST_IDS = {
  DISABLE_TFA_BUTTON: 'disable-tfa-button',
  SETUP_MFA: 'setup-mfa-button',
  ENABLE_MFA: 'enable-mfa-button',
  CANCEL_SETUP_MFA: 'cancel-setup-button',
}

const TwoFA = (TwoFAData: TwoFAProps): JSX.Element => {
  const code = TwoFAData.twoFACode
  const issuer = 'KGLD'
  const username = TwoFAData.username
  const str = 'otpauth://totp/Stably:' + username + '?secret=' + code + '&issuer=' + issuer
  const displayCopy = useRef() as React.MutableRefObject<HTMLSpanElement>

  const displayCopied = (): void => {
    displayCopy.current.style.display = 'inline'
    setTimeout(() => {
      displayCopy.current.style.display = 'none'
    }, 4000)
  }

  return (
    <React.Fragment>
      {TwoFAData.twoFASuccess ? (
        <SuccessBlock clearSuccessMethod={TwoFAData.setTwoFASuccess} successMsg="2FA settings updated successfully!" />
      ) : (
        <div className="twoFA-container">
          <div>
            <div className="title-med text-align-center text-uppercase">{context.manage2FA}</div>
          </div>
          {TwoFAData.hasMFAEnabled && TwoFAData.showMFASetup == false ? (
            <React.Fragment>
              <p className="text-align-center text-instruction">
                <span className="text-uppercase text-bold-imp">{context.important}: </span>
                {context.twoFAImp}
              </p>
              <div className="center-align-vertical-container">
                <button
                  className="button-primary"
                  onClick={TwoFAData.disableTwoFA}
                  data-testid={TFA_TEST_IDS.DISABLE_TFA_BUTTON}
                >
                  {context.disable2FA}
                </button>
              </div>
            </React.Fragment>
          ) : TwoFAData.showMFASetup ? (
            <React.Fragment>
              {code !== '' && username !== '' && (
                <>
                  <QRCode value={str} />
                  <p className="text-align-center text-bold-small" style={{ position: 'relative' }}>
                    Scan the above QR code
                    <sup title="You can use app like Authy, Duo Mobile, LastPass Authenticator, Microsoft Authenticator and Google Authenticator downloaded from the Apple store or Play store">
                      ?
                    </sup>{' '}
                    or{' '}
                    <CopyToClipboard text={TwoFAData.twoFACode} onCopy={displayCopied}>
                      <span className="link hovertext">
                        click here
                        <span className="hover-tooltip" ref={displayCopy}>
                          Copied!
                        </span>
                      </span>
                    </CopyToClipboard>{' '}
                    to copy the code and enter manually
                  </p>
                </>
              )}

              <TextInput
                name="challenge"
                onChange={TwoFAData.handleChange}
                type="text"
                value={TwoFAData.challenge}
                error={TwoFAData.challengeError}
                required={true}
                label="Enter Authenticator Code"
                autoCompleteOff={true}
              />
              <p className="text-align-center text-bold-small">
                <span className="text-uppercase text-bold-small">{context.important}:</span>
                {context.twoFAImp}
              </p>
              <div className="center-align-vertical-container">
                <button
                  className="button-primary"
                  onClick={TwoFAData.setUserTwoFA}
                  data-testid={TFA_TEST_IDS.ENABLE_MFA}
                >
                  {context.enable2FA}
                </button>
                <button
                  className="button-primary"
                  onClick={(): void => TwoFAData.setShowMFASetup(false)}
                  data-testid={TFA_TEST_IDS.CANCEL_SETUP_MFA}
                >
                  {context.cancel}
                </button>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p className="text-align-center text-instruction">
                <span className="text-uppercase text-bold-imp">{context.important}: </span>
                {context.twoFAMsg}
              </p>

              <button
                className="button-primary"
                onClick={(): void => TwoFAData.startMFAsetup()}
                data-testid={TFA_TEST_IDS.SETUP_MFA}
              >
                {context.setUpTwoFA}
              </button>
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default TwoFA
