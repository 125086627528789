import { merge } from '../../util'

export class BurnAddressReply {
  constructor(init?: BurnAddressReply) {
    if (init) merge(init, this)
  }

  public address? = ''
}

export class BurnAddressCountReply {
  constructor(init?: BurnAddressCountReply) {
    if (init) merge(init, this)
  }

  public count? = ''
}
