import React, { FormEvent } from 'react'
import { TextInput } from '@/ApiReact'
import { EthCryptoAccount, EthCryptoAccountErrors } from '@/Api'
import SuccessBlock from '../../container/usernotifications/success'
import context from '../../util/localize'

export type AddEthAccComponentProps = {
  handleChange: (e: FormEvent<HTMLInputElement>) => void
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  values: EthCryptoAccount
  errors: EthCryptoAccountErrors
  success: boolean
  setSuccess: (boolean) => void
}

export const ETH_ACC_TESTID = 'eth-acc-add-testid'

const AddEthCryptoAccount = ({
  handleChange,
  handleSubmit,
  values,
  errors,
  success,
  setSuccess,
}: AddEthAccComponentProps): JSX.Element => {
  return (
    <React.Fragment>
      {success ? (
        <SuccessBlock
          clearSuccessMethod={setSuccess}
          successMsg="Ethereum crypto account has been updated successfully!"
        />
      ) : (
        <div className="sub-card">
          <form onSubmit={handleSubmit} data-testid={ETH_ACC_TESTID}>
            <TextInput
              type="text"
              name="address"
              label="Ethereum Address"
              value={values.address}
              onChange={handleChange}
              error={errors.address || errors.address}
            />
            <div className="center-align-container">
              <button type="submit" className="button-primary">
                {context.submit}
              </button>
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  )
}

export default AddEthCryptoAccount
