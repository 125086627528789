import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Account from '../container/home'
import NavBar from '../navbar/authed'
import Settings from '../container/account'
import Footer from '../container/footer'
import Sandbox from '../sandbox'
import BankAccountContainer from '../container/BankAccount'
import EthereumAccountContainer from '../container/EthereumAccount'
import KYCTypeSelection from '../container/kyc/typeSelection'
import IndividualAccountContainer from '../container/kyc/IndividualAccount'
import Admin from '../container/admin/admin'
import InstitutionalAccountContainer from '../container/kyc/InstitutionalAccount'
import PriceCalculatorContainer from '../container/admin/pricecalculator'
import RouteConstants from './routeConstants'

const Routes: React.FC = () => {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path={RouteConstants.Login} component={Account} />
        <Route exact path={RouteConstants.KYCType} component={KYCTypeSelection} />
        <Route exact path={RouteConstants.KYCIndividual} component={IndividualAccountContainer} />
        <Route exact path={RouteConstants.KYCInstitution} component={InstitutionalAccountContainer} />
        <Route path={RouteConstants.Account} component={Account} />
        <Route path={RouteConstants.Home} component={Account} />
        <Route path={RouteConstants.Bank} component={BankAccountContainer} />
        <Route path={RouteConstants.Crypto} component={EthereumAccountContainer} />
        <Route path={RouteConstants.Settings} component={Settings} />
        <Route path={RouteConstants.Admin} component={Admin} />
        <Route path={RouteConstants.GoldPrice} component={PriceCalculatorContainer} />
        <Route path={RouteConstants.Sandbox} component={Sandbox} />
        <Redirect to={RouteConstants.Account} />
      </Switch>
      <Footer />
    </Router>
  )
}

export default Routes
