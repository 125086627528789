import createKeccakHash from 'keccak'

// eslint-disable-next-line no-useless-escape
const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function isEmail(value: string): boolean {
  return EmailRegex.test(value)
}

const HostNamePartRegex = new RegExp('^[a-z0-9-]+$')
export function isHostName(value: string): boolean {
  if (value.endsWith('.')) {
    value.substring(0, value.length - 1)
  }
  const host = value.toLowerCase()
  if (host.length > 254) {
    return false
  }

  const hostArr = host.split('.')
  for (const part of hostArr) {
    if (part.length === 0 || part.length > 63) {
      return false
    }
    if (part[0] === '-') {
      return false
    }
    if (part[part.length - 1] === '-') {
      return false
    }

    if (!HostNamePartRegex.test(part)) {
      return false
    }
  }

  return true
}

const IPAddressRegex = new RegExp(
  '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
)
export function isIP(value: string): boolean {
  if (!IPAddressRegex.test(value)) {
    return false
  }
  return true
}

const IPv4AddressRegex = new RegExp(
  '^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]).([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]).([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]).([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$'
)
export function isIPv4(value: string): boolean {
  if (!IPv4AddressRegex.test(value)) {
    return false
  }
  return true
}

const IPv6AddressRegex = new RegExp(
  '^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$'
)
export function isIPv6(value: string): boolean {
  if (!IPv6AddressRegex.test(value)) {
    return false
  }
  return true
}

const URIRegex = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
)

export function isURI(value: string): boolean {
  if (URIRegex.test(value) && (value.indexOf('://') > 0 || value.indexOf('//') === 0)) {
    return true
  }
  return false
}

export function isURIRef(value: string): boolean {
  if (!URIRegex.test(value)) {
    return false
  }
  return true
}

export function isInetAddress(value: string): boolean {
  if (!isHostName(value) && !isURIRef(value)) {
    return false
  }
  return true
}

const UUIDRegex = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')

export function isUUID(value: string): boolean {
  if (!UUIDRegex.test(value)) {
    return false
  }
  return true
}

const Countries = [
  'US',
  'AF',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BA',
  'BW',
  'BR',
  'VG',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CO',
  'KM',
  'CK',
  'CR',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'CD',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GN',
  'GW',
  'GY',
  'HT',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'CI',
  'JM',
  'JP',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'KP',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PL',
  'PT',
  'PR',
  'QA',
  'CG',
  'RE',
  'RO',
  'RU',
  'RW',
  'BL',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'KR',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'VI',
  'UY',
  'UZ',
  'VU',
  'VA',
  'VE',
  'VN',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
]
export function isCountry(value: string): boolean {
  return Countries.includes(value)
}

const CityNamePattern = new RegExp(`^(\\w+[-\\s,./]*){1,50}$`)
export function isAddressCity(value: string): boolean {
  if (!CityNamePattern.test(value)) {
    return false
  }
  return true
}

export function isAddressPostal(value: string): boolean {
  console.log(value)
  return true
}

export function isAddressRegion(value: string): boolean {
  console.log(value)
  return true
}

const AddressStreetRegex = new RegExp(`^([ -~]*){0,50}$`)
export function isAddressStreet(value: string): boolean {
  if (!AddressStreetRegex.test(value)) {
    return false
  }
  return true
}
export function isAddressStreet2(value: string): boolean {
  if (!AddressStreetRegex.test(value)) {
    return false
  }
  return true
}

const PhoneRegex = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$')
export function isPhone(value: string): boolean {
  if (!PhoneRegex.test(value)) {
    return false
  }
  return true
}

const NamePattern = new RegExp('^[a-zA-Z][^#&<>\\"~;$^%{}()?\\d]{1,20}$')
export function isFullName(value: string): boolean {
  if (!NamePattern.test(value)) {
    return false
  }
  return true
}

export function isFirstName(value: string): boolean {
  if (!NamePattern.test(value)) {
    return false
  }
  return true
}

export function isLastName(value: string): boolean {
  if (!NamePattern.test(value)) {
    return false
  }
  return true
}

const MiddleNameRegex = new RegExp('^(([A-Z][a-z]+)|([A-Z].?))$')
export function isMiddleName(value: string): boolean {
  if (!MiddleNameRegex.test(value)) {
    return false
  }
  return true
}

export const toChecksumAddress = (address: string): string => {
  address = address.toLowerCase().replace('0x', '')
  const hash = createKeccakHash('keccak256').update(address).digest('hex')
  let ret = '0x'

  for (let i = 0; i < address.length; i++) {
    if (parseInt(hash[i], 16) >= 8) {
      ret += address[i].toUpperCase()
    } else {
      ret += address[i]
    }
  }
  return ret
}

export function isEthAddress(address: string): boolean {
  const isNotValidLength = address.length !== 42
  if (isNotValidLength) {
    return false
  }
  const isNotAValidEthereumAddress = toChecksumAddress(address) !== address
  if (isNotAValidEthereumAddress) {
    return false
  }
  return true
}

export function isBnbAddress(value: string): boolean {
  // TODO: do bnb validation
  console.log(value)
  return true
}

export function isBtcAddress(value: string): boolean {
  // TODO: do bnb validation
  console.log(value)
  return true
}

const ABARoutingRegex = new RegExp('^[0-9]{9}$')
export function isAbaRoutingNumber(value: string): boolean {
  if (!ABARoutingRegex.test(value)) {
    return false
  }
  return true
}

const SwiftCodeRegex = new RegExp(
  '^[A-Z]{4}(AF|AL|DZ|AS|AD|AO|AI|AG|AR|AM|AW|AU|AT|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BA|BW|BR|VG|BN|BG|BF|BI|KH|CM|CA|CV|KY|CF|TD|CL|CN|CO|KM|CK|CR|HR|CU|CW|CY|CZ|DK|DJ|DM|DO|CD|EC|EG|SV|GQ|ER|EE|ET|FK|FO|FJ|FI|FR|GF|PF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GN|GW|GY|HT|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|CI|JM|JP|JO|KZ|KE|KI|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MK|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MN|ME|MS|MA|MZ|MM|NA|NR|NP|NL|NC|NZ|NI|NE|NG|NU|KP|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PL|PT|PR|QA|CG|RE|RO|RU|RW|BL|KN|LC|MF|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SX|SK|SI|SB|SO|ZA|KR|SS|ES|LK|SD|SR|SZ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|US|VI|UY|UZ|VU|VA|VE|VN|WF|EH|YE|ZM|ZW)[A-Z0-9]{2}([A-Z0-9]{3})?$'
)
export function isSwiftCodeNumber(value: string): boolean {
  if (!SwiftCodeRegex.test(value)) {
    return false
  }
  return true
}

export function isIbanNumber(value: string): boolean {
  // TODO: do iban validation
  console.log(value)
  return true
}
