import { Address } from '../common'
import { merge } from '../../util'
import { AddressRules, validation } from '../validation'

export class UserAddress {
  constructor(init?: UserAddress) {
    if (init) merge(init, this)
  }

  @validation(new AddressRules({ required: true }))
  public address = new Address()
}

export { UserAddress as UserAddressErrors }
