import { Api } from '@/Api'

const apiConfig = {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  AWS_REGION: process.env.REACT_APP_AWS_REGION || `us-west-2`,
  AWS_USER_POOL_ID: process.env.REACT_APP_AWS_USER_POOL_ID,
  AWS_USER_POOLS_WEB_CLIENT_ID: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  persistent: true,
  syncStorage: localStorage,
  STAGE: process.env.REACT_APP_STAGE,
}

//console.log(`apiConfig ${JSON.stringify(apiConfig, null, '\t')}`)
export const api = new Api(apiConfig)
