// @ts-nocheck
import React from 'react'
import { CustomTextInput, TextInput } from '@/ApiReact'
import countrystates from '../../constants/countrystates'
import Select from 'react-select'
import * as countries from '../../constants/countries.json'
// import { BLOCKED_COUNTRIES } from '../../constants/config'

export type ValueLabelPair = {
  value: string
  label: string
}

export const renderCountries = (): any => {
  const options = countries.data.map((country) => {
    return {
      value: `${country.iso2}`,
      label: `${country.flag} ${country.name}`,
    }
  })
  options.push({
    value: '',
    label: 'Choose country',
  })
  return options
}

const getCountry = (code: string): ValueLabelPair => {
  if (code === '') {
    return { value: '', label: 'Choose country' }
  } else {
    const countryList = countries.data
    const len = countryList.length
    let i
    for (i = 0; i < len; i++) {
      if (countryList[i].iso2 === code) {
        break
      }
    }

    return {
      value: `${countryList[i].iso2}`,
      label: `${countryList[i].flag} ${countryList[i].name}`,
    }
  }
}

export const hasStates = (countryCode): boolean => (countrystates[countryCode] ? true : false)

export const allowOther = (countryCode): any =>
  countrystates[countryCode] && countrystates[countryCode].allowOther ? true : false

export const renderStates = (countryCode): ValueLabelPair | null => {
  if (!hasStates(countryCode)) return null
  const states = countrystates[countryCode].options.map((state) => {
    return {
      value: `${state.code}`,
      label: `${state.name}`,
    }
  })
  if (allowOther(countryCode)) {
    states.push({ value: 'other', label: 'Other' })
  }
  states.push({ value: '', label: 'Choose state' })
  return states
}

const getState = (countryCode: string, stateCode: string): ValueLabelPair => {
  if (hasStates(countryCode)) {
    if (stateCode === '') {
      return { value: '', label: 'Choose state' }
    }

    const stateList = countrystates[countryCode].options
    const len = stateList.length
    let i
    for (i = 0; i < len; i++) {
      if (stateList[i].code === stateCode) {
        break
      }
    }
    return {
      value: `${stateList[i].code}`,
      label: `${stateList[i].name}`,
    }
  } else {
    return { value: '', label: 'Choose state' }
  }
}

export const SelectCountries = ({ error, name, label, value, handleCountryChange, showAsterisk }: any): JSX.Element => {
  return (
    <CustomTextInput label={label} name={name} id={name} error={error} showAsterisk={showAsterisk}>
      <Select
        name={name}
        id={name}
        onChange={handleCountryChange}
        value={getCountry(value)}
        options={renderCountries()}
        isOptionDisabled={(value): any => value.value === '' || !!countries.restricted[value.value]}
      />
    </CustomTextInput>
  )
}

export const SelectState = ({
  country,
  error,
  name,
  label,
  value,
  handleChange,
  handleStateChange,
  showAsterisk,
}: any): any => {
  return (
    <React.Fragment>
      {hasStates(country) ? (
        <CustomTextInput label={label} name={name} showAsterisk={showAsterisk} error={error}>
          <Select
            name={name}
            id={name}
            onChange={handleStateChange}
            value={getState(country, value)}
            options={renderStates(country)}
            isOptionDisabled={(value): any => value.value === ''}
          />
        </CustomTextInput>
      ) : (
        <TextInput
          type="text"
          label={label}
          name={name}
          value={value}
          error={error}
          onChange={handleChange}
          showAsterisk={showAsterisk}
        />
      )}
    </React.Fragment>
  )
}

export default {
  hasStates,
  allowOther,
  renderCountries,
  renderStates,
}
