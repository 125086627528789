import { api } from '@/ApiReact'
import {
  SET_ERROR,
  SET_LOADING,
  SET_APPLIED_FOR_VERIFICATION,
  SET_VERIFICATION_APPLIED,
  CLEAR_STATE,
} from '../actionTypes'
import error from './errorAction'

const account = {
  FETCH_VERIFICATION_STATUS: (): any => {
    return async (dispatch): Promise<any> => {
      try {
        const response = await api.firstdigital.appliedForVerification()
        if (!response) {
          dispatch(error.SET_ERROR('Failed to fetch verification status'))
        } else {
          let payload = {}
          payload = Object.assign({}, payload, {
            accountStatus: response.accountStatus,
            appliedForVerification: response.appliedForVerification,
            wireReference: response.wireReference,
            redemptionAddress: response.redemptionAddress,
          })

          return dispatch({
            type: SET_APPLIED_FOR_VERIFICATION,
            payload,
          })
        }
      } catch (e) {
        dispatch(error.SET_ERROR(e))
      }
    }
  },
  APPLIED_FOR_VERIFICATION: (val: boolean): any => {
    let payload = {}
    payload = Object.assign({}, payload, { accountStatus: 'disabled', appliedForVerification: val })
    return {
      type: SET_VERIFICATION_APPLIED,
      payload,
    }
  },
  LOADING: (isLoading: boolean): any => {
    let payload = {}
    payload = Object.assign({}, payload, { isLoading })
    return {
      type: SET_LOADING,
      payload,
    }
  },
  CLEAR_STATE: (): any => {
    let payload = {}
    payload = Object.assign({}, payload, { appliedForVerification: false })
    return {
      type: CLEAR_STATE,
      payload,
    }
  },
  ERROR: (errormsg: string): any => {
    let payload = {}
    return (dispatch): any => {
      dispatch(error.SET_ERROR(errormsg))
      payload = Object.assign({}, payload, {
        error: errormsg,
      })
      dispatch({
        type: SET_ERROR,
        payload,
      })
    }
  },
}

export default account
