import { useEffect, useState } from 'react'
import { useAuthContext } from '../context/auth'
import { FetchType } from '@/Api'

export function useFetch<T extends FetchType<T['message']>>(
  action: T
): {
  error: string
  values: T['message']
} {
  const auth = useAuthContext()
  const [values, dispatchValues] = useState(new action.messageType())
  const [error, setError] = useState('')

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const initialValues = async (): Promise<void> => {
      if (!action.getUrl) return
      try {
        const getValues = await auth.api().get(action.getUrl)
        dispatchValues(new action.messageType(getValues))
      } catch (e) {
        setError(e.message)
      }
    }
    if (action.getUrl) {
      initialValues()
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return { error, values }
}

export function useFetchWithCallback<T extends FetchType<T['message']>>(
  action: T,
  callback: (error: string, values: T['message']) => void
): {
  error: string
  values: T['message']
} {
  const auth = useAuthContext()
  const [values, dispatchValues] = useState(new action.messageType())
  const [error, setError] = useState('')

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const initialValues = async (): Promise<void> => {
      if (!action.getUrl) return
      try {
        const getValues = await auth.api().get(action.getUrl)
        // console.log(`got ${JSON.stringify(getValues)}`)

        const val = new action.messageType(getValues)
        dispatchValues(val)
        callback('', val)
      } catch (e) {
        setError(e.message)
        callback(e.message, null)
      }
    }
    if (action.getUrl) {
      initialValues()
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return { error, values }
}
