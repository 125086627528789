import { ActionType } from '../action'
import { IndividualAccount, IndividualAccountErrors } from '../../messages/firstdigital'

export class ActionIndividualAccount implements ActionType<IndividualAccount, IndividualAccountErrors> {
  readonly authRequired = true
  readonly setUrl = '/account/individual/open'
  public message = new IndividualAccount()
  public errors = new IndividualAccountErrors()
  public messageType = IndividualAccount
  public errorsType = IndividualAccountErrors
}
