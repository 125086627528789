import styled from 'styled-components'

const TermsOfServiceWrapper = styled.div`
  .coverup-container {
    position: fixed;
    content: '';
    width: 100vw;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #00000040;
    z-index: 99;
  }

  .terms-of-service-card {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 2rem;
    max-width: 40rem;
    min-height: 22rem;
    transform: translate(-50%, -50%);
    background: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  p {
    font-size: 1.2rem;
    margin: 0;
    text-align: center;
  }

  p:nth-child(3) {
    margin-top: 0.5rem;
  }

  .button-primary {
    margin: 1.25rem auto;
  }

  @media (max-width: 992px) {
    .terms-of-service-card {
      width: 22rem;
      min-height: 25rem;
    }

    .button-primary {
      max-width: 8rem;
    }
  }
`
export default TermsOfServiceWrapper
