import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Admin from '../../components/admin'
import { ContainerRouterProps } from '../../interface'
import ErrorAlert from '../usernotifications/erroralert'
import LoadingBlock from '../usernotifications/loading'
import { AddressFormStateType } from './admin'

interface RedemptionAddrProps extends ContainerRouterProps {
  AddrFormState: AddressFormStateType
}

const RedmptionAddrContainer: React.FC<RedemptionAddrProps> = (props: RedemptionAddrProps) => {
  const [generalError, setGeneralError] = useState('')
  const AddrFormState = props.AddrFormState
  useEffect(() => {
    AddrFormState.getEmail()
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      {AddrFormState.isLoading ? (
        <LoadingBlock clearLoadingMethod={AddrFormState.setLoading} setTimeoutErrorMethod={setGeneralError} />
      ) : null}
      <div className="card-small">
        <p className="text-align-center uppercase text-bold">Add redemption addresses for new users</p>

        <Admin
          errors={AddrFormState.errors}
          handleChange={AddrFormState.handleChange}
          handleSubmit={AddrFormState.handleSubmit}
          showContent={AddrFormState.showContent}
          setSuccess={AddrFormState.setSuccess}
          success={AddrFormState.success}
          values={AddrFormState.values}
        />
      </div>
    </div>
  )
}

export default withRouter(RedmptionAddrContainer)
