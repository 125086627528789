import { merge } from '../../util'
import moment from 'moment'

export class DateTimeRules {
  constructor(init: DateTimeRules) {
    if (init) merge(init, this)
  }
  public required = false
  public future?: boolean
  public past?: boolean
  public format? = 'YYYY-MM-DDTHH:mm:ssZ'
}

export function validateDateTimeInput(input: string, rules?: DateTimeRules): boolean {
  if (input === '') return true
  if (!rules) return true
  return true
}

export function validateDateTimeValue(value: string, rules?: DateTimeRules): string {
  if (!rules) return ''
  if ((!rules || !rules?.required) && !value) return ''
  if (rules.required && !value) return 'A value is required'

  const thatDateTime = moment(value, rules.format, true)
  if (!thatDateTime.isValid()) {
    return `Value is an invalid date with format ${rules.format}`
  }

  const thisMoment = moment()

  if (rules.future && thatDateTime.isBefore(thisMoment)) {
    return 'Value must be a datetime in the future, got ' + value
  }
  if (rules.past && thatDateTime.isAfter(thisMoment)) {
    return 'Value must be a datetime in the past, got ' + value
  }
  return ''
}
