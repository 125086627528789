//TODO update values for all below constants

// emails which can take role of admin
export const VALID_ADMIN_EMAILS: string[] = ['']

// source of signup
export const SOURCE = 'kgld-platform-signup'

// document agreed on signup
export const DOCUMENT_TITLE = 'termsofservice20203108'

// new terms of service will be applicable from date
export const DOCUMENT_UPDATE_DATE = '10-10-2020'

// document agreed on signup with version
export const DOCUMENT_VERSION = '1.0'

// show language selector to user
export const DISABLE_LOCALIZATION = process.env.REACT_APP_STAGE === 'local' ? true : false

export const FEE_URL = 'https://support.kitco.gold/hc/en-us/articles/4402653617043-Fee-schedule'

export const KITCO_ACCOUNT_SETTINGS =
  'https://support.kitco.gold/hc/en-us/articles/1500010855941-How-to-update-your-Kitco-Gold-Account-'

export const KITCO_FAQ_URL = 'https://support.kitco.gold/hc/en-us/sections/360009956254--Frequently-Asked-Questions'

export const KITCO_GOLD_URL = 'https://kitco.gold'

export const KITCO_HELP_URL =
  'https://support.kitco.gold/hc/en-us/articles/1500010824882-Inquiries-regarding-your-Kitco-Gold-account'

export const KITCO_PRIVACY_POLICY =
  'https://support.kitco.gold/hc/en-us/articles/4408113228563-Kitco-Digital-Metals-Group-Inc-Privacy-Policy'

export const KITCO_TERMS_AND_CONDITIONS =
  'https://support.kitco.gold/hc/en-us/articles/4408107281299-KGLD-User-Precious-Metals-Storage-Agreement-Terms-Conditions'

export const TRANSACTION_HISTORY_URL =
  'https://support.kitco.gold/hc/en-us/articles/360054297074-How-do-I-view-my-Kitco-Gold-holdings-'

export const SUPPORT_URL = 'https://support.kitco.gold/hc/en-us/sections/360009956254--Frequently-Asked-Questions'

export const SUPPORT_EMAIL = 'support@kitco.gold'

// stably privacy policy
export const STABLY_PRIVACY_POLICY = 'https://www.stably.io/privacy-policy/'
// stably terms of service
export const STABLY_TERMS_OF_SERVICE = 'https://www.stably.io/terms-of-service/'

// moving to ts/kgld/Web/src/constants/countries.json for better maintainability
// export const BLOCKED_COUNTRIES: string[] = [
//   'AF', 'AL', 'BY', 'BA', 'CF', 'HR', // 'CA', Enabling CA for now to allow Kitco team to onboard some members 'CU', 'ER', 'GW', 'HK', 'IR', 'XK', 'LB', 'ML', 'MK', 'ME', 'NI', 'KP', 'PK', 'PS', 'RS', 'SG', 'SY', 'TN', 'US',
// ]

// not present in our list
// Congo, Democratic Republic of the
// Cote d'Ivoire
// Democratic People’s Republic of Korea [sanctions]
// Iraq
// North Macedonia ??? MK
// Myanmar
// Somalia
// South Sudan
// Sudan
// Yemen
// Zimbabwe
