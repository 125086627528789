import { Api } from '../index'
import {
  HealthCheckReply,
  ServiceReply,
  UserAccountGet,
  UserAccountGetReply,
  UserAccountSet,
  UserCountry,
  UserSignUpTermsAgreement,
  VerificationReply,
} from '../../messages'
import { DOCUMENT_TITLE, DOCUMENT_VERSION, SOURCE } from '../../../../Web/src/constants/config'
import { Result } from 'neverthrow'

//TODO upgrade to neverthrow model instead of using _unsafeUnwrap
export class FirstDigitalApi {
  constructor(readonly api: Api) {}

  public async healthCheck(): Promise<HealthCheckReply | undefined> {
    return (await this.api.httpGet('/healthcheck')) as HealthCheckReply
  }

  public async authCheck(): Promise<HealthCheckReply | undefined> {
    return (await this.api.get('/authcheck')) as HealthCheckReply
  }

  public async userCountry(): Promise<UserCountry> {
    return (await this.api.get('/detect/country')) as UserCountry
  }

  public async appliedForVerification(): Promise<VerificationReply> {
    const value = await this.api.get('/verification/status')
    // return value && value.appliedForVerification ? true : false
    return value as VerificationReply
  }

  public async getGoldPrice(): Promise<any> {
    const response = await this.api.get('/gold-price')
    return response
  }

  public async setUserAccountStatus(msg: UserAccountSet): Promise<Result<ServiceReply, Error>> {
    const response = await this.api.set('/account/set-status', msg)
    return response
  }

  public async getUserAccountStatus(msg: UserAccountGet): Promise<Result<UserAccountGetReply, Error>> {
    const response = await this.api.set('/account/get', msg)
    return response
  }

  public async setSignUpTermsAgreement(): Promise<UserSignUpTermsAgreement | undefined> {
    const resp = await this.api.set(`/agreement-accept`, {
      source: SOURCE,
      document: DOCUMENT_TITLE,
      version: DOCUMENT_VERSION,
    })

    if (resp.isErr()) {
      return undefined
    }

    return (resp._unsafeUnwrap() as unknown) as UserSignUpTermsAgreement
  }

  public async getTermsAgreementRecord(): Promise<any> {
    const resp = await this.api.get(`/get-agreement-record`)

    return resp
  }
}
