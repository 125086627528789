import styled from 'styled-components'

const KYCSelection = styled.div`
  min-height: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .button-primary {
    margin: 10px auto;
    max-width: 100%;
  }

  img {
    width: 100px;
    height: 100px;
    margin: auto;
  }

  @media screen and (max-width: 62rem) {
  }
`

const Card = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  background-color: var(--white);
  margin: 2rem;
  padding: 2rem;

  @media screen and (max-width: 62rem) {
    width: 80%;
    margin: 2rem auto;
  }
`

const Desc = styled.div`
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  margin: 1rem auto;
`

const H1 = styled.h1`
  text-align: center;
  margin: auto;
`

const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PageTitle = styled.p`
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
`

const Title = styled.div`
  font-size: 1.5rem;
  text-align: center;
  color: var(--kitco-yellow);
  font-weight: 600;
  text-transform: uppercase;
`

const Wrapper = styled.div`
  display: flex;
  margin: auto;
  flex-direction: row;
  justify-content: space-between;
  max-width: 50rem;

  @media screen and (max-width: 62rem) {
    flex-direction: column;
  }
`

KYCSelection.Card = Card
KYCSelection.Desc = Desc
KYCSelection.H1 = H1
KYCSelection.Image = Image
KYCSelection.PageTitle = PageTitle
KYCSelection.Title = Title
KYCSelection.Wrapper = Wrapper
export default KYCSelection
