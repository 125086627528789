import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { ContainerRouterProps } from '../../interface'
import ErrorAlert from '../usernotifications/erroralert'
import { AddressFormStateType, UserListType } from './admin'
import context from '../../util/localize'
import { UserAccount } from 'Api/src/messages'
import { api, TextInput } from '@/ApiReact'

interface UserListProps extends ContainerRouterProps {
  AddrFormState: AddressFormStateType
  UserListState: UserListType
}

const noOfCols = 7
const rejectedStatus = 'rejected'

const UserListContainer: React.FC<UserListProps> = (props: UserListProps) => {
  const [generalError, setGeneralError] = useState('')
  const [count, setCount] = useState(0)
  const UserListState = props.UserListState
  const AddrFormState = props.AddrFormState

  const rejectApplication = async (e: React.MouseEvent, acc: UserAccount, singleRecord?: boolean): Promise<void> => {
    e.preventDefault()
    try {
      const confirmPrompt = confirm(
        `Do you want to reject the application for user with email ${acc.email}? This process is not reversible`
      )

      if (!confirmPrompt) {
        return
      }

      UserListState.setLoading(true)
      const response = await api.firstdigital.setUserAccountStatus({
        userGuid: acc.userGuid,
        email: acc.email,
        enableAccount: false,
        rejectAccount: true,
      })
      if (response.isOk()) {
        if (response.value.success) {
          if (singleRecord) {
            const newAccount = UserListState.account
            newAccount.accountEnabled = !acc.accountEnabled
            newAccount.accountStatus = rejectedStatus
            UserListState.setAccount(newAccount)
          } else {
            const accIndex = UserListState.accounts.indexOf(acc)
            const accs = UserListState.accounts
            accs[accIndex].accountEnabled = !acc.accountEnabled
            accs[accIndex].accountStatus = rejectedStatus
            UserListState.setTableValues(accs)
          }
          setCount(count + 1)
        } else {
          setGeneralError(JSON.stringify(response.value.error) || JSON.stringify(response.value.errors))
        }
      } else {
        setGeneralError('Failed to update user status')
      }
      UserListState.setLoading(false)
    } catch (e) {
      UserListState.setLoading(false)
      setGeneralError(`Failed to update user status` + JSON.stringify(e))
    }
  }

  const changeUserStatus = async (e: React.MouseEvent, acc: UserAccount, singleRecord?: boolean): Promise<void> => {
    e.preventDefault()
    UserListState.setLoading(true)
    try {
      const response = await api.firstdigital.setUserAccountStatus({
        userGuid: acc.userGuid,
        email: acc.email,
        enableAccount: !acc.accountEnabled,
      })
      if (response.isOk()) {
        if (response.value.success) {
          if (singleRecord) {
            const newAccount = UserListState.account
            newAccount.accountEnabled = !acc.accountEnabled
            UserListState.setAccount(newAccount)
          } else {
            const accIndex = UserListState.accounts.indexOf(acc)
            const accs = UserListState.accounts
            accs[accIndex].accountEnabled = !acc.accountEnabled
          }
          setCount(count + 1)
        } else {
          setGeneralError(JSON.stringify(response.value.error) || JSON.stringify(response.value.errors))
        }
      } else {
        setGeneralError('Failed to update user status')
      }
      UserListState.setLoading(false)
    } catch (e) {
      UserListState.setLoading(false)
      setGeneralError(`Failed to update user status` + JSON.stringify(e))
    }
  }

  const renderSearchedUser = (acc: UserAccount): any => {
    if (acc.userGuid !== '') {
      return (
        <div className="table-wrapper">
          <table className="border user-table">
            <tbody>
              <tr>
                <th>{context.srno}</th>
                <th>{context.userguid}</th>
                <th>{context.userEmail}</th>
                <th>{context.userAccountStatus}</th>
                <th>{context.wireReferenceAssigned}</th>
                <th>{context.redemptionAddressAssigned}</th>
              </tr>
              <tr key="user-search">
                <td>1</td>
                <td>{acc.userGuid}</td>
                <td>{acc.email}</td>
                {acc.accountStatus === rejectedStatus ? (
                  <td colSpan={4}>
                    <p>Account is rejected</p>
                  </td>
                ) : (
                  <>
                    <td>
                      <button
                        onClick={(e): Promise<void> => changeUserStatus(e, acc, true)}
                        className={acc.accountEnabled ? 'enabled' : 'disabled'}
                      >
                        {acc.accountEnabled ? 'Active' : 'disabled'}
                      </button>
                    </td>
                    <td>{acc.wireReferenceAssigned ? acc.wireReference : 'pending'}</td>
                    <td>{acc.redemptionAddressAssigned ? acc.redemptionAddress : 'pending'}</td>
                    <td>
                      <button
                        onClick={(e): Promise<void> => rejectApplication(e, acc, true)}
                        style={{ borderRadius: '0.5px' }}
                      >
                        Reject
                      </button>
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      )
    } else {
      return <p>{context.noUserFound}</p>
    }
  }

  const renderTableData = (): any => {
    const acc = UserListState.accounts.slice(0, UserListState.displayCount)
    if (acc.length > 0) {
      const rows = acc.map((acc, i) => {
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{acc.userGuid}</td>
            <td>{acc.email}</td>
            {acc.accountStatus === rejectedStatus ? (
              <td colSpan={4}>
                <p>Account is rejected</p>
              </td>
            ) : (
              <>
                <td>
                  <button
                    onClick={(e): Promise<void> => changeUserStatus(e, acc)}
                    className={acc.accountEnabled ? 'enabled' : 'disabled'}
                  >
                    {acc.accountEnabled ? 'Active' : 'disabled'}
                  </button>
                </td>
                <td>{acc.wireReferenceAssigned ? acc.wireReference : 'pending'}</td>
                <td>{acc.redemptionAddressAssigned ? acc.redemptionAddress : 'pending'}</td>
                <td>
                  <button onClick={(e): Promise<void> => rejectApplication(e, acc)} className="reject">
                    Reject
                  </button>
                </td>
              </>
            )}
          </tr>
        )
      })
      if (UserListState.displayCount < UserListState.rowLength) {
        rows.push(
          <tr key="load">
            <td colSpan={noOfCols}>
              <button onClick={UserListState.loadMore}>{context.loadMore}</button>
            </td>
          </tr>
        )
      }
      return rows
    } else {
      return (
        <tr>
          <td rowSpan={noOfCols}>No records available</td>
        </tr>
      )
    }
  }

  useEffect(() => {
    AddrFormState.getEmail()
    // eslint-disable-next-line
  }, [UserListState.accounts])

  return (
    <div>
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      <div className="card-small">
        <p className="text-align-center uppercase text-bold">{context.changeStatusForUser}</p>
        <p className="text-align-center">
          A new wire reference and redemption address will be assigned when the user logs in again (if not allocated
          previously)
        </p>
        <div className="flex-row">
          <div className="col-6">
            <TextInput
              name="username"
              type="text"
              label="USER GUID"
              required={true}
              onChange={UserListState.handleInputChange}
              value={UserListState.userguid}
              error={UserListState.error}
              autoCompleteOff={true}
            />
          </div>
          <div className="col-6 align-vertical">
            <button onClick={UserListState.searchUser} disabled={!UserListState.enableSearch}>
              Search
            </button>
            <button onClick={UserListState.clearUser}>Clear</button>
          </div>
        </div>
        {UserListState.account.userGuid && UserListState.account.userGuid !== '' ? (
          renderSearchedUser(UserListState.account)
        ) : (
          <div className="table-wrapper">
            <table className="border user-table">
              <tbody>
                <tr>
                  <th>{context.srno}</th>
                  <th>{context.userguid}</th>
                  <th>{context.userEmail}</th>
                  <th>{context.userAccountStatus}</th>
                  <th>{context.wireReferenceAssigned}</th>
                  <th>{context.redemptionAddressAssigned}</th>
                  <th>{context.rejectApplication}</th>
                </tr>
                {renderTableData()}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default withRouter(UserListContainer)
