import React, { useEffect, useState } from 'react'
import { useHistory, withRouter, Link } from 'react-router-dom'
import Navbar from '../blocks/navbar'
import HamburgerMenu from 'react-hamburger-menu'
import LanguageContainer from '../container/language'
import context from '../util/localize'
import { colors } from '../util/styles'
import RouteConstants from '../routes/routeConstants'
import { KITCO_GOLD_URL } from '../constants/config'

const Public = (): any => {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = (): any => {
    setIsOpen(!isOpen)
  }

  const closeMenu = (): any => {
    setIsOpen(false)
  }

  const handleNavbar = (): void => {
    const path = window.location.pathname
    document.getElementById('login')?.classList.remove('active')
    document.getElementById('signup')?.classList.remove('active')
    switch (path) {
      case RouteConstants.Login:
        document.getElementById('login')?.classList.add('active')
        break
      case RouteConstants.Signup:
        document.getElementById('signup')?.classList.add('active')
        break
    }
  }

  useEffect(() => {
    handleNavbar()
    // eslint-disable-next-line
  }, [history.location.pathname])

  return (
    <React.Fragment>
      {/* <Navbar.ReplacementBlock /> */}
      <Navbar isopen={isOpen}>
        <Navbar.Group withLogo={true}>
          <Navbar.Dropdown>
            <HamburgerMenu
              isOpen={isOpen}
              menuClicked={toggleMenu}
              width={18}
              height={15}
              strokeWidth={1}
              rotate={0}
              color={colors.black}
              borderRadius={0}
              animationDuration={0.5}
            />
            <Navbar.Group forDesktop={true} isMenu={true} isOpen={isOpen}>
              <Link to={RouteConstants.Login}>
                <Navbar.Item forDesktop={true} onClick={closeMenu}>
                  {context.login}
                </Navbar.Item>
              </Link>
              <Link to={RouteConstants.Signup}>
                <Navbar.Item forDesktop={true} onClick={closeMenu}>
                  {context.signup}
                </Navbar.Item>
              </Link>
            </Navbar.Group>
            <Navbar.MenuBg isOpen={isOpen} />
          </Navbar.Dropdown>
          <Navbar.Logo>
            <a href={KITCO_GOLD_URL} onClick={closeMenu}>
              <img src="/images/KitcoGold-Logo.png" alt="KitcoGold logo" style={{ width: '155px' }} />
            </a>
          </Navbar.Logo>
        </Navbar.Group>

        <Navbar.Group hasNavItems={true} isOpen={isOpen}>
          <>
            <Link to={RouteConstants.Login} id="login" onClick={closeMenu}>
              <Navbar.Item>{context.login}</Navbar.Item>
            </Link>
            <Link to={RouteConstants.Signup} id="signup" onClick={closeMenu}>
              <Navbar.Item>{context.createAccount}</Navbar.Item>
            </Link>
          </>
        </Navbar.Group>
        <Navbar.Group isLangSelector={true}>
          <LanguageContainer />
        </Navbar.Group>
      </Navbar>
    </React.Fragment>
  )
}
export default withRouter(Public)
