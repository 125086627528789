import React from 'react'
import context from '../../util/localize'
import { TextInput } from '@/ApiReact'

const Reset = ({ emailRef, codeRef, errors, handleChange, handleSubmit, values }: any): any => {
  return (
    <React.Fragment>
      {emailRef === '' || codeRef === '' ? (
        <p className="text-align-center text-bold bottom-margin-2">{context.resetPasswordMessage}</p>
      ) : (
        <p className="text-align-center text-bold bottom-margin-2">{context.enterNewPassword}</p>
      )}

      <form onSubmit={handleSubmit}>
        {emailRef === '' && (
          <TextInput
            name="email"
            onChange={handleChange}
            placeholder={context.email}
            type="email"
            value={values.email || ''}
            error={errors.email}
            required={true}
            label={context.email}
          />
        )}
        {codeRef === '' && (
          <TextInput
            name="code"
            onChange={handleChange}
            placeholder={context.code}
            type="text"
            value={values.code || ''}
            error={errors.code}
            required={true}
            label={context.code}
          />
        )}

        <TextInput
          name="newPassword"
          onChange={handleChange}
          placeholder={context.newPassword}
          type="password"
          value={values.newPassword || ''}
          error={errors.newPassword}
          required={true}
          label={context.newPassword}
        />

        <TextInput
          name="confirmNewPassword"
          onChange={handleChange}
          placeholder={context.confirmNewPassword}
          type="password"
          value={values.confirmNewPassword || ''}
          error={errors.confirmNewPassword}
          required={true}
          label={context.confirmNewPassword}
        />

        <div className="center-align-container">
          <button className="button-primary" type="submit">
            {context.submit}
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Reset
