import React from 'react'
import ChangePassword, { ChangePasswordProps } from './changepassword'
import TwoFA, { TwoFAProps } from './twoFA'
import UpdateInfo from './updateinfo'

export type AccountProps = {
  password: ChangePasswordProps
  twoFAData: TwoFAProps
}

const Account = ({ password, twoFAData }: AccountProps): JSX.Element => {
  return (
    <React.Fragment>
      <div className="home-container">
        <div className="account-wrapper">
          <div className="account-card-4">
            <div className="account-card-content">
              <UpdateInfo />
            </div>
          </div>
          <div className="account-card-4">
            <div className="account-card-content">
              <ChangePassword {...password} />
            </div>
          </div>
          <div className="account-card-4">
            <div className="account-card-content">
              <TwoFA {...twoFAData} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Account
