import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { api, useAuthContext, useFetchWithCallback, useFormStateAction } from '@/ApiReact'
import { ActionAddBurnAddresses, isEthAddress } from '@/Api'
import RedemptionAddrContainer from './redemptionaddr'
import UserListContainer from './userlist'
import { ContainerRouterProps } from '../../interface'
import AdminComponent from '../../blocks/admin'
import ErrorAlert from '../usernotifications/erroralert'
import LoadingBlock from '../usernotifications/loading'
import { AddRedemptionAddresses, AddRedemptionAddressesErrors, UserAccount } from 'Api/src/messages'
import { FetchUserAccounts } from 'Api/src/services/firstdigital/user_accounts'
import { checkAdminEmail, convertTimestampToLocalString } from '../../util/helpers'
import RouteConstants from '../../routes/routeConstants'

export interface AddressFormStateType {
  values: AddRedemptionAddresses
  setValues: React.Dispatch<any>
  errors: AddRedemptionAddressesErrors
  setErrors: React.Dispatch<any>
  getEmail: () => void
  handleChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
  isLoading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
  success: boolean
  showContent: boolean
}
export interface UserListType {
  account: UserAccount
  accounts: UserAccount[]
  clearUser: () => void
  displayCount: number
  enableSearch: boolean
  error: string
  handleInputChange: (e: React.FormEvent<HTMLInputElement>) => void
  isLoading: boolean
  loadMore: () => void
  rowLength: number
  searchUser: () => void
  setAccount: React.Dispatch<React.SetStateAction<UserAccount>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setRowLength: React.Dispatch<React.SetStateAction<number>>
  setTableValues: React.Dispatch<React.SetStateAction<UserAccount[]>>
  userguid: string
}

const AdminContainer: React.FC<ContainerRouterProps> = (props: ContainerRouterProps) => {
  const auth = useAuthContext()
  const [generalError, setGeneralError] = useState('')
  const [isPageOne, setPageToggle] = useState(true)
  const [livePrice, setLivePrice] = useState({ askPrice: '0.0', bidPrice: '0.0' })
  const [priceDate, setPriceDate] = useState('')

  const useAddAddressFormState = (): AddressFormStateType => {
    const [isLoading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)
    const [showContent, setShowContent] = useState(false)
    const AddBurnAddress = new ActionAddBurnAddresses()
    const { values, setValues, errors, setErrors, handleChange } = useFormStateAction(AddBurnAddress)

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault()
      const addresses = values.addressList
        .replace(/(^\n*)|(\n*$)/g, '')
        .replace(/(\n){1,}/g, '\n')
        .split('\n')
      setValues({ ...values, addresses })
      setLoading(true)
      for (let i = 0; i < addresses.length; i++) {
        const valid = isEthAddress(addresses[0])
        if (!valid) {
          setLoading(false)
          setErrors({ addressList: `invalid address at index ${i + 1}` })
          return
        }
      }
      const response = await auth.api().set(AddBurnAddress.setUrl, { addresses })
      if (response.isOk()) {
        if (response.value.errors) {
          setLoading(false)
          setErrors(response.value.errors)
        } else {
          if (response.value.success) {
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          } else {
            setLoading(false)
            setGeneralError(JSON.stringify(response.value.error))
          }
        }
      } else {
        setLoading(false)
        setGeneralError(JSON.stringify(response.error))
        return
      }
    }

    const getEmail = async (): Promise<void> => {
      try {
        const response = await auth.getCurrentAuthenticatedUser()

        if (response.isOk()) {
          const str = response.value.attributes.email
          const isValid = checkAdminEmail(str)
          if (isValid) {
            setShowContent(true)
          } else {
            setShowContent(false)
            props.history.push(RouteConstants.Home)
          }
        }
        setLoading(false)
      } catch (err) {
        setGeneralError(JSON.stringify(err))
      }
    }

    const AddrFormState = {
      values: values,
      setValues: setValues,
      errors: errors,
      setErrors: setErrors,
      getEmail: getEmail,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      isLoading: isLoading,
      setLoading: setLoading,
      setSuccess: setSuccess,
      success: success,
      showContent: showContent,
    }

    return AddrFormState
  }

  const useUserListState = (): UserListType => {
    const MAX_RECORDS = 20
    const [userguid, setUserGuid] = useState('')
    const [account, setAccount] = useState(new UserAccount())
    const [error, setError] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [accounts, setTableValues] = useState([] as UserAccount[])
    const [enableSearch, setEnableSearch] = useState(false)
    const [displayCount, setDisplayCount] = useState(0)
    const [rowLength, setRowLength] = useState(0)

    useFetchWithCallback(new FetchUserAccounts(), (err, values) => {
      if (err) {
        console.log(err)
        return
      }

      if (values && values.success && values.accounts.length >= 0) {
        setTableValues(values.accounts)
        const len = values.accounts.length
        setRowLength(len)
        if (len > MAX_RECORDS) {
          setDisplayCount(MAX_RECORDS)
        } else {
          setDisplayCount(len)
        }
      } else {
        console.log(values.error)
        setGeneralError(JSON.stringify(values.error))
      }
    })

    const loadMore = (): void => {
      const newLen = displayCount + MAX_RECORDS
      if (newLen > rowLength) {
        setDisplayCount(rowLength)
      } else {
        setDisplayCount(newLen)
      }
    }

    const clearUser = (): void => {
      setAccount(new UserAccount())
      setUserGuid('')
      setError('')
      setEnableSearch(false)
    }

    const searchUser = async (): Promise<void> => {
      try {
        setLoading(true)
        const response = await api.firstdigital.getUserAccountStatus({
          userGuid: userguid,
        })

        if (response.isOk()) {
          if (response.value.success) {
            setAccount(new UserAccount(response.value.account))
          } else {
            // if (response.value.error) {
            setGeneralError(JSON.stringify(response.value.error))
          }
        } else {
          setGeneralError(JSON.stringify(response.error))
        }
        setLoading(false)
      } catch (err) {
        setLoading(false)
        setGeneralError(JSON.stringify(err))
      }
    }

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
      const val = e.currentTarget.value.replace(/\s/gm, '')
      setUserGuid(val)
      if (val.length === 36) {
        setEnableSearch(true)
      } else {
        setEnableSearch(false)
      }
    }

    const UserListState = {
      account: account,
      accounts: accounts,
      clearUser: clearUser,
      displayCount: displayCount,
      enableSearch: enableSearch,
      error: error,
      handleInputChange: handleInputChange,
      isLoading: isLoading,
      loadMore: loadMore,
      rowLength: rowLength,
      setRowLength: setRowLength,
      searchUser: searchUser,
      setAccount: setAccount,
      setLoading: setLoading,
      setTableValues: setTableValues,
      userguid: userguid,
    }

    return UserListState
  }

  const togglePage = (isPageOne: boolean): void => {
    setPageToggle(isPageOne)
    if (isPageOne) {
      const loginElem = document.getElementsByClassName('toggle-left')
      loginElem[0].classList.add('toggle-active')
      const signupElem = document.getElementsByClassName('toggle-right')
      signupElem[0].classList.remove('toggle-active')
    } else {
      const signupElem = document.getElementsByClassName('toggle-right')
      signupElem[0].classList.add('toggle-active')
      const loginElem = document.getElementsByClassName('toggle-left')
      loginElem[0].classList.remove('toggle-active')
    }
  }

  const getLiveGoldPrice = async (): Promise<void> => {
    try {
      const response = await api.firstdigital.getGoldPrice()
      if (response.success && response.askPrice && response.bidPrice) {
        setLivePrice({
          askPrice: parseFloat(response.askPrice).toFixed(2).toString(),
          bidPrice: parseFloat(response.bidPrice).toFixed(2).toString(),
        })
        const formatDate = convertTimestampToLocalString(response.timestamp)
        setPriceDate(formatDate)
      } else {
        setGeneralError(`Couldn't fetch spot price`)
      }
    } catch (err) {
      setGeneralError(`Couldn't fetch spot price`)
    }
  }

  const AddrFormState = useAddAddressFormState()
  const UserListState = useUserListState()

  const setLoading = (): void => {
    AddrFormState.setLoading(false)
    UserListState.setLoading(false)
  }

  useEffect(() => {
    AddrFormState.getEmail()
    getLiveGoldPrice()
    // eslint-disable-next-line
  }, [])

  return (
    <AdminComponent className="container">
      {AddrFormState.isLoading || UserListState.isLoading ? (
        <LoadingBlock clearLoadingMethod={setLoading} setTimeoutErrorMethod={setGeneralError} />
      ) : null}
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}

      <div className="card-small">
        <div className="kgld-gold-price-wrapper">
          <a href={RouteConstants.GoldPrice} className="link underline">
            Gold price calculator
          </a>
        </div>
        <table className="border user-table">
          <tbody>
            <tr>
              <th>Asset</th>
              <th>Ask Price</th>
              <th>Bid price</th>
              <th>Time</th>
            </tr>

            <tr>
              <th>Gold</th>
              <th>${livePrice.askPrice}</th>
              <th>${livePrice.bidPrice}</th>
              <th>{priceDate}</th>
            </tr>
            {/* <tr>
              <th colSpan={4}>Calculator</th>
            </tr>
            <tr>
              <th colSpan={2}>Calculate token value</th>
              <th colSpan={2}>Calculate USD value</th>
            </tr> */}
          </tbody>
        </table>
      </div>
      <div className="toggle-wrapper">
        <div className="toggle-left toggle-active" onClick={(): void => togglePage(true)}>
          View Users
        </div>
        {isPageOne ? <div className="toggle-right-arrow"></div> : <div className="toggle-left-arrow"></div>}
        <div className="toggle-right" onClick={(): void => togglePage(false)}>
          Add Address
        </div>
      </div>
      {isPageOne ? (
        <UserListContainer AddrFormState={AddrFormState} UserListState={UserListState} />
      ) : (
        <RedemptionAddrContainer AddrFormState={AddrFormState} />
      )}
    </AdminComponent>
  )
}

export default withRouter(AdminContainer)
