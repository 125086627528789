import { merge } from '../../util'

export class UUIDRules {
  constructor(init: UUIDRules) {
    if (init) merge(init, this)
  }
  public required = false
}

export function validateUUIDInput(input: string, rules?: UUIDRules): boolean {
  if (input === '') return true
  if (!rules) return true
  return true
}

export function validateUUIDValue(input: string, rules?: UUIDRules): string {
  if (input === '') return ''
  if (!rules) return ''
  return ''
}
