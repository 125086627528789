import { CognitoApi, CognitoUser, CognitoUserSession, AuthUser } from './cognito'
import { Api } from '../index'
import { Result } from 'neverthrow'

export class AuthState {
  public loggedIn = false
  public authToken?: string
  public expiration?: number
  public userGuid?: string
}

export type handler = (state: AuthState) => void

export class AuthApi {
  constructor(readonly api: Api) {
    this.cognito = new CognitoApi(api.config)
    if (this.api.persistent) {
      this.restoreState()
    }
  }

  public cognito: CognitoApi

  // Cognito objects
  private user?: CognitoUser
  private session?: CognitoUserSession

  // State
  public state = new AuthState()
  public authError?: string

  // HTTP Header containing the Bearer Token
  public authHeader(): string | undefined {
    if (!this.state.authToken) return undefined
    return `Bearer ${this.state.authToken}`
  }

  public async login(username: string, password: string): Promise<boolean> {
    const [user, session] = await this.cognito.login(username, password)
    this.setCognitoAttributes(user, session)
    return user ? true : false
  }

  public async login2FA(username: string, password: string): Promise<AuthUser | null> {
    const [user, session] = await this.cognito.login2FA(username, password)
    this.setCognitoAttributes(user, session)
    return user
  }

  public async login2FAConfirm(loguser: any, code: string, mfaType: string): Promise<boolean> {
    const [user, session] = await this.cognito.login2FAConfirm(loguser, code, mfaType)
    this.setCognitoAttributes(user, session)
    return user ? true : false
  }

  public async signup(username: string, password: string): Promise<boolean> {
    const user = await this.cognito.signup(username, password)
    this.setCognitoAttributes(user, null)
    return user ? true : false
  }

  public async loginOrSignup(username: string, password: string): Promise<boolean> {
    const [user, session] = await this.cognito.loginOrSignup(username, password)
    this.setCognitoAttributes(user, session)
    return user ? true : false
  }

  public async signupAndLogin(username: string, password: string): Promise<boolean> {
    const [user, session] = await this.cognito.signupAndLogin(username, password)
    this.setCognitoAttributes(user, session)
    return user ? true : false
  }

  // this can only be used on test pools with usernames that are autoconfirmed
  public async loginOrSignupAndLogin(username: string, password: string): Promise<boolean> {
    const [user, session] = await this.cognito.loginOrSignupAndLogin(username, password)
    this.setCognitoAttributes(user, session)
    return user ? true : false
  }

  public logout(): boolean {
    localStorage.clear()
    this.clearCognitoAttributes()
    return true
  }

  public async confirmSignup(userEmail: string, code: string): Promise<Result<any, Error>> {
    return await this.cognito.confirmSignup(userEmail, code)
  }

  public async getCurrentAuthenticatedUser(): Promise<Result<any, Error>> {
    return await this.cognito.getCurrentAuthenticatedUser()
  }

  public async getPreferredMFAType(user: CognitoUser): Promise<Result<any, Error>> {
    return await this.cognito.getPreferredMFAType(user)
  }

  public async setPreferredMFAType(user: CognitoUser, mfaType: string): Promise<Result<any, Error>> {
    return await this.cognito.setPreferredMFA(user, mfaType)
  }

  public async getTOTPCode(user: CognitoUser): Promise<Result<any, Error>> {
    return await this.cognito.getTOTPCode(user)
  }

  public async verifyTotpToken(user: CognitoUser, challenge: string): Promise<Result<any, Error>> {
    return await this.cognito.verifyTotpToken(user, challenge)
  }

  public async forgotPassword(userEmail: string): Promise<Result<any, Error>> {
    return await this.cognito.forgotPassword(userEmail)
  }

  public async forgotPasswordSubmit(userEmail: string, code: string, newPassword: string): Promise<Result<any, Error>> {
    return await this.cognito.forgotPasswordSubmit(userEmail, code, newPassword)
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<Result<any, Error>> {
    return await this.cognito.changePassword(oldPassword, newPassword)
  }

  public async resendSignUp(username: string): Promise<Result<any, Error>> {
    return await this.cognito.resendSignUp(username)
  }

  private subscribers: Array<handler> = []

  public subscribe(handler: handler): void {
    this.subscribers.push(handler)
  }

  public unsubscribe(handler: handler): void {
    this.subscribers = this.subscribers.filter((fn) => fn !== handler)
  }

  private publish(): void {
    this.subscribers.forEach((handler) => handler(this.state))
  }

  private setState(state: AuthState): void {
    this.state = state
    if (this.api.persistent) {
      this.saveSyncState()
    }
    this.publish()
  }

  private clearState(): void {
    this.state = new AuthState()
    if (this.api.persistent && this.api.syncStorage) {
      this.clearSyncState()
    }
    this.publish()
  }

  private restoreState(): void {
    if (this.api.persistent && this.api.syncStorage) {
      this.restoreSyncState()
    }
  }

  private saveSyncState(): void {
    if (this.api.persistent && this.api.syncStorage) {
      this.api.syncStorage.setItem('api.auth.state', JSON.stringify(this.state))
    }
  }

  private clearSyncState(): void {
    if (this.api.persistent && this.api.syncStorage) {
      this.api.syncStorage.removeItem('api.auth.state')
    }
  }

  private restoreSyncState(): void {
    if (this.api.persistent && this.api.syncStorage) {
      const storedState = this.api.syncStorage.getItem('api.auth.state')
      if (storedState) {
        const newState: AuthState = JSON.parse(storedState)
        if (newState.userGuid) {
          this.restoreCognitoAttributes() // don't wait for this async method
        }
        this.state = newState
      }
    }
  }

  private setCognitoAttributes(user: CognitoUser | null, session: CognitoUserSession | null): void {
    if (user && session) {
      this.user = user
      this.session = session
      this.setState({
        loggedIn: true,
        authToken: this.session.getIdToken().getJwtToken(),
        expiration: this.session.getIdToken().getExpiration() * 1000,
        userGuid: this.user.getUsername(),
      })
    } else if (user) {
      this.user = user
      this.setState({
        loggedIn: false, // tdb: unconfirmed state
        authToken: undefined,
        userGuid: this.user.getUsername(),
      })
    }
  }

  private clearCognitoAttributes(): void {
    this.user = undefined
    this.session = undefined
    this.clearState()
  }

  public async restoreCognitoAttributes(): Promise<void> {
    // trigger and await cognito session restoration
    // compare userGuid and authToken (has refresh built in?)
    const session = await this.cognito.auth.currentSession()
    if (!session) {
      this.logout()
      return
    }
    const idToken = session.getIdToken()
    if (!idToken) {
      this.logout()
      return
    }
    if (idToken.payload.sub !== this.state.userGuid) {
      console.log(
        `saved cognito user ${idToken.payload.sub} did not match saved auth state user ${this.state.userGuid}`
      )
      this.logout()
      return
    }
    const authToken = idToken.getJwtToken()
    if (!authToken) {
      this.logout()
      return
    }
    if (authToken !== this.state.authToken) {
      // console.log(`got new authToken`)
      this.setState({ ...this.state, authToken, expiration: idToken.getExpiration() * 1000 })
    }
    this.session = session
  }

  public isLoggedIn(): boolean {
    return this.state.loggedIn
  }
}
