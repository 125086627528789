import { css } from 'styled-components'

export const colors = {
  black: '#000000',
  red: '#FF0000',
  white: '#ffffff',
  kitcoYellow: '#eeb111',
  backgroundCol: 'rgb(245, 245, 246)',
  navbarShadow: '#a1a0a0',
}

const mediaQuery = (...query) => (...rules): string => css`
  @media ${css`
      ${query}
    `} {
    ${css`
      ${rules}
    `};
  }
`

const breakpoint = {
  mediumPhone: 23.4375,
  largePhone: 25.875,
  mobile: 47.9375,
  touch: 62,
  tablet: 48,
  desktop: 62,
  widescreen: 80,
  fullhd: 90,
}

export const media = {
  mediumPhone: mediaQuery(`(min-width: ${breakpoint.mediumPhone}rem)`),
  largePhone: mediaQuery(`(min-width: ${breakpoint.largePhone}rem)`),
  mobile: mediaQuery(`(max-width: ${breakpoint.mobile}rem)`),
  touch: mediaQuery(`(max-width: ${breakpoint.touch}rem)`),
  tablet: mediaQuery(`(min-width: ${breakpoint.tablet}rem)`),
  desktop: mediaQuery(`(min-width: ${breakpoint.desktop}rem)`),
  widescreen: mediaQuery(`(min-width: ${breakpoint.widescreen}rem)`),
  fullhd: mediaQuery(`(min-width: ${breakpoint.fullhd}rem)`),
}
