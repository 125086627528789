import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ContainerRouterProps } from '../interface'
import { useAuthContext, useFormStateAction, api } from '@/ApiReact'
import {
  ActionFDUSBankAccount,
  ActionFDIntBankAccount,
  validateMessage,
  FDIntBankAccount,
  FDIntBankAccountErrors,
  FDUSBankAccount,
  FDUSBankAccountErrors,
} from '@/Api'
import AddUSBankAccount from '../components/BankAccount/AddUSBankAccount'
import AddIntBankAccount from '../components/BankAccount/AddIntBankAccount'
import context from '../util/localize'
import ErrorAlert from './usernotifications/erroralert'
import LoadingBlock from './usernotifications/loading'
import RouteConstants from '../routes/routeConstants'
import { ValueLabelPair } from '../components/locale/countries'

export type BankAccFormState = {
  country: string
  getInitialValues: () => Promise<void>
  getUserCountry: () => Promise<void>
  handleCountryChange: (country: ValueLabelPair) => void
  isLoading: boolean
  setFormCountry: (value: string) => void
  setLoading: (val: boolean) => void
}

export type AddIntBankAccountState = {
  values: FDIntBankAccount
  setValues: React.Dispatch<any>
  errors: FDIntBankAccountErrors
  setErrors: React.Dispatch<any>
  handleChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  success: boolean
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

export type AddUSBankAccountState = {
  values: FDUSBankAccount
  setValues: React.Dispatch<any>
  errors: FDUSBankAccountErrors
  setErrors: React.Dispatch<any>
  handleChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  success: boolean
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

const BankAccountContainer: React.FC<ContainerRouterProps> = (props: any) => {
  const auth = useAuthContext()
  const [generalError, setGeneralError] = useState('')
  const [displayTextEdit, setDisplayTextEdit] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const useUSBankAccountFormState = (): AddUSBankAccountState => {
    const [success, setSuccess] = useState(false)
    const FDUSBankAccount = new ActionFDUSBankAccount()
    const { values, setValues, errors, setErrors, handleChange } = useFormStateAction(FDUSBankAccount)

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault()
      setLoading(true)
      const [valid, newErrors] = validateMessage(values, FDUSBankAccount.messageType, FDUSBankAccount.errorsType)
      if (!valid) {
        setLoading(false)
        setErrors(newErrors)
        return
      }

      const response = await auth.api().set(FDUSBankAccount.setUrl, values)
      if (response.isOk()) {
        if (typeof response.value.errors === 'object' && Object.keys(response.value.errors).length > 0) {
          setLoading(false)
          setErrors(response.value.errors)
        } else {
          setValues(new FDUSBankAccount.messageType(response.value))
          if (response.value.success) {
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
              props.history.push(RouteConstants.Home)
            }, 2000)
          } else if (response.isErr()) {
            setLoading(false)
            setGeneralError(JSON.stringify(response.error))
          } else {
            setGeneralError(JSON.stringify(response))
          }
        }
      } else {
        setLoading(false)
        setGeneralError(
          JSON.stringify(response.error).length > 2 ? JSON.stringify(response.error) : 'Unexpected error occured'
        )
        return
      }
    }

    return {
      values: values,
      setValues: setValues,
      errors: errors,
      setErrors: setErrors,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      success: success,
      setSuccess: setSuccess,
    }
  }

  const useIntBankAccountFormState = (): AddIntBankAccountState => {
    const [success, setSuccess] = useState(false)
    const FDIntBankAccount = new ActionFDIntBankAccount()
    const { values, setValues, errors, setErrors, handleChange } = useFormStateAction(new ActionFDIntBankAccount())

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault()
      setLoading(true)
      const [valid, newErrors] = validateMessage(values, FDIntBankAccount.messageType, FDIntBankAccount.errorsType)
      if (!valid) {
        setLoading(false)
        setErrors(newErrors)
        return
      }

      const response = await auth.api().set(FDIntBankAccount.setUrl, values)
      if (response.isOk()) {
        if (typeof response.value.errors === 'object' && Object.keys(response.value.errors).length > 0) {
          setLoading(false)
          setErrors(response.value.errors)
        } else {
          setValues(new FDIntBankAccount.messageType(response.value))
          if (response.value.success) {
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
              props.history.push(RouteConstants.Home)
            }, 2000)
          } else if (response.isErr()) {
            setLoading(false)
            setGeneralError(JSON.stringify(response.error))
          } else {
            setGeneralError(JSON.stringify(response))
          }
        }
      } else {
        setLoading(false)
        setGeneralError(
          JSON.stringify(response.error).length > 2 ? JSON.stringify(response.error) : 'Unexpected error occured'
        )
        return
      }
    }

    return {
      values: values,
      setValues: setValues,
      errors: errors,
      setErrors: setErrors,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      success: success,
      setSuccess: setSuccess,
    }
  }

  const USBankAcc = useUSBankAccountFormState()

  const IntBankAcc = useIntBankAccountFormState()

  const useBankAccountFormState = (): any => {
    const [country, setCountry] = useState('US')
    const appliedForVerification = useSelector((state) => state.kgldReducer.appliedForVerification)

    const setFormCountry = (value: string): void => {
      setCountry(value)
      USBankAcc.setValues({ country: value })
      IntBankAcc.setValues({ country: value })
    }

    const handleCountryChange = (country: ValueLabelPair): void => {
      setCountry(country.value)
      USBankAcc.setValues({ country: country.value })
      IntBankAcc.setValues({ country: country.value })
    }

    const getUserCountry = async (): Promise<void> => {
      try {
        const reply = await api.firstdigital.userCountry()
        if (reply.country) {
          setFormCountry(reply.country)
          setLoading(false)
        } else {
          setFormCountry('')
          setLoading(false)
        }
      } catch (err) {
        setLoading(false)
        setGeneralError(JSON.stringify(err))
      }
    }

    const getInitialValues = async (): Promise<void> => {
      try {
        setLoading(true)
        //redirect if not applied for verification
        if (!appliedForVerification) {
          props.history.push(RouteConstants.KYCType)
        }
        const FDIntBankAccount = new ActionFDIntBankAccount()
        const values = await auth.api().get(FDIntBankAccount.getUrl)
        if (
          values &&
          values.accountNumber === '' &&
          values.nameOnAccount === '' &&
          typeof values === 'object' &&
          Object.keys(values).length > 0
        ) {
          setDisplayTextEdit(false)
          getUserCountry()
        } else if (
          values &&
          values.country !== '' &&
          values.bankName !== '' &&
          values.accountNumber !== '' &&
          values.nameOnAccount !== ''
        ) {
          setCountry(values.country.toString())
          USBankAcc.setValues(values)
          IntBankAcc.setValues(values)
          setDisplayTextEdit(true)
        } else if (values && typeof values.errors === 'object' && Object.keys(values.errors).length > 0) {
          setGeneralError(JSON.stringify(values.errors))
        }
        setLoading(false)
      } catch (err) {
        setLoading(false)
        setGeneralError(JSON.stringify(err).length > 2 ? JSON.stringify(err) : 'Unexpected error occured')
      }
    }

    return {
      country: country,
      getInitialValues: getInitialValues,
      getUserCountry: getUserCountry,
      handleCountryChange: handleCountryChange,
      isLoading: isLoading,
      setFormCountry: setFormCountry,
      setLoading: setLoading,
    }
  }

  const BankAccState = useBankAccountFormState()

  useEffect(() => {
    BankAccState.getInitialValues()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="container">
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      {isLoading ? <LoadingBlock clearLoadingMethod={setLoading} setTimeoutErrorMethod={setGeneralError} /> : null}
      <div className="card-small">
        <p className="text-align-center uppercase text-bold">
          {displayTextEdit ? context.changeBankAcc : context.addBankInfo}
        </p>
        <p className="text-align-center text-bold-imp">
          {!(USBankAcc.success || IntBankAcc.success)
            ? displayTextEdit
              ? context.changeBankInstruction
              : context.addBankInstruction
            : null}
        </p>
        {BankAccState.country == 'US' ? (
          <AddUSBankAccount
            handleChange={USBankAcc.handleChange}
            handleCountryChange={BankAccState.handleCountryChange}
            handleSubmit={USBankAcc.handleSubmit}
            values={USBankAcc.values}
            errors={USBankAcc.errors}
            country={BankAccState.country}
            success={USBankAcc.success}
            setSuccess={USBankAcc.setSuccess}
          />
        ) : (
          <AddIntBankAccount
            handleChange={IntBankAcc.handleChange}
            handleCountryChange={BankAccState.handleCountryChange}
            handleSubmit={IntBankAcc.handleSubmit}
            values={IntBankAcc.values}
            errors={IntBankAcc.errors}
            country={BankAccState.country}
            success={IntBankAcc.success}
            setSuccess={IntBankAcc.setSuccess}
          />
        )}
      </div>
    </div>
  )
}

export default withRouter(BankAccountContainer)
