import LocalizedStrings from 'react-localization'

const context = new LocalizedStrings({
  en: {
    abaRoutingNumber: `ABA Routing Number`,
    about: 'About',
    accept: 'Accept',
    account: 'Account',
    accountHoldersName: `Account Holder's Name`,
    accountName: 'Account Name',
    accountNumber: 'Account Number',
    accountType: 'Setup Account',
    addBankAccount: 'Add Bank Account',
    addBankInfo: 'Banking Information',
    addBankInstruction: 'Add a bank account to receive payment for your Kitco Gold',
    addCryptoAccount: 'Add Crypto Account',
    addEthAddr: 'Add an Ethereum address to your account',
    addEthAddrListIns: 'Add a list of ethereum addresses separated by a new line',
    address: 'Address',
    addressofUltimateBeneficialOwner: 'Address of Ultimate Beneficial Owner',
    agreementPart1: 'I agree with the',
    agreementPart2: 'and want to open an account',
    and: ' and ',
    anyLitigationsIndividual: 'I have never been involved in any litigations',
    anyLitigationsInstitution: 'I/We have never been involved in any litigations',
    anyLitigationsDetail: 'Summary of litigation results',
    anyRegulatoryActionsIndividual: 'I have never been subject to any regulatory actions',
    anyRegulatoryActionsInstitution: 'I/We have never been subject to any regulatory actions',
    anyRegulatoryActionsDetail: 'Summary of regulatory actions',
    articlesofAssociation: 'Articles of Association',
    backToKGLD: 'Back to Kitco Gold',
    backToWebsite: 'Back to website',
    bankAddress: 'Bank Address',
    bankInformationQuestions: 'For questions concerning the required banking information, please click ',
    bankName: 'Bank Name',
    burnAddressMessage: 'Your unique burn address',
    business: 'Business',
    businessAccount: 'Business Account',
    businessPhoneNumber: 'Business Phone Number',
    businessRegistrationNumber: 'Business Registration Number',
    businessRegistrationCertificate: 'Business Registration Certificate',
    buy: 'BUY',
    buyInstruction: 'Please include the unique wire reference number listed below when processing your payment',
    buyStepOnePart1: 'Send a payment in USD to ',
    buyStepOnePart2: ' via bank wire',
    buyStepTwoPart1: ' Your Kitco Gold will be sent to your Ethereum address: ',
    buyStepTwoPart2: '. Quantity is determined based on gold price at time of fund settlement.',
    cancel: 'Cancel',
    changeStatusForUser: 'Change account status for users',
    changeBankAcc: 'Change Banking Information',
    changeBankInstruction: 'This bank account will be used to transfer the funds for Kitco Gold that you redeem',
    changePassword: 'Change Password',
    changePhone: 'Change Phone',
    certificateofIncorporation: 'Certificate of Incorporation',
    city: 'City',
    clickHere: 'Click here',
    code: 'Code',
    companyName: 'Company Name',
    companyStructure: 'Company Structure',
    confirmNewPassword: 'Confirm New Password',
    confirmPassword: 'Confirm Password',
    contactUs: 'contact us',
    copyright: 'Copyright',
    copyrightText: `Kitco Digital Metals Group Inc. All rights reserved.
            All company, product, brands and service names used in this website are for identification purposes only and
            are the property of their respective owners.`,
    country: 'Country',
    correspondenceAddress: 'Correspondence Address (If different than primary address)',
    createAccount: 'Create Account',
    createAnAccount: 'Create an account',
    dateofBirth: 'Date of birth',
    dateofIncorporation: 'Date of Incorporation',
    decline: 'Decline',
    disable2FA: 'Disable 2FA',
    dualNationality: 'Dual Nationality',
    email: 'Email',
    emailAddress: 'Email Address',
    employmentStatus: 'Employment Status',
    enable2FA: 'Enable 2FA',
    enterNewPassword: 'Enter New Password',
    evidenceofListedOrRegulatoryStatus: 'Evidence of Listed or Regulatory Status',
    ethAddrList: 'Ethereum addresses',
    faq: 'FAQ',
    feeInfo: 'Applicable fees for buying/selling Kitco Gold please click ',
    firstName: 'First Name',
    forAssistanceEmailUs: 'For assistance please email us at ',
    forCorporate: 'For corporate and institutional buyers',
    forgotMessage:
      'If you want to change your password, provide the email associated with your Kitco Gold account below and we will send you a link to reset it.',
    forgotPassword: 'Forgot password',
    forIndividual: 'For professional investors',
    forPhysicalGold: 'For physical gold',
    gender: 'Gender',
    generalError: 'Something went wrong!',
    governmentIdNumber: 'Government Id Number',
    governmentIdNumberofUltimateBeneficialOwner: 'Government Id Number of Ultimate Beneficial Owner',
    haveNeverBeenUsCitizen: 'I have never been a US citizen or resident',
    weHaveNeverBeenUsCitizen: 'I/We have never been a US citizen or resident',
    help: 'Help',
    here: 'HERE',
    idDocument: 'Id Document',
    idCopiesofDirectorsAndKeyShareholders: 'Id Copies of Directors And Key Shareholders',
    important: 'Important',
    initialTOS1: 'You must accept the ',
    initialTOS2: ' before we can serve you.',
    individual: 'Individual',
    iAmUltimateBeneficialOwner: 'I am the ultimate beneficial owner',
    jobTitle: 'Job title',
    KGLD: 'Kitco Gold',
    KGLDSpotPrice: 'Kitco Gold spot price',
    kitcoOnlineStore: `Kitco's online store`,
    keyShareHoldersNames: 'Key Share Holders Names',
    lastName: 'Last Name',
    legacyTrustComp: 'Legacy Trust Company',
    listofDirectors: 'List of Directors',
    listofDirectorsNames: `List of Directors' Names`,
    listofShareholders: 'List of Shareholders',
    loading: 'Loading',
    loadMore: 'Load more',
    login: 'Login',
    loginInstead: 'Login Instead',
    loginMessage: 'Login to your Kitco Gold Account',
    logout: 'Log out',
    manage2FA: 'Manage 2FA',
    mobilePhone: 'Mobile Phone',
    mobilePhoneofUltimateBeneficialOwner: 'Mobile Phone of Ultimate Beneficial Owner',
    nameTitle: 'Name Title',
    natureofBusiness: 'Nature of Business',
    nameofEmployer: 'Name of employer',
    nameofUltimateBeneficialOwner: 'Name of Ultimate Beneficial Owner',
    nationality: 'Nationality',
    newPassword: 'New Password',
    noUserFound: 'No user found',
    occupation: 'Occupation',
    oldPassword: 'Old Password',
    openBusinessAccount: 'OPEN A BUSINESS ACCOUNT',
    openAccInstruction: 'Please fill out each field in the form below to open a Kitco Gold Account',
    openPersonalAccount: 'OPEN A PERSONAL ACCOUNT',
    password: 'Password',
    personal: 'Personal',
    personalAccount: 'Personal Account',
    postal: 'Postal Code',
    placeofBirth: 'Place of birth',
    primaryAddress: 'Primary Address',
    primaryContactPersonFirstName: 'Primary Contact Person First Name',
    primaryContactPersonLastName: 'Primary Contact Person Last Name',
    primaryContactEmail: 'Primary Contact Email',
    preApprovalStepOne: 'Once your account is approved you can start buying Kitco Gold.',
    preBuyInstruction: 'You need to add your crypto account to buy Kitco Gold',
    preBuyStepOne: 'Provide a compatible wallet address to receive Kitco Gold',
    preBuyStepTwo: 'Send a payment in USD to First Digital Trust Limited via bank wire',
    preBuyStepThree: 'Receive Kitco Gold in your wallet',
    preSellInstruction: 'You need to add your bank account before you can sell your Kitco Gold',
    preSellStepOne: 'Add your bank account information to receive USD',
    preSellStepTwo: 'Send your Kitco Gold to your unique burn address',
    preSellStepThree: 'Receive USD in your bank account via wire',
    privacyPolicy: 'Privacy Policy',
    proofofAddress: 'Proof of Address',
    redeem: 'Redeem',
    redemptionAddressAssigned: 'Redemption Address Assigned',
    redeemAssistance: 'For digital wallet instructions or assistance please click ',
    redeemInstructionPart1: 'To redeem Kitco Gold for physical, please ',
    redeemInstructionPart2: ' for more information about the procedure.',
    redemptionAddrAvailable: 'No. of redemption addresses available to users',
    referenceNumberMessage: 'Your unique wire reference number',
    region: 'Region',
    registeredOfficeAddress: 'Registered Office Address',
    rejectApplication: 'Reject User Application',
    requestTransactionHistory: 'To view your transaction history regarding your Kitco Gold account please click ',
    resend: 'Resend',
    resetPassword: 'Reset password',
    resetPasswordMessage: 'Enter the reset code sent to your email and your new password',
    restrictedCountriesNotice: '*Please note that we do not accept residents of {0}',
    select: 'Select',
    sell: 'Sell',
    sellInstruction:
      'Only send KGLD to the burn address. Make sure the address you enter matches exactly with what is provided. We are not responsible for you sending wrong tokens or sending KGLD to the wrong address.',
    sellStepOne: 'Send your Kitco Gold to your unique burn address below',
    sellStepTwoPart1: `Your USD will be wired to your bank account ending in `,
    sellStepTwoPart2: `. Quantity is determined based on gold price at time of fund settlement.`,
    settings: 'Settings',
    setUpTwoFA: 'Set up 2FA',
    signOut: 'Sign out',
    signup: 'Sign Up',
    signupMessage: 'Sign up for a Kitco Gold Account',
    sourceofFunds: 'Source of Funds',
    sourceofWealth: 'Source of Wealth',
    srno: 'Sr. no.',
    submit: 'Submit',
    success: 'Success!',
    successBankAcc: 'Successfully updated bank account details!',
    successAddRedemptionAdd: 'Successfully added redemption address(es)',
    successKYCSubmitted:
      'Thanks for your completing the application form. You will receive a confirmation via e-mail once your account has been approved. If we require any additional information, one of our representatives will be in touch within 24-48 hours.',
    successPassword: 'Successfully updated password!',
    subjectToMsbRegulation: 'I/We are not subject to any MSB regulations',
    subjectToMsbRegulationDetails: 'Summary of MSB status and requirements',
    summaryOfRegulatoryStatus: 'Summary of regulatory status and requirements',
    swiftCode: 'SWIFT Code',
    taxCountry: 'Tax country',
    taxId: 'Tax Id',
    termsOfService: 'Terms of service',
    termsofUse: 'Terms of Use',
    twoFAMsg: 'It is recommended that you set up 2FA to keep your account secure',
    twoFAImp: `Please make sure you don't lose access to your 2FA method or 2FA key, this can result in losing access to your account.`,
    tradeNow: 'Trade now',
    ultimateBeneficialOwnerIdDocument: 'Ultimate Beneficial Owner Id Document',
    ultimateBeneficialOwnerProofofAddress: 'Ultimate Beneficial Owner Proof of Address',
    updateAccountInfo: 'Update Account Info',
    updateAccountSettings: 'To update your account settings please click ',
    updateBankInfo: 'Update Bank Details',
    updateCryptoInfo: 'Update Crypto Details',
    updateEthAddr: 'Update your Ethereum Address',
    updatedTOS1: 'A change in our ',
    updatedTOS2: ' takes place on ',
    updatedTOS3: '. Please read them carefully.',
    updatedTerms: 'Updated Terms',
    updatedTermsTitle: `We've updated our Terms of Service`,
    userAccountStatus: 'User Account Status',
    userEmail: 'User Email',
    userguid: 'User GUID',
    username: 'Username',
    verifyEmailMessage: 'Verify your email address',
    weAreRegulatedEntity: 'I/We are a regulated entity in any jurisdiction',
    welcome: 'Welcome',
    wireReferenceAssigned: 'Wire reference assigned',
    yearsofService: 'Years of service',
  },
  zh: {
    abaRoutingNumber: 'ABA Routing Number',
    about: '關於',
    accept: 'Accept',
    account: '家',
    accountHoldersName: `Account Holder's Name`,
    accountName: '用户名',
    accountNumber: '账号',
    accountType: 'Setup Account',
    addBankAccount: 'Add Bank Account',
    addBankInfo: '添加銀行賬戶',
    addBankInstruction: 'Add a bank account to receive payment for your Kitco Gold',
    addCryptoAccount: 'Add Crypto Account',
    addEthAddr: 'Add an Ethereum address to your account',
    addEthAddrListIns: 'Add a list of ethereum addresses separated by a new line',
    address: '地址',
    addressofUltimateBeneficialOwner: '最终实益拥有人地址',
    agreementPart1: 'I agree with the',
    agreementPart2: 'and want to open an account',
    and: ' and ',
    anyLitigationsIndividual: 'I have never been involved in any litigations',
    anyLitigationsInstitution: 'I/We have never been involved in any litigations',
    anyLitigationsDetail: '诉讼结果摘要（如果有）',
    anyRegulatoryActionsIndividual: 'I have never been subject to any regulatory actions',
    anyRegulatoryActionsInstitution: 'I/We have never been subject to any regulatory actions',
    anyRegulatoryActionsDetail: '监管措施结果摘要（如果有',
    articlesofAssociation: '关联的文章',
    backToKGLD: '回到 KitcoGold',
    backToWebsite: '背部',
    bankAddress: '银行地址',
    bankInformationQuestions: 'For questions concerning the required banking information, please click ',
    bankName: 'Bank Name',
    burnAddressMessage: 'Your unique burn address',
    business: 'Business',
    businessAccount: 'Business Account',
    businessPhoneNumber: '公司电话号码',
    businessRegistrationNumber: '商业登记号码',
    businessRegistrationCertificate: '商业登记证',
    buy: 'BUY',
    buyInstruction: 'Please include the unique wire reference number listed below when processing your payment',
    buyStepOnePart1: 'Send a payment in USD to ',
    buyStepOnePart2: ' via bank wire',
    buyStepTwoPart1: ' Your Kitco Gold will be sent to your Ethereum address: ',
    buyStepTwoPart2: '. Quantity is determined based on gold price at time of fund settlement.',
    cancel: '取消',
    changeStatusForUser: 'Change account status for users',
    changeBankAcc: 'Change Banking Information',
    changeBankInstruction: 'This bank account will be used to transfer the funds for Kitco Gold that you redeem',
    changePassword: '更改密碼',
    changePhone: 'Change Phone',
    city: '市',
    clickHere: 'Click here',
    certificateofIncorporation: '公司注册证书',
    code: '碼',
    companyName: '公司名',
    companyStructure: '公司结构',
    confirmNewPassword: 'Confirm New Password',
    confirmPassword: '確認密碼',
    contactUs: 'contact us',
    copyright: '版權',
    copyrightText: `Kitco Digital Metals Group Inc. All rights reserved.
            All company, product, brands and service names used in this website are for identification purposes only and
            are the property of their respective owners.`,
    country: '国家',
    correspondenceAddress: '通讯地址 (If different than primary address)',
    createAccount: '创建帐号',
    createAnAccount: '創建一個帳戶',
    dateofBirth: '出生日期',
    dateofIncorporation: '注册成立日期',
    decline: 'Decline',
    disable2FA: '禁用 2FA',
    dualNationality: '双重国籍',
    email: '电子邮件',
    emailAddress: '电子邮件地址',
    employmentStatus: '就业状况',
    enable2FA: '啟用 2FA',
    enterNewPassword: 'Enter New Password',
    evidenceofListedOrRegulatoryStatus: '上市或监管状态的证据',
    ethAddrList: 'Ethereum addresses',
    faq: '常問問題',
    feeInfo: 'Applicable fees for buying/selling Kitco Gold please click ',
    firstName: '名字',
    forAssistanceEmailUs: 'For assistance please email us at ',
    forCorporate: 'For corporate and institutional investors',
    forgotMessage:
      'If you want to change your password, provide the email associated with your Kitco Gold account below and we will send you a link to reset it.',
    forgotPassword: 'Forgot password',
    forIndividual: 'For individual investors',
    forPhysicalGold: 'For physical gold',
    gender: '性别',
    generalError: '出了些問題!',
    governmentIdNumber: '政府编号',
    governmentIdNumberofUltimateBeneficialOwner: '最终受益所有人的政府编号',
    haveNeverBeenUsCitizen: 'I have never been a US citizen or resident',
    weHaveNeverBeenUsCitizen: 'I/We have never been a US citizen or resident',
    help: '救命',
    here: 'HERE',
    idDocument: '身份证件',
    idCopiesofDirectorsAndKeyShareholders: 'Id Copies of Directors And Key Shareholders',
    important: '重要',
    initialTOS1: 'You must accept the ',
    initialTOS2: ' before we can serve you.',
    individual: 'Individual',
    iAmUltimateBeneficialOwner: '是最终实益拥有人',
    jobTitle: '职称',
    KGLD: 'Kitco Gold',
    KGLDSpotPrice: 'Kitco Gold spot price',
    kitcoOnlineStore: `Kitco's online store`,
    keyShareHoldersNames: '主要股东名称',
    lastName: '姓',
    legacyTrustComp: 'Legacy Trust Company',
    listofDirectors: '董事名单',
    listofDirectorsNames: '董事名单',
    listofShareholders: '股东名单',
    loading: '正在载入',
    login: '登录',
    loginInstead: 'Login Instead',
    loginMessage: '登錄到您的Kitco金賬戶',
    logout: '登出',
    manage2FA: 'Manage 2FA',
    mobilePhone: '移动电话',
    mobilePhoneofUltimateBeneficialOwner: '最终受益人手机',
    nameTitle: '名字标题',
    natureofBusiness: '业务性质',
    nameofEmployer: '雇主名称',
    nameofUltimateBeneficialOwner: 'Name of Ultimate Beneficial Owner',
    nationality: '国籍',
    newPassword: 'New Password',
    noUserFound: 'No user found',
    occupation: '占用',
    oldPassword: 'Old Password',
    openBusinessAccount: 'OPEN A BUSINESS ACCOUNT',
    openAccInstruction: 'Please fill out each field in the form below to open a Kitco Gold Account',
    openPersonalAccount: 'OPEN A PERSONAL ACCOUNT',
    password: '密码',
    personal: 'Personal',
    personalAccount: 'Personal Account',
    postal: '邮政编码',
    placeofBirth: '出生地',
    primaryContactPersonFirstName: '主要联系人姓名',
    primaryAddress: 'Primary Address',
    primaryContactPersonLastName: '主要联系人的姓氏',
    primaryContactEmail: '主要联系人电子邮件',
    preApprovalStepOne: 'Once your account is approved you can start buying Kitco Gold.',
    preBuyInstruction: 'You need to add your crypto account to buy Kitco Gold',
    preBuyStepOne: 'Provide a compatible wallet address to receive Kitco Gold',
    preBuyStepTwo: 'Send a payment in USD to First Digital Trust Limited via bank wire',
    preBuyStepThree: 'Receive Kitco Gold in your wallet',
    preSellInstruction: 'You need to add your bank account before you can sell your Kitco Gold',
    preSellStepOne: 'Add your bank account information to receive USD',
    preSellStepTwo: 'Send your Kitco Gold to your unique burn address',
    preSellStepThree: 'Receive USD in your bank account via wire',
    privacyPolicy: '隱私政策',
    proofofAddress: '地址证明',
    redeem: 'Redeem',
    redemptionAddressAssigned: 'Redemption Address Assigned',
    redeemAssistance: 'For digital wallet instructions or assistance please click ',
    redeemInstructionPart1: 'To redeem Kitco Gold for physical, please ',
    redeemInstructionPart2: ' for more information about the procedure.',
    redemptionAddrAvailable: 'No. of redemption addresses available to users',
    referenceNumberMessage: 'Your unique wire reference number',
    region: '地区',
    registeredOfficeAddress: '注册办事处地址',
    rejectApplication: 'Reject User Application',
    requestTransactionHistory: 'To view your transaction history regarding your Kitco Gold account please click ',
    resend: 'Resend',
    resetPassword: '重設密碼',
    resetPasswordMessage: 'Enter the reset code sent to your email and your new password',
    restrictedCountriesNotice: '*Please note that we do not accept residents of {0}',
    select: 'Select',
    sell: 'Sell',
    sellInstruction:
      'Only send KGLD to the burn address. Make sure the address you enter matches exactly with what is provided. We are not responsible for you sending wrong tokens or sending KGLD to the wrong address.',
    sellStepOne: 'Send your Kitco Gold to your unique burn address below',
    sellStepTwoPart1: `Your USD will be wired to your bank account ending in `,
    sellStepTwoPart2: `. Quantity is determined based on gold price at time of fund settlement.`,
    settings: '帐号设定',
    setUpTwoFA: 'Set up 2FA',
    signOut: '登出',
    signup: '註冊',
    signupMessage: '註冊一個Kitco金賬戶',
    sourceofFunds: '资金来源',
    sourceofWealth: '财富来源',
    submit: '提交',
    success: '成功!',
    successBankAcc: 'Successfully updated bank account details!',
    successAddRedemptionAdd: 'Successfully added redemption address/es',
    successKYCSubmitted:
      'Thanks for your completing the application form. You will receive a confirmation via e-mail once your account has been approved. If we require any additional information, one of our representatives will be in touch within 24-48 hours.',
    successPassword: 'Successfully updated password!',
    subjectToMsbRegulation: 'Subject To Msb Regulation',
    subjectToMsbRegulationDetails: 'Subject To Msb Regulation Details',
    summaryOfRegulatoryStatus: 'Summary of regulatory status and requirements',
    swiftCode: 'SWIFT代碼',
    taxCountry: '纳税国家',
    taxId: '税号',
    termsOfService: 'Terms of service',
    termsofUse: '使用條款',
    twoFAMsg: 'It is recommended that you set up 2FA to keep your account secure',
    twoFAImp: `Please make sure you don't lose access to your 2FA method or 2FA key, this can result in losing access to your account.`,
    tradeNow: '现在交易',
    ultimateBeneficialOwnerIdDocument: '最终受益所有人身份证件',
    ultimateBeneficialOwnerProofofAddress: '最终实益拥有人地址证明',
    updateAccountInfo: 'Update Account Info',
    updateAccountSettings: 'To update your account settings please click ',
    updateBankInfo: '更新銀行詳細信息',
    updateCryptoInfo: '更新加密細節',
    updateEthAddr: 'Update your Ethereum Address',
    updatedTOS1: 'A change in our ',
    updatedTOS2: ' takes place on ',
    updatedTOS3: '. Please read them carefully.',
    updatedTerms: 'Updated Terms',
    updatedTermsTitle: `We've updated our Terms of Service`,
    userAccountStatus: 'User Account Status',
    userEmail: 'User Email',
    userguid: 'User GUID',
    username: '用戶名',
    verifyEmailMessage: '確認你的郵件地址',
    weAreRegulatedEntity: 'I/We are a regulated entity in any jurisdiction',
    welcome: 'Welcome',
    wireReferenceAssigned: 'Wire reference assigned',
    yearsofService: '服务年限',
  },
})

context.setLanguage(localStorage.getItem('locale') || 'en')

export default context
