import { useState, Dispatch, SetStateAction } from 'react'
import { KeyValues } from '@/Api'

// export interface KeyValues {
//   [key: string]: string
// }

export interface FormConfig {
  initialValues?: KeyValues
  initialErrors?: KeyValues
  handleSubmit?: (e: React.FormEvent<HTMLFormElement>, values: KeyValues) => Promise<any>
}

export interface FormState {
  values: KeyValues
  errors: KeyValues
  setValues: Dispatch<SetStateAction<KeyValues>>
  setErrors: Dispatch<SetStateAction<KeyValues>>
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void
  handleNumberInputChange: (e: React.FormEvent<HTMLInputElement>) => void
  handlePhoneInputChange: (phoneNumber: string, phoneData: any) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export function useFormState(config: FormConfig): FormState {
  const [values, dispatchValues] = useState(config.initialValues || {})
  const [errors, setErrors] = useState(config.initialErrors || {})

  const setValues: Dispatch<SetStateAction<KeyValues>> = (newValues: SetStateAction<KeyValues>): void => {
    dispatchValues(newValues)
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const newValues = { ...values }
    newValues[e.currentTarget.name] = e.currentTarget.value
    setValues(newValues)

    if (errors[e.currentTarget.name]) {
      const newErrors = { ...errors }
      delete newErrors[e.currentTarget.name]
      setErrors(newErrors)
    }
  }

  const handleNumberInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const newValues = { ...values }
    const numberString = e.currentTarget.value
    const onlyNumbersRegex = /\D/g
    const cleanedNumber = numberString.replace(onlyNumbersRegex, '')
    newValues[e.currentTarget.name] = cleanedNumber
    setValues(newValues)

    if (errors[e.currentTarget.name]) {
      const newErrors = { ...errors }
      delete newErrors[e.currentTarget.name]
      setErrors(newErrors)
    }
  }

  const handlePhoneInputChange = (phoneNumber: string, phoneData: any): void => {
    const newValues = { ...values }
    const rawPhone = phoneNumber.replace(/[^0-9]+/g, '').slice(phoneData.dialCode.length)

    newValues['phoneNumber'] = rawPhone
    newValues['phoneCountryCode'] = phoneData.dialCode
    newValues['phoneCountry'] = phoneData.countryCode

    setValues(newValues)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<any> => {
    e.preventDefault()
    if (config.handleSubmit) {
      return await config.handleSubmit(e, values)
    } else {
      console.log(`no submit handler configured`)
      return false
    }
  }

  return {
    values,
    errors,
    setValues,
    setErrors,
    handleChange,
    handleNumberInputChange,
    handlePhoneInputChange,
    handleSubmit,
  }
}

export type { KeyValues }
