import React, { useEffect, useState } from 'react'
import { useAuthContext } from '@/ApiReact'
import SignUpTerms from '../../components/user/termsagreement'
import ErrorAlert from '../usernotifications/erroralert'
import { DOCUMENT_VERSION } from '../../constants/config'

const termsAgreementPossibleStates = ['old', 'uptodate', 'nodata']

const TermsAgreementContainer: React.FC = () => {
  const auth = useAuthContext()
  const [hasAcceptedPreviously, setHasAcceptedPreviously] = useState(termsAgreementPossibleStates[1])
  const [generalError, setGeneralError] = useState('')

  const accepted = async (): Promise<void> => {
    try {
      // to prevent delay in letting user use the site initialize it beforehand as success
      setHasAcceptedPreviously(termsAgreementPossibleStates[1])
      const resp = await auth.api().firstdigital.setSignUpTermsAgreement()
      if (resp === undefined || resp?.version === '') {
        setGeneralError('Unable to make request')
      }
    } catch (e) {
      setGeneralError(JSON.stringify(e))
    }
  }

  const declined = (): void => {
    auth.logout()
  }

  const fetchUserAgreementRecord = async (): Promise<void> => {
    const agreementRecord = await auth.api().firstdigital.getTermsAgreementRecord()
    if (agreementRecord === undefined) {
      // if no response then don't show the popup
      setHasAcceptedPreviously(termsAgreementPossibleStates[1])
    } else if (agreementRecord.version < DOCUMENT_VERSION && agreementRecord.version !== '') {
      setHasAcceptedPreviously(termsAgreementPossibleStates[0])
    } else if (agreementRecord.version === DOCUMENT_VERSION) {
      setHasAcceptedPreviously(termsAgreementPossibleStates[1])
    } else {
      setHasAcceptedPreviously(termsAgreementPossibleStates[2])
    }
  }

  useEffect(() => {
    fetchUserAgreementRecord()
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      {hasAcceptedPreviously !== termsAgreementPossibleStates[1] ? (
        <SignUpTerms
          accepted={accepted}
          declined={declined}
          hasAcceptedPreviously={hasAcceptedPreviously}
          termsAgreementPossibleStates={termsAgreementPossibleStates}
        />
      ) : null}
    </React.Fragment>
  )
}

export default TermsAgreementContainer
