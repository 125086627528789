import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Account from '../components/account/account'
import { useAuthContext, useFormState } from '@/ApiReact'
import { ContainerRouterProps } from '../interface'
import ErrorAlert from './usernotifications/erroralert'
import LoadingBlock from './usernotifications/loading'
import { ChangePasswordProps } from '../components/account/changepassword'
import { TwoFAProps } from '../components/account/twoFA'

const AccountContainer: React.FC<ContainerRouterProps> = (): any => {
  const auth = useAuthContext()
  const [generalError, setGeneralError] = useState('')
  const [isLoading, setLoading] = useState(true)

  const useChangePasswordFormState = (): ChangePasswordProps => {
    const [success, setSuccess] = useState(false)
    const initialValues = { oldPassword: '', newPassword: '' }

    const { values, setValues, errors, setErrors, handleChange, handleSubmit } = useFormState({
      initialValues,
      handleSubmit: async (event, values): Promise<void> => {
        event.preventDefault()
        setLoading(true)
        try {
          const response = await auth.changePassword(values.oldPassword, values.newPassword, values.confirmNewPassword)
          if (response.isOk()) {
            setSuccess(true)
            setLoading(false)
          } else {
            setLoading(false)
            setGeneralError(response.error.message)
          }
        } catch (e) {
          switch (e.name) {
            case 'InvalidPasswordException':
              setErrors({ newPassword: e.message })
              setValues({ ...values, confirmNewPassword: '' })
              break
            case 'InvalidConfirmPasswordException':
              setErrors({ confirmNewPassword: e.message })
              break
            default:
              setGeneralError(JSON.stringify(e))
          }
          setLoading(false)
        }
      },
    })

    return {
      values: values,
      errors: errors,
      setErrors: setErrors,
      setValues: setValues,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      success: success,
      setSuccess: setSuccess,
    }
  }

  const use2FAFormState = (): TwoFAProps => {
    const [twoFACode, set2FACode] = useState('')
    const [username, setUsername] = useState('')
    const [hasMFAEnabled, setMFAEnabled] = useState(false)
    const [showMFASetup, setShowMFASetup] = useState(false)
    const [challenge, setChallenge] = useState('')
    const [challengeError, setChallengeError] = useState('')
    const [twoFASuccess, setTwoFASuccess] = useState(false)

    const disableTwoFA = async (): Promise<void> => {
      setLoading(true)
      const response = await auth.getCurrentAuthenticatedUser()

      if (response.isOk()) {
        const resp = await auth.setPreferredMFAType(response.value, 'NOMFA')
        if (resp.isOk()) {
          setLoading(false)
          setTwoFASuccess(true)
        } else {
          setLoading(false)
          setGeneralError(resp.error.message)
        }
      } else {
        setLoading(false)
        setGeneralError(response.error)
      }
    }

    const fetchCode = async (): Promise<void> => {
      setLoading(true)
      const response = await auth.getCurrentAuthenticatedUser()

      if (response.isOk()) {
        setUsername(response.value.attributes.email)
        const resp = await auth.getTOTPCode(response.value)
        if (resp.isOk()) {
          set2FACode(resp.value)
        } else {
          setGeneralError(resp.error.message)
        }
        const mfaTypeResponse = await auth.getPreferredMFAType(response.value)
        if (mfaTypeResponse.isOk()) {
          if (mfaTypeResponse.value == 'NOMFA') {
            setMFAEnabled(false)
            setLoading(false)
          } else if (mfaTypeResponse.value == 'SOFTWARE_TOKEN_MFA') {
            setMFAEnabled(true)
            setLoading(false)
          } else {
            setLoading(false)
            setGeneralError(mfaTypeResponse.value)
          }
        } else {
          setLoading(false)
          setGeneralError(mfaTypeResponse.error.message)
        }
      } else {
        setLoading(false)
        setGeneralError(response.error)
      }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      setChallenge(e.target.value)
      setChallengeError('')
    }

    const setUserTwoFA = async (): Promise<void> => {
      setLoading(true)
      const response = await auth.getCurrentAuthenticatedUser()

      if (response.isOk()) {
        const resp = await auth.verifyTotpToken(response.value, challenge)
        if (resp.isOk()) {
          if (resp.value.Status === 'SUCCESS') {
            const setMFAResponse = await auth.setPreferredMFAType(response.value, 'TOTP')
            if (setMFAResponse.isOk()) {
              setLoading(false)
              setTwoFASuccess(true)
              setMFAEnabled(true)
              setShowMFASetup(false)
            } else {
              setLoading(false)
              setGeneralError(setMFAResponse.error.message)
            }
          } else {
            setLoading(false)
            setChallengeError(resp.value.Status)
          }
        } else {
          switch (resp.error.name) {
            case 'InvalidParameterException':
              setChallengeError(`Code doesn't satisfy constraint`)
              break
            default:
              setChallengeError(resp.error.message)
          }
          setLoading(false)
        }
      } else {
        setChallengeError(response)
        setLoading(false)
      }
    }

    const getMFASettings = async (): Promise<void> => {
      const response = await auth.getCurrentAuthenticatedUser()

      if (response.isOk()) {
        const mfaTypeResponse = await auth.getPreferredMFAType(response.value)
        if (mfaTypeResponse.isOk()) {
          if (mfaTypeResponse.value == 'NOMFA') {
            setMFAEnabled(false)
          } else if (mfaTypeResponse.value == 'SOFTWARE_TOKEN_MFA') {
            setMFAEnabled(true)
          } else {
            setGeneralError(mfaTypeResponse.value)
          }
        } else {
          setGeneralError(JSON.stringify(mfaTypeResponse.error))
        }
      } else {
        setGeneralError(JSON.stringify(response.error))
      }
      setLoading(false)
    }

    const startMFAsetup = (): void => {
      fetchCode()
      setShowMFASetup(true)
    }

    return {
      challenge: challenge,
      challengeError: challengeError,
      disableTwoFA: disableTwoFA,
      fetchCode: fetchCode,
      getMFASettings: getMFASettings,
      handleChange: handleChange,
      hasMFAEnabled: hasMFAEnabled,
      setChallenge: setChallenge,
      setShowMFASetup: setShowMFASetup,
      setTwoFASuccess: setTwoFASuccess,
      setUserTwoFA: setUserTwoFA,
      showMFASetup: showMFASetup,
      startMFAsetup: startMFAsetup,
      twoFACode: twoFACode,
      twoFASuccess: twoFASuccess,
      username: username,
    }
  }

  const password = useChangePasswordFormState()
  const twoFAData = use2FAFormState()

  useEffect(() => {
    twoFAData.getMFASettings()
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      {isLoading ? <LoadingBlock clearLoadingMethod={setLoading} setTimeoutErrorMethod={setGeneralError} /> : null}
      <Account password={password} twoFAData={twoFAData} />
    </React.Fragment>
  )
}

export default withRouter(AccountContainer)
