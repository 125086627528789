import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useAuthContext, useFormState, KeyValues } from '@/ApiReact'
import LoginWrapper from '../../components/user/loginwrapper'
import Login from '../../components/user/login'
import SignUpForm from '../../components/user/signup'
import { ContainerRouterProps } from '../../interface'
import ErrorAlert from '../usernotifications/erroralert'
import LoadingBlock from '../usernotifications/loading'
import RouteConstants from '../../routes/routeConstants'

type LoginFormStateType = {
  LoginFormState: {
    values: KeyValues
    setValues: React.Dispatch<any>
    errors: KeyValues
    setErrors: React.Dispatch<any>
    handleChange: (e: React.FormEvent<HTMLInputElement>) => void
    handleSubmit: (e: React.FormEvent<HTMLFormElement>, values: KeyValues) => Promise<void> | void
    isLoginPage: boolean
    toggleToLogin: (boolean) => void
  }
}

type TwoFAFormStateType = {
  TwoFAFormState: {
    values: KeyValues
    setValues: React.Dispatch<any>
    errors: KeyValues
    setErrors: React.Dispatch<any>
    handleChange: (e: React.FormEvent<HTMLInputElement>) => void
    handleSubmit: (e: React.FormEvent<HTMLFormElement>, values: KeyValues) => Promise<void> | void
  }
}

type SignUpFormStateType = {
  SignupFormState: {
    values: KeyValues
    errors: KeyValues
    setErrors: React.Dispatch<any>
    handleChange: (e: React.FormEvent<HTMLInputElement>) => void
    handlePasswordChange: (e: React.FormEvent<HTMLInputElement>) => void
    handleSubmit: (e: React.FormEvent<HTMLFormElement>, values: KeyValues) => Promise<void> | void
  }
}

const LoginContainer: React.FC<ContainerRouterProps> = (props: ContainerRouterProps) => {
  const auth = useAuthContext()
  const [currentUser, setUser] = useState({})
  const [has2FAEnabled, setHas2FAEnabled] = useState(false)
  const [generalError, setGeneralError] = useState('')
  const [isLoading, setLoading] = useState(false)

  const useLoginFormState = (): LoginFormStateType => {
    const initialValues = { username: '', password: '' }
    const [isLoginPage, setPageToggle] = useState(true)

    const { values, setValues, errors, setErrors, handleChange, handleSubmit } = useFormState({
      initialValues,
      handleSubmit: async (event: React.FormEvent<HTMLFormElement>, values: KeyValues): Promise<void> => {
        event.preventDefault()
        setLoading(true)
        try {
          const user = await auth.login2FA(values.username, values.password)
          // currently only supports TOTP
          if (user && (user.challengeName === 'SOFTWARE_TOKEN_MFA' || user.challengeName === 'SMS_MFA')) {
            setUser(user)
            setHas2FAEnabled(true)
            setLoading(false)
          } else if (user && user.challengeName === undefined) {
            props.history.push(RouteConstants.Account)
          }
        } catch (e) {
          setLoading(false)
          switch (e.name) {
            case 'UserNotConfirmedException':
              setErrors({ username: e.message })
              const encoded = encodeURIComponent(values.username)
              const verifyRoute = `${RouteConstants.Verify}?email=${encoded}`
              props.history.push(verifyRoute)
              break
            case 'UserNotFoundException':
              setErrors({ username: e.message })
              break
            case 'NotAuthorizedException':
              setErrors({ password: e.message })
              break
            default:
              setGeneralError(e.message)
              setHas2FAEnabled(false)
          }
        }
      },
    })

    const toggleToLogin = (isLogin: boolean): void => {
      setPageToggle(isLogin)
      if (isLogin) {
        const loginElem = document.getElementsByClassName('toggle-left')
        loginElem[0].classList.add('toggle-active')
        const signupElem = document.getElementsByClassName('toggle-right')
        signupElem[0].classList.remove('toggle-active')
      } else {
        const signupElem = document.getElementsByClassName('toggle-right')
        signupElem[0].classList.add('toggle-active')
        const loginElem = document.getElementsByClassName('toggle-left')
        loginElem[0].classList.remove('toggle-active')
      }
    }

    const LoginFormState = {
      values: values,
      setValues: setValues,
      errors: errors,
      setErrors: setErrors,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      isLoginPage: isLoginPage,
      toggleToLogin: toggleToLogin,
    }

    return { LoginFormState }
  }

  const useTwoFAFormState = (): TwoFAFormStateType => {
    const initialValues = { code: '' }
    const { values, setValues, errors, setErrors, handleChange, handleSubmit } = useFormState({
      initialValues,
      handleSubmit: async (event): Promise<any> => {
        event.preventDefault()
        setLoading(true)
        try {
          await auth.login2FAConfirm(currentUser, values.code, 'SOFTWARE_TOKEN_MFA')
        } catch (e) {
          setLoading(false)
          switch (e.name) {
            case 'CodeMismatchException':
              setErrors({ code: e.message })
              break
            case 'UserNotFoundException':
              setErrors({ code: e.message })
              break
            case 'NotAuthorizedException':
              setErrors({ code: e.message })
              break
            default:
              setGeneralError(e.message)
              setHas2FAEnabled(true)
          }
        }
      },
    })

    const TwoFAFormState = {
      values: values,
      setValues: setValues,
      errors: errors,
      setErrors: setErrors,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
    }
    return { TwoFAFormState }
  }

  const useSignUpFormState = (): SignUpFormStateType => {
    const initialValues = { email: '', password: '', confirmPassword: '' }
    const [agreementTerms, setAgreementTerms] = useState({
      value: false,
      error: '',
    })
    const auth = useAuthContext()

    const { values, errors, setErrors, setValues, handleChange, handleSubmit } = useFormState({
      initialValues,
      handleSubmit: async (event, values: any): Promise<any> => {
        event.preventDefault()
        setLoading(true)
        try {
          await auth.signup(values.email, values.password, values.confirmPassword)
          const encoded = encodeURIComponent(values.email)
          const verifyRoute = `${RouteConstants.Verify}?email=${encoded}`
          props.history.push(verifyRoute)
        } catch (e) {
          setLoading(false)
          switch (e.name) {
            case 'UsernameExistsException':
              setErrors({ email: e.message })
              break
            case 'UserNotConfirmedException':
              setErrors({ email: e.message })
              const encoded = encodeURIComponent(values.email)
              const verifyRoute = `${RouteConstants.Verify}?email=${encoded}`
              props.history.push(verifyRoute)
              break
            case 'UserNotFoundException':
              setErrors({ email: e.message })
              break
            case 'InvalidPasswordException':
              setErrors({ password: e.message })
              setValues({ ...values, confirmPassword: '' })
              break
            case 'NotAuthorizedException':
              setErrors({ password: e.message })
              break
            case 'InvalidConfirmPasswordException':
              setErrors({ confirmPassword: e.message })
              break
            default:
              setGeneralError(e.message)
          }
          return false
        }
      },
    })

    const toggleTerms = (): void => {
      setAgreementTerms({ value: !agreementTerms.value, error: '' })
    }

    const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>): void => {
      const newValues = { ...values }
      const val = e.currentTarget.value
      newValues[e.currentTarget.name] = val
      setValues(newValues)
      if (errors[e.currentTarget.name]) {
        const newErrors = { ...errors }
        delete newErrors[e.currentTarget.name]
        setErrors(newErrors)
      }

      const passwordErrors: string[] = []
      if (val.length < 8) {
        passwordErrors.push('must be at least 8 characters')
      }
      if (val.length > 90) {
        passwordErrors.push('must be less than 90 characters')
      }
      // if (val.search(/[a-z]/i) < 0) {
      //   passwordErrors.push('must contain at least one letter')
      // }
      // if (val.search(/[0-9]/) < 0) {
      //   passwordErrors.push('must contain at least one digit')
      // }
      // if (val.search(/[=+^$*.[\]{}()?"!@#%&/\\,><':;|_~` -]/) < 0) {
      //   passwordErrors.push('must contain at least one of =+^$*.[]{}()?"!@#%&/\\,><\':;|_~` -')
      // }

      if (passwordErrors.length > 0) {
        // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
        const newErrors = { ...errors }
        newErrors[e.currentTarget.name] =
          'Your password ' + (passwordErrors.length > 1 ? passwordErrors.join(', ') : passwordErrors[0])
        setErrors(newErrors)
      }
    }

    const SignupFormState = {
      agreementTerms: agreementTerms,
      values: values,
      errors: errors,
      setErrors: setErrors,
      handleChange: handleChange,
      handlePasswordChange: handlePasswordChange,
      handleSubmit: handleSubmit,
      toggleTerms: toggleTerms,
    }

    return { SignupFormState }
  }

  const { LoginFormState } = useLoginFormState()

  const { TwoFAFormState } = useTwoFAFormState()

  const { SignupFormState } = useSignUpFormState()

  useEffect(() => {
    const param = window.location.pathname

    setTimeout(() => {
      if (param == RouteConstants.Signup || param == `${RouteConstants.User}${RouteConstants.Signup}`) {
        LoginFormState.toggleToLogin(false)
      }
    }, 50)
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      {isLoading ? <LoadingBlock clearLoadingMethod={setLoading} /> : null}
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      <LoginWrapper
        toggleToLogin={LoginFormState.toggleToLogin}
        isLoginPage={LoginFormState.isLoginPage}
        displayToggle={true}
      >
        {LoginFormState.isLoginPage ? (
          <Login LoginFormState={LoginFormState} has2FAEnabled={has2FAEnabled} TwoFAFormState={TwoFAFormState} />
        ) : (
          <SignUpForm SignupFormState={SignupFormState} toggleToLogin={LoginFormState.toggleToLogin} />
        )}
      </LoginWrapper>
    </React.Fragment>
  )
}

export default withRouter(LoginContainer)
