import { merge } from '../../util'

export class WireReferenceReply {
  constructor(init?: WireReferenceReply) {
    if (init) merge(init, this)
  }

  public reference? = ''
}

export { BurnAddressReply, BurnAddressCountReply } from './burn_address'
export { IndividualAccount, IndividualAccountErrors } from './individual_account'
export {
  AddRedemptionAddress,
  AddRedemptionAddressErrors,
  AddRedemptionAddresses,
  AddRedemptionAddressesErrors,
} from './add_redemption_address'
export { InstitutionalAccount, InstitutionalAccountErrors } from './instituional_account'
export { VerificationReply } from './verification'
export { UserAccount, UserAccountSet, UserAccountGet, UserAccountGetReply, UserAccountsList } from './users_accounts'
export { ServiceReply } from './service_reply'
