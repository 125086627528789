import React from 'react'
import RouteConstants from '../../routes/routeConstants'
import context from '../../util/localize'

const UpdateInfo = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className="account-content">
        <div>
          <div className="title-med text-align-center text-uppercase">{context.updateAccountInfo}</div>
        </div>
        <div className="center-align-vertical-container">
          <a href={RouteConstants.Bank} className="btn">
            {context.updateBankInfo}
          </a>
          <a href={RouteConstants.Crypto} className="btn" style={{ marginTop: '1.25rem' }}>
            {context.updateCryptoInfo}
          </a>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UpdateInfo
