import { merge } from '../../util'

export class Address {
  constructor(init?: Address) {
    if (init) merge(init, this)
  }

  public country? = ''
  public region? = ''
  public city? = ''
  public postal? = ''
  public street_1? = ''
  public street_2? = ''
}

export { Address as AddressError }
