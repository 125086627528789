import { ActionType } from '../action'
import {
  AddRedemptionAddress,
  AddRedemptionAddressErrors,
  AddRedemptionAddresses,
  AddRedemptionAddressesErrors,
} from '../../messages/firstdigital'

export class ActionAddBurnAddress implements ActionType<AddRedemptionAddress, AddRedemptionAddressErrors> {
  readonly authRequired = true
  readonly setUrl = '/admin/redemption/address/add'
  public message = new AddRedemptionAddress()
  public errors = new AddRedemptionAddressErrors()
  public messageType = AddRedemptionAddress
  public errorsType = AddRedemptionAddressErrors
}

export class ActionAddBurnAddresses implements ActionType<AddRedemptionAddresses, AddRedemptionAddressesErrors> {
  readonly authRequired = true
  readonly setUrl = '/admin/redemption/addresses/add'
  readonly getUrl = '/redemption/addresses/count/available'
  public message = new AddRedemptionAddresses()
  public errors = new AddRedemptionAddressesErrors()
  public messageType = AddRedemptionAddresses
  public errorsType = AddRedemptionAddressesErrors
}
