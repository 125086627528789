import { StringRules, validation, AddressRules, IntRules, BoolRules } from '../validation'
import { Address, Name } from '../common'
import { merge } from '../../util'

export class UltimateBeneficialOwner {
  constructor(init?: UltimateBeneficialOwner) {
    if (init) merge(init, this)
  }
  public ownerName? = ''
  public ownerPhone? = ''
  public ownerAddress = new Address()
  public ownerGovernmentIdNumber? = ''
}

export class IndividualAccount {
  constructor(init?: IndividualAccount) {
    if (init) merge(init, this)
  }
  public nameTitle? = ''
  public name = new Name()
  @validation(new AddressRules({ required: true }))
  public address = new Address()
  public correspondenceAddress = new Address()
  @validation(new StringRules({ required: true, maxLen: 50 }))
  public email? = ''
  @validation(new StringRules({ required: true, maxLen: 20 }))
  public phone? = ''
  @validation(new StringRules({ required: true }))
  public gender? = ''
  @validation(new StringRules({ required: true }))
  public birth? = ''
  @validation(new StringRules({ required: true }))
  public placeOfBirth? = ''
  @validation(new StringRules({ required: true }))
  public nationality? = ''
  @validation(new StringRules({ required: true }))
  public governmentIdNumber? = ''
  @validation(new StringRules({ required: true }))
  public taxCountry? = ''
  @validation(new BoolRules({ required: true }))
  public dualNationality? = false
  @validation(new StringRules({ required: true }))
  public taxId? = ''
  @validation(new StringRules({ required: true }))
  public employmentStatus? = ''
  @validation(new StringRules({ required: true }))
  public occupation? = ''
  @validation(new StringRules({ required: true }))
  public jobTitle? = ''
  @validation(new StringRules({ required: true }))
  public nameOfEmployer? = ''
  @validation(new IntRules({ required: true }))
  public yearsOfService?: number = 0
  @validation(new StringRules({ required: true }))
  public sourceOfWealth? = ''
  @validation(new StringRules({ required: true }))
  public sourceOfFunds? = ''
  public anyLitigations? = ''
  public anyRegulatoryActions? = ''
  @validation(new BoolRules({ required: true }))
  public isUltimateBeneficialOwner? = false
  @validation(new BoolRules({ required: true }))
  public haveNeverBeenUsCitizen? = false
  public ultimateBeneficialOwner = new UltimateBeneficialOwner()
}

export { IndividualAccount as IndividualAccountErrors }
