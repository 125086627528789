import {
  SET_APPLIED_FOR_VERIFICATION,
  SET_ADMIN,
  SET_ERROR,
  CLEAR_STATE,
  SET_VERIFICATION_APPLIED,
} from '../actionTypes'

export interface UserState {
  accountStatus: string
  appliedForVerification: boolean
  error: string
  isAdmin: boolean
  loading: boolean
  redemptionAddress: string
  wireReference: string
}

const initialState: UserState = {
  accountStatus: '',
  appliedForVerification: false,
  error: '',
  isAdmin: false,
  loading: false,
  redemptionAddress: '',
  wireReference: '',
}

const kgldReducer = (state = initialState, action): UserState => {
  switch (action.type) {
    case SET_APPLIED_FOR_VERIFICATION:
      const { accountStatus, appliedForVerification, redemptionAddress, wireReference } = action.payload
      return Object.assign({}, state, { accountStatus, appliedForVerification, redemptionAddress, wireReference })
    case SET_VERIFICATION_APPLIED:
      return Object.assign({}, state, {
        accountStatus: action.payload.accountStatus,
        appliedForVerification: action.payload.appliedForVerification,
      })
    case SET_ADMIN:
      const { isAdmin } = action.payload
      return Object.assign({}, state, { isAdmin })
    case SET_ERROR:
      const { error } = action.payload
      return Object.assign({}, state, { error })
    case CLEAR_STATE:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export default kgldReducer
