import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { ActionEthCryptoAccount, ActionFDIntBankAccount } from '@/Api'
import { useFetchWithCallback, api, useAuthContext } from '@/ApiReact'
import TermsAgreementContainer from './user/termsagreement'
import Home from '../components/home'
import ErrorAlert from './usernotifications/erroralert'
import LoadingBlock from './usernotifications/loading'
import account from '../redux/actions/accountActions'
import { ContainerRouterProps } from '../interface'
import { convertTimestampToLocalString } from '../util/helpers'
import RouteConstants from '../routes/routeConstants'

const initialValues = {
  accHolder: '',
  accNumber: 'xxxxxxx',
  burnAddress: '------------',
  ethAddress: '------------',
  wireReference: '------------',
}

const HomeContainer: React.FC<ContainerRouterProps> = (props: ContainerRouterProps) => {
  const auth = useAuthContext()
  const [bankState, setBankState] = useState({
    accHolder: initialValues.accHolder,
    accNumber: initialValues.accNumber,
  })
  const [ethAddress, setEthAddr] = useState(initialValues.ethAddress)
  const [hasEthAddress, displayBuyPage] = useState(true)
  const [hasBankAcc, displaySellPage] = useState(true)
  const [generalError, setGeneralError] = useState('')
  const [livePrice, setLivePrice] = useState('----')
  const [isLoadingBank, setLoadingBank] = useState(true)
  const [isLoadingCrypto, setLoadingCrypto] = useState(true)
  const [isLoading, setLoading] = useState(true)
  const [priceDate, setPriceDate] = useState(moment().local().format('MMM DD, YYYY [at] hh:mm A'))
  const dispatch = useDispatch()

  const accountStatus = useSelector((state) => state.kgldReducer.accountStatus)
  const appliedForVerification = useSelector((state) => state.kgldReducer.appliedForVerification)
  const wireRef = useSelector((state) => state.kgldReducer.wireReference)
  const burnAddr = useSelector((state) => state.kgldReducer.redemptionAddress)
  const [userEmail, setUserEmail] = useState('')

  // get bank account and display component accordingly
  useFetchWithCallback(new ActionFDIntBankAccount(), (err, values) => {
    setLoadingBank(true)
    if (err) {
      setLoadingBank(false)
      setGeneralError(err)
      return
    }

    if (values && values.accountNumber !== '') {
      const accHolder = values.nameOnAccount ? values.nameOnAccount : initialValues.accHolder
      let accNumber = values.accountNumber ? values.accountNumber : initialValues.accNumber
      const accStr = accNumber.substring(0, accNumber.length - 4).replace(/[a-zA-Z0-9]/gi, 'x')
      accNumber = accStr + accNumber.substring(accNumber.length - 4)

      displaySellPage(true)
      setBankState({ accHolder, accNumber })
      setLoadingBank(false)
    } else {
      displaySellPage(false)
      setLoadingBank(false)
    }
  })

  // get eth account and display component accordingly
  useFetchWithCallback(new ActionEthCryptoAccount(), (err, values) => {
    setLoadingCrypto(true)
    if (err) {
      setLoadingCrypto(false)
      setGeneralError(err)
      return
    }

    if (values && values.address !== '') {
      const address = values.address ? values.address : initialValues.ethAddress
      setEthAddr(address)
      displayBuyPage(true)
      setLoadingCrypto(false)
    } else {
      displayBuyPage(false)
      setLoadingCrypto(false)
    }
  })

  const checkIfAppliedForVerification = (): void => {
    // redirect if not applied for verification
    // need a delay for the state to update
    setLoading(false)
    setTimeout(() => {
      if (appliedForVerification) {
      } else {
        props.history.push(RouteConstants.KYCType)
      }
    }, 4000)
  }

  const getLiveGoldPrice = async (): Promise<void> => {
    try {
      const response = await api.firstdigital.getGoldPrice()
      if (response.success && response.askPrice && response.bidPrice) {
        const price = (parseFloat(response.askPrice) + parseFloat(response.bidPrice)) / 2
        setLivePrice(price.toFixed(2).toString())

        setPriceDate(convertTimestampToLocalString(response.timestamp))
      } else {
        setGeneralError(`Couldn't fetch spot price`)
      }
    } catch (err) {
      setGeneralError(`Couldn't fetch spot price`)
    }
  }

  const getEmail = async (): Promise<void> => {
    const response = await auth.getCurrentAuthenticatedUser()
    if (response.isOk()) {
      const str = response.value.attributes.email
      setUserEmail(str)
    }
  }

  const setAllLoading = (value: boolean): void => {
    setLoadingBank(value)
    setLoading(value)
    setLoadingCrypto(value)
  }

  useEffect(() => {
    checkIfAppliedForVerification()
    getLiveGoldPrice()
    dispatch(account.FETCH_VERIFICATION_STATUS())
    getEmail()
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      {isLoadingBank || isLoadingCrypto || isLoading ? <LoadingBlock clearLoadingMethod={setAllLoading} /> : null}
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      <TermsAgreementContainer />
      <Home
        accountStatus={accountStatus}
        bankValues={bankState}
        burnAddress={burnAddr}
        ethAddress={ethAddress}
        hasBankAcc={hasBankAcc}
        hasEthAddr={hasEthAddress}
        livePrice={livePrice}
        priceDate={priceDate}
        userEmail={userEmail}
        wireReference={wireRef}
      />
    </React.Fragment>
  )
}

export default withRouter(HomeContainer)
