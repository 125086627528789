import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, withRouter, Link } from 'react-router-dom'
import Navbar from '../blocks/navbar'
import HamburgerMenu from 'react-hamburger-menu'
import LanguageContainer from '../container/language'
import { useAuthContext } from '@/ApiReact'
import ErrorAlert from '../container/usernotifications/erroralert'
import context from '../util/localize'
import { colors } from '../util/styles'
import account from '../redux/actions/accountActions'
import RouteConstants from '../routes/routeConstants'
import { checkAdminEmail } from '../util/helpers'
import { KITCO_GOLD_URL } from '../constants/config'

const HeaderAuth = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [generalError, setGeneralError] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [showAdmin, setShowAdmin] = useState(false)
  const auth = useAuthContext()
  const appliedForVerification = useSelector((state) => state.kgldReducer.appliedForVerification)

  const toggleMenu = (): void => {
    setIsOpen(!isOpen)
  }

  const closeMenu = (): void => {
    setIsOpen(false)
  }

  const getEmail = async (): Promise<void> => {
    try {
      const response = await auth.getCurrentAuthenticatedUser()
      if (response.isOk()) {
        const str = response.value.attributes.email
        const isAdmin = checkAdminEmail(str)

        if (isAdmin) {
          setShowAdmin(true)
        } else {
          setShowAdmin(false)
        }
      }
    } catch (err) {
      setGeneralError(JSON.stringify(err))
    }
  }

  const clearStorage = (): void => {
    const session = sessionStorage.getItem('ref')

    if (session == null) {
      localStorage.removeItem('persistantState')
      auth.logout()
    }
    sessionStorage.setItem('ref', '1')
  }
  window.addEventListener('load', clearStorage)

  const handleNavbar = (): void => {
    const path = window.location.pathname
    document.getElementById('account')?.classList.add('invisible')
    document.getElementById('account')?.classList.remove('active')
    document.getElementById('accountType')?.classList.add('invisible')
    document.getElementById('accountType')?.classList.remove('active')
    document.getElementById('settings')?.classList.add('invisible')
    document.getElementById('settings')?.classList.remove('active')
    document.getElementById('bank')?.classList.add('invisible')
    document.getElementById('bank')?.classList.remove('active')
    document.getElementById('crypto')?.classList.add('invisible')
    document.getElementById('crypto')?.classList.remove('active')
    document.getElementById('individual')?.classList.add('invisible')
    document.getElementById('individual')?.classList.remove('active')
    document.getElementById('institution')?.classList.add('invisible')
    document.getElementById('institution')?.classList.remove('active')
    document.getElementById('admin')?.classList.remove('active')
    document.getElementById('admin')?.classList.remove('invisible')
    switch (path) {
      case RouteConstants.Account:
      case RouteConstants.Home:
      case RouteConstants.Login:
        document.getElementById('account')?.classList.remove('invisible')
        document.getElementById('account')?.classList.add('active')
        document.getElementById('settings')?.classList.remove('invisible')
        break
      case RouteConstants.Admin:
        setTimeout(() => {
          document.getElementById('account')?.classList.remove('invisible')
          document.getElementById('admin')?.classList.add('active')
          document.getElementById('settings')?.classList.remove('invisible')
        }, 2000)
        break
      case RouteConstants.Settings:
        document.getElementById('account')?.classList.remove('invisible')
        document.getElementById('settings')?.classList.remove('invisible')
        document.getElementById('settings')?.classList.add('active')
        break
      case RouteConstants.KYCType:
        document.getElementById('account')?.classList.remove('invisible')
        document.getElementById('accountType')?.classList.remove('invisible')
        document.getElementById('accountType')?.classList.add('active')
        document.getElementById('settings')?.classList.remove('invisible')
        break
      case RouteConstants.Bank:
      case RouteConstants.BankAdd:
        document.getElementById('account')?.classList.remove('invisible')
        document.getElementById('bank')?.classList.remove('invisible')
        document.getElementById('bank')?.classList.add('active')
        break
      case RouteConstants.Crypto:
      case RouteConstants.CryptoAdd:
        document.getElementById('account')?.classList.remove('invisible')
        document.getElementById('crypto')?.classList.remove('invisible')
        document.getElementById('crypto')?.classList.add('active')
        break
      case RouteConstants.KYCIndividual:
        document.getElementById('account')?.classList.remove('invisible')
        document.getElementById('individual')?.classList.remove('invisible')
        document.getElementById('individual')?.classList.add('active')
        break
      case RouteConstants.KYCInstitution:
        document.getElementById('account')?.classList.remove('invisible')
        document.getElementById('institution')?.classList.remove('invisible')
        document.getElementById('institution')?.classList.add('active')
        break
    }
  }

  useEffect(() => {
    const path = history.location.pathname
    if (!appliedForVerification && path === RouteConstants.Home) {
      history.push(RouteConstants.KYCType)
    } else if (appliedForVerification && path === RouteConstants.KYCType) {
      history.push(RouteConstants.Home)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedForVerification, history.location.pathname])

  useEffect(() => {
    getEmail()
    handleNavbar()

    dispatch(account.FETCH_VERIFICATION_STATUS())
    // eslint-disable-next-line
  }, [history.location.pathname])

  return (
    <React.Fragment>
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      <Navbar isopen={isOpen}>
        <Navbar.Group withLogo={true}>
          <Navbar.Dropdown>
            <HamburgerMenu
              isOpen={isOpen}
              menuClicked={toggleMenu}
              width={18}
              height={15}
              strokeWidth={1}
              rotate={0}
              color={colors.black}
              borderRadius={0}
              animationDuration={0.5}
            />
            <Navbar.Group forDesktop={true} isMenu={true} isOpen={isOpen} isAuthed={true}>
              <Link to={RouteConstants.Bank}>
                <Navbar.Item onClick={closeMenu}>{context.updateBankInfo}</Navbar.Item>
              </Link>
              <Link to={RouteConstants.Crypto}>
                <Navbar.Item onClick={closeMenu}>{context.updateCryptoInfo}</Navbar.Item>
              </Link>
              {showAdmin ? (
                <Link to={RouteConstants.Admin}>
                  <Navbar.Item onClick={closeMenu}>Admin</Navbar.Item>
                </Link>
              ) : null}
              <Link to={''}>
                <Navbar.Item
                  onClick={(): any => {
                    sessionStorage.removeItem('ref')
                    localStorage.removeItem('persistantState')
                    auth.logout()
                    closeMenu()
                  }}
                >
                  {context.signOut}
                </Navbar.Item>
              </Link>
            </Navbar.Group>
            <Navbar.MenuBg forDesktop={true} isOpen={isOpen} onClick={closeMenu} />
          </Navbar.Dropdown>
          <Navbar.Logo>
            <a href={KITCO_GOLD_URL} onClick={closeMenu}>
              <img src="/images/KitcoGold-Logo.png" alt="KitcoGold logo" style={{ width: '155px' }} />
            </a>
          </Navbar.Logo>
        </Navbar.Group>

        <Navbar.Group hasNavItems={true} isOpen={isOpen}>
          <>
            <Link to={RouteConstants.Account} onClick={closeMenu}>
              <Navbar.Item className="invisible" id="account">
                {context.account}
              </Navbar.Item>
            </Link>
            <Link to={RouteConstants.KYCType} onClick={closeMenu}>
              <Navbar.Item className="invisible" id="accountType">
                {context.accountType}
              </Navbar.Item>
            </Link>
            <Link to={RouteConstants.Settings} onClick={closeMenu}>
              <Navbar.Item className="invisible" id="settings">
                {context.settings}
              </Navbar.Item>
            </Link>
            <Link to={RouteConstants.Bank}>
              <Navbar.Item className="invisible" id="bank" onClick={closeMenu}>
                {context.updateBankInfo}
              </Navbar.Item>
            </Link>
            <Link to={RouteConstants.Crypto}>
              <Navbar.Item className="invisible" id="crypto" onClick={closeMenu}>
                {context.updateCryptoInfo}
              </Navbar.Item>
            </Link>
            <Link to={RouteConstants.KYCIndividual}>
              <Navbar.Item className="invisible" id="individual" onClick={closeMenu}>
                {context.personalAccount}
              </Navbar.Item>
            </Link>
            <Link to={RouteConstants.KYCInstitution}>
              <Navbar.Item className="invisible" id="institution" onClick={closeMenu}>
                {context.businessAccount}
              </Navbar.Item>
            </Link>
            {showAdmin ? (
              <Link to={RouteConstants.Admin}>
                <Navbar.Item id="admin" onClick={closeMenu}>
                  Admin
                </Navbar.Item>
              </Link>
            ) : null}
            <Link to={''}>
              <Navbar.Item
                onClick={(): any => {
                  sessionStorage.removeItem('ref')
                  localStorage.removeItem('persistantState')
                  auth.logout()
                  closeMenu()
                }}
              >
                {context.signOut}
              </Navbar.Item>
            </Link>
            <Navbar.MenuBg forMobile={true} isOpen={isOpen} onClick={closeMenu} />
          </>
        </Navbar.Group>
        <Navbar.Group isLangSelector={true}>
          <LanguageContainer />
        </Navbar.Group>
      </Navbar>
    </React.Fragment>
  )
}
export default withRouter(HeaderAuth)
