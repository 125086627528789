import { SET_ERROR, RESET_ERROR } from '../actionTypes'

const error = {
  SET_ERROR: (msg): any => {
    return {
      type: SET_ERROR,
      payload: { message: msg || 'Something went wrong' },
    }
  },

  RESET_ERROR: (): any => {
    return {
      type: RESET_ERROR,
      payload: { message: '' },
    }
  },
}

export default error
