import { merge } from '../../util'

/**
 * FloatRules specifies float value rules that may be
 * applied to a message as a field decorator.
 *
 * e.g. @validation(new FloatRules({ required: true, min: 0 }))
 *
 * Rules are defined by go-packages/schema/validate/float.proto
 */
export class FloatRules {
  constructor(init: FloatRules) {
    if (init) merge(init, this)
  }
  public required = false
  public const?: number
  public min?: number
  public max?: number
  public in?: Array<number>
  public notIn?: Array<number>
}

const regFloat = new RegExp('^[\\-]{0,1}[0-9]+[\\.]{0,1}[0-9]*$')

/**
 * isFloat will return whether a value is an acceptable
 * value for an unsigned integer type field.
 *
 * @param value
 * returns boolean
 */
export function isFloat(value: string): boolean {
  return regFloat.test(value)
}

/**
 * validateFloatValue will validate whether a field is a valid string
 * representation of a float value, and that the value complies with
 * the field rules (if any). Returns an error message (string) or blank string.
 * @param value
 * @param rules
 * returns string
 */
export function validateFloatValue(value: string, rules?: FloatRules): string {
  if (!value && (!rules || !rules?.required)) return ''
  if (!rules) return ''
  if (!value && rules.required) return 'A value is required'
  if (!isFloat(value)) return 'A numeric value is required'

  const floatVal = parseFloat(value)
  if (rules.const && floatVal !== rules.const) return 'Value must be equal to constant ' + rules.const
  if (rules.in && rules.in.indexOf(floatVal) === -1) return 'Value does not exist in the required list'
  if (rules.notIn && rules.notIn.indexOf(floatVal) !== -1) return 'Value exist in the excluded list'
  if (rules.min && floatVal < rules.min) return 'Value must be greater than ' + rules.min
  if (rules.max && floatVal > rules.max) return 'Value must be less than ' + rules.max

  return ''
}

/**
 * validateFloatInput will return whether the present string input can lead to a
 * valid float field value that conforms to the given rules
 *
 * @param input
 * @param rules
 * returns boolean
 */
export function validateFloatInput(input: string, rules?: FloatRules): boolean {
  if (input === '') return true
  if (!isFloat(input)) return false
  if (!rules) return true
  // rules not implemented
  return true
}
