import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useAuthContext, useFormState } from '@/ApiReact'
import Reset from '../../components/user/reset'
import LoginWrapper from '../../components/user/loginwrapper'
import { getEmailFromUrlParam, getParamFromUrlParam } from '../../util/helpers'
import { ContainerRouterProps, KeyValues } from '../../interface'
import ErrorAlert from '../usernotifications/erroralert'
import LoadingBlock from '../usernotifications/loading'

const ResetPasswordContainer: React.FC<ContainerRouterProps> = (props: ContainerRouterProps) => {
  const [isLoading, setLoading] = useState(false)
  const [generalError, setGeneralError] = useState('')

  const useResetPasswordFormState = (): any => {
    // if redirected from email link then the user does not need to enter the code or email id
    const emailRef = getEmailFromUrlParam()
    const codeRef = getParamFromUrlParam('code')
    const initialValues = {
      email: emailRef,
      code: codeRef,
      newPassword: '',
    }
    const auth = useAuthContext()

    const { values, setValues, errors, setErrors, handleChange, handleSubmit } = useFormState({
      initialValues,
      handleSubmit: async (event: React.FormEvent<HTMLFormElement>, values: KeyValues): Promise<void> => {
        event.preventDefault()
        setLoading(true)
        try {
          const { email, code, newPassword, confirmNewPassword } = values
          const response = await auth.forgotPasswordSubmit(email, code, newPassword, confirmNewPassword)
          if (response.isOk()) {
            props.history.push('/login')
          } else {
            setLoading(false)
            switch (response.error.name) {
              case 'CodeMismatchException':
                setErrors({ code: response.error.message })
                break
              case 'InvalidConfirmPasswordException':
                setErrors({ confirmNewPassword: response.error.message })
                break
              default:
                setGeneralError(response.error.message)
            }
          }
        } catch (e) {
          setLoading(false)
          switch (e.name) {
            case 'CodeMismatchException':
              setErrors({ code: e.message })
              break
            case 'InvalidConfirmPasswordException':
              setErrors({ confirmNewPassword: e.message })
              break
            default:
              setGeneralError(e.message)
          }
        }
      },
    })
    return { emailRef, codeRef, values, setValues, errors, setErrors, handleChange, handleSubmit }
  }
  const { emailRef, codeRef, values, errors, handleChange, handleSubmit } = useResetPasswordFormState()

  return (
    <React.Fragment>
      {isLoading ? <LoadingBlock /> : null}
      {generalError !== '' && <ErrorAlert errorMsg={generalError} clearErrorMethod={setGeneralError} />}
      <LoginWrapper displayToggle={false}>
        <Reset
          errors={errors}
          emailRef={emailRef}
          codeRef={codeRef}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          values={values}
        />
      </LoginWrapper>
    </React.Fragment>
  )
}

export default withRouter(ResetPasswordContainer)
