import React from 'react'
import context from '../../util/localize'

const LoginWrapper = ({ isLoginPage, hideLogo, displayToggle, toggleToLogin, children }: any): any => {
  return (
    <React.Fragment>
      <div className="container">
        {displayToggle ? (
          <div className="toggle-wrapper">
            <div
              className="toggle-left toggle-active"
              onClick={(): React.MouseEvent<HTMLDivElement, MouseEvent> => toggleToLogin(true)}
            >
              {context.login}
            </div>
            {isLoginPage ? <div className="toggle-right-arrow"></div> : <div className="toggle-left-arrow"></div>}
            <div
              className="toggle-right"
              onClick={(): React.MouseEvent<HTMLDivElement, MouseEvent> => toggleToLogin(false)}
            >
              {context.signup}
            </div>
          </div>
        ) : null}
        <div className="card">
          {!hideLogo ? (
            <img src="/images/KitcoGold-Logo.png" alt="kitco gold logo" style={{ maxHeight: '50px', width: '155px' }} />
          ) : null}
          {children}
          {!hideLogo ? (
            <img
              src="/images/PoweredByStably.png"
              alt="powered by stably"
              style={{ maxHeight: '50px', width: '155px', marginTop: '15px' }}
            />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  )
}

export default LoginWrapper
