import { StringRules, validation } from '../validation'
import { merge } from '../../util'

export class FDUSBankAccount {
  constructor(init?: FDUSBankAccount) {
    if (init) merge(init, this)
  }
  @validation(new StringRules({ required: true, maxLen: 80 }))
  public bankName? = ''
  @validation(new StringRules({ required: true, maxLen: 40 }))
  public accountNumber? = ''
  @validation(new StringRules({ required: true, maxLen: 80 }))
  public nameOnAccount? = ''
  @validation(new StringRules({ required: true, maxLen: 15 }))
  public abaRoutingNumber? = ''
  @validation(new StringRules({ required: true, maxLen: 80 }))
  public bankAddress? = ''
  @validation(new StringRules({ required: true, maxLen: 3 }))
  public country? = ''
  public additionalInfo? = ''
}

export class FDUSBankAccountErrors {
  constructor(init?: FDUSBankAccountErrors) {
    if (init) merge(init, this)
  }
  public bankName? = ''
  public accountNumber? = ''
  public nameOnAccount? = ''
  public abaRoutingNumber? = ''
  public bankAddress? = ''
  public country? = ''
  public BankName? = ''
  public AccountNumber? = ''
  public NameOnAccount? = ''
  public AbaRoutingNumber? = ''
  public BankAddress? = ''
  public Country? = ''
}

export class FDIntBankAccount {
  constructor(init?: FDIntBankAccount) {
    if (init) merge(init, this)
  }
  @validation(new StringRules({ required: true, maxLen: 80 }))
  public bankName? = ''
  @validation(new StringRules({ required: true, maxLen: 40 }))
  public accountNumber? = ''
  @validation(new StringRules({ required: true, maxLen: 80 }))
  public nameOnAccount? = ''
  @validation(new StringRules({ required: true, maxLen: 15 }))
  public swiftCode? = ''
  @validation(new StringRules({ required: true, maxLen: 80 }))
  public bankAddress? = ''
  @validation(new StringRules({ required: true, maxLen: 3 }))
  public country? = ''
  public additionalInfo? = ''
}

export class FDIntBankAccountErrors {
  constructor(init?: FDIntBankAccountErrors) {
    if (init) merge(init, this)
  }
  public bankName? = ''
  public accountNumber? = ''
  public nameOnAccount? = ''
  public swiftCode? = ''
  public bankAddress? = ''
  public country? = ''
  public BankName? = ''
  public AccountNumber? = ''
  public NameOnAccount? = ''
  public SwiftCode? = ''
  public BankAddress? = ''
  public Country? = ''
}
