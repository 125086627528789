import { ApiConfig } from './config'
import { AuthApi } from './auth/auth'
import { FirstDigitalApi } from './firstdigital'
import { version } from '../version'
import { JsonData } from '../types'
import { SyncStorageProvider } from './interfaces'
import { ok, err, Result } from 'neverthrow'
import axios from 'axios'
import { now } from 'moment'

export { ApiConfig }
export * from './interfaces'

export class Api {
  constructor(public config: ApiConfig) {
    if (config.persistent !== undefined) {
      this.persistent = config.persistent
    }
    if (config.syncStorage) {
      this.syncStorage = config.syncStorage
    }
    this.auth = new AuthApi(this)
    this.firstdigital = new FirstDigitalApi(this)
  }

  public auth: AuthApi
  public firstdigital: FirstDigitalApi
  public version = version

  /**
   * syncStorage is the syncronous storage provider that will
   * used by the API to store persistent state information.
   *
   * tdb: detect storage provider by platform
   * tdb: implement safeLocalStorage that falls back to sessionStorage
   * tdb: implement tabLocalStorage to segregate local storage by tab
   */
  public syncStorage?: SyncStorageProvider = localStorage
  public persistent = true

  /**
   * httpGet makes an HTTP GET request to the API
   * Generally the API avoids GET methods in favor of POST
   * so this method is available for exceptions to that rule.
   * returns json
   */
  public async httpGet(url: string): Promise<JsonData | undefined> {
    try {
      if (this.auth.state.expiration && now() >= this.auth.state.expiration) {
        await this.auth.restoreCognitoAttributes()
      }

      url = this.config.API_ENDPOINT + url
      const headers: HeadersInit = new Headers()
      const authorization = this.auth.authHeader()
      if (authorization) {
        headers.set('Authorization', authorization)
      }
      const response = await fetch(url, {
        method: 'GET',
        headers,
      })
      if (response.status === 401) {
        // not authorized
        this.auth.logout()
        return undefined
      }
      if (response.status !== 200) {
        throw new Error(response.statusText)
      }
      return this.getJson(response)
    } catch (e) {
      console.error(e)
      return undefined
    }
  }

  /***
   * get fetches data from the API
   * this will be an HTTPS POST call
   * with Authorization header (if authenticated)
   * with no parameters nor body
   * returns json
   */
  public async get(url: string): Promise<JsonData | undefined> {
    try {
      if (this.auth.state.expiration && now() >= this.auth.state.expiration) {
        await this.auth.restoreCognitoAttributes()
      }

      url = this.config.API_ENDPOINT + url
      const headers: HeadersInit = new Headers()
      const authorization = this.auth.authHeader()
      if (authorization) {
        headers.set('Authorization', authorization)
      }
      const response = await fetch(url, {
        method: 'POST',
        headers,
      })
      if (response.status === 401) {
        // not authorized
        this.auth.logout()
        return undefined
      }
      if (response.status !== 200) {
        // TODO: this throw does not facilitate a graceful failure
        throw new Error(response.statusText)
      }
      return this.getJson(response)
    } catch (e) {
      console.error(e)
      return undefined
    }
  }

  /***
   * set sends data to the API
   * this will be an HTTPS POST call
   * with Authorization header (if authenticated)
   * with a json body
   * returns json
   */
  // public async set(url: string, message: any): Promise<JsonData | undefined> {
  public async set(url: string, message: any): Promise<Result<JsonData, Error>> {
    try {
      if (this.auth.state.expiration && now() >= this.auth.state.expiration) {
        await this.auth.restoreCognitoAttributes()
      }

      url = this.config.API_ENDPOINT + url
      const headers: HeadersInit = new Headers()
      headers.set('Content-Type', 'application/json')
      const authorization = this.auth.authHeader()
      if (authorization) {
        headers.set('Authorization', authorization)
      }
      const body: string = message.toObject ? JSON.stringify(message.toObject()) : JSON.stringify(message)
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body,
      })
      if (response.status === 401) {
        // not authorized
        this.auth.logout()
        return err(new Error('Not authorized'))
      }
      if (response.status !== 200) {
        // We are not throwing here to facilitate graceful failures
        return err(new Error(response.statusText))
      }
      return ok(await this.getJson(response))
    } catch (e) {
      return err(new Error('Unexpected connection error'))
    }
  }

  public async upload(url: string, file: any, label: string): Promise<Result<JsonData, Error>> {
    try {
      if (this.auth.state.expiration && now() >= this.auth.state.expiration) {
        await this.auth.restoreCognitoAttributes()
      }

      url = this.config.API_ENDPOINT + url
      const authorization = this.auth.authHeader()

      const formData = new FormData()
      formData.append('file', file)
      formData.append('label', label)

      const response = await axios.post(url, formData, {
        headers: { Authorization: authorization },
      })
      if (response.status === 401) {
        // not authorized
        this.auth.logout()
        return err(new Error('Not authorized'))
      }

      if (response.status !== 200) {
        // We are not throwing here to facilitate graceful failures
        return err(new Error(response.statusText))
      }
      return response.data
    } catch (e) {
      return err(new Error('Unexpected connection error'))
    }
  }

  public getEndpoint(): string {
    return this.config.API_ENDPOINT || ''
  }

  public useEndpoint(endpoint: string): void {
    this.config.API_ENDPOINT = endpoint
    console.log(`using API endpoint ${endpoint}`)
  }

  private getJson(response: Response): Promise<JsonData> {
    // tdb: try/catch for invalid json
    return response.json()
  }
}
