import React from 'react'
import context from '../../util/localize'
import { TextInput } from '@/ApiReact'

const UserVerify = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  onSubmitResend,
  resendSuccess,
  resendTimer,
}: any): any => {
  return (
    <React.Fragment>
      <p className="text-align-center text-bold">{context.verifyEmailMessage}</p>

      <form onSubmit={handleSubmit}>
        <TextInput
          name="email"
          onChange={handleChange}
          placeholder={context.email}
          type="email"
          value={values.email || ''}
          error={errors.email}
          required={true}
          label={context.email}
        />

        <TextInput
          name="code"
          onChange={handleChange}
          placeholder={context.code}
          type="text"
          value={values.code || ''}
          error={errors.code}
          required={true}
          label={context.code}
          autoCompleteOff={true}
        />

        <div className="space-between-container">
          <button type="submit" className="button-secondary">
            {context.submit}
          </button>

          <button
            type="button"
            style={{ float: 'right' }}
            onClick={(): void => onSubmitResend(values)}
            id="resend"
            className="button-secondary"
            disabled={resendSuccess ? true : false}
          >
            {context.resend}
            {resendTimer !== 0 ? ` in ${resendTimer}` : ''}
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default UserVerify
