import { merge } from '../../util'
import { Address, AddressError } from '../common/address'
import { isAddressCity, isAddressStreet, isAddressStreet2, isCountry } from './wellknown'
import { RomanizedRegex } from './string'

export class AddressRules {
  constructor(init: AddressRules) {
    if (init) merge(init, this)
  }
  public required = false
}

// USRegionCodes includes region codes of US regions
const USRegionCodes = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
]

// CARegionCodes includes region codes of Canada regions
const CARegionCodes = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT']

// GBRegionCodes includes region codes of UK regions
const GBRegionCodes = ['ENG', 'NIR', 'SCT', 'WLS']

/**
 * validateAddressRegionCodeValue will return whether the address input can lead to a
 * valid address value that conforms to the given rules
 * @param regionCode
 * @param countryCode
 */
function validateAddressRegionCodeValue(regionCode: string, countryCode: string): string {
  let countryRegionCodes: string[] = []
  switch (countryCode) {
    case 'US':
      countryRegionCodes = USRegionCodes
      break
    case 'CA':
      countryRegionCodes = CARegionCodes
      break
    case 'GB':
      countryRegionCodes = GBRegionCodes
      break
  }

  if (countryRegionCodes.length === 0) {
    return ''
  }

  if (countryRegionCodes.includes(regionCode)) {
    return ''
  }

  return 'Invalid ' + countryCode + ' region code'
}

// GBPostalCodePatternString is a string that will use to validate
// a postal code of UK
const GBPostalCodePatternString = '^\\w{2,4}([ \\-]*\\w{3})?$'

// USPostalCodePatternString is a string that will use to validate
// a postal code of US
const USPostalCodePatternString = '^\\d{5}([ \\-]*\\d{4})?$'

// CAPostalCodePatternString is a string that will use to validate
// a postal code of CA
const CAPostalCodePatternString = '^[A-Za-z]\\d[A-Za-z][ ]*\\d[A-Za-z]\\d$'

/**
 *  validateAddressPostalCodeValue will validate a string is an valid postal code
 * @param postalCode
 * @param country
 */
function validateAddressPostalCodeValue(postalCode: string, countryCode: string): string {
  let pttrnStr = ''

  switch (countryCode) {
    case 'US':
      pttrnStr = USPostalCodePatternString
      break
    case 'CA':
      pttrnStr = CAPostalCodePatternString
      break
    case 'GB':
      pttrnStr = GBPostalCodePatternString
      break
  }

  if (pttrnStr.length === 0) {
    return ''
  }

  const postalCodePattern = new RegExp(pttrnStr)
  if (postalCodePattern.test(postalCode)) {
    return ''
  }

  return `Invalid ${countryCode} postal code`
}

/**
 * validateAddressValue will validate whether an address field complies with
 * the field rules (if any). Returns an error message (AddressError) or blank string.
 * @param value
 * @param rules
 * returns string
 */
export function validateAddressValue(msg: Address, rules?: AddressRules): AddressError | undefined {
  if (!msg && !rules) {
    return
  }
  if (!rules) return

  const err: AddressError = {}

  // eslint-disable-next-line @typescript-eslint/camelcase
  if (!msg.street_1) err.street_1 = 'Street is required'
  // eslint-disable-next-line @typescript-eslint/camelcase
  else if (!RomanizedRegex.test(msg.street_1)) err.street_1 = 'Only romanized characters allowed'
  // eslint-disable-next-line @typescript-eslint/camelcase
  else if (!isAddressStreet(msg.street_1)) err.street_1 = 'Value is not a valid address street'

  if (msg.street_2 !== undefined && err.street_2 !== '' && !isAddressStreet2(msg.street_2)) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    if (!RomanizedRegex.test(msg.street_2)) err.street_2 = 'Only romanized characters allowed'
    // eslint-disable-next-line @typescript-eslint/camelcase
    else err.street_2 = 'Value is not a valid address street line 2'
  }

  if (!msg.city) err.city = 'City is required'
  else if (!isAddressCity(msg.city)) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    if (!RomanizedRegex.test(msg.city)) err.city = 'Only romanized characters allowed'
    else err.city = 'Value is not a valid address city'
  }

  if (!msg.country) err.country = 'Country is required'
  else if (!isCountry(msg.country)) err.country = 'Value is not a valid address country'

  if (msg.country && !err.country) {
    if (!msg.region) {
      err.region = 'Region is required'
    } else {
      const regionError = validateAddressRegionCodeValue(msg.region, msg.country)
      if (regionError) err.region = regionError
      else {
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (!RomanizedRegex.test(msg.region)) err.region = 'Only romanized characters allowed'
      }
    }

    if (!msg.postal) err.postal = 'Postal code is required'
    else {
      const postalError = validateAddressPostalCodeValue(msg.postal, msg.country)
      if (postalError) err.postal = postalError
    }
  }

  if (Object.keys(err).length === 0) {
    return
  }

  return new AddressError(err)
}

/**
 * validateStringInput will return whether the address input can lead to a
 * valid address value that conforms to the given rules
 *
 * @param input
 * @param rules
 * returns boolean
 */
export function validateAddressInput(input: string, rules?: AddressRules): boolean {
  if (input === '') return true
  if (!rules) return true
  // rules not implemented
  return true
}
